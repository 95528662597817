import React from "react";

class MobileHomeIcon extends React.Component {
   render () {
     return (
       <svg viewBox="0 0 640 512" height={this.props.height || "25px"} fill={this.props.fill || "black"}>
        <path d="M304,128H112c-8.8,0-16,7.2-16,16v96c0,8.8,7.2,16,16,16h192c8.8,0,16-7.2,16-16v-96C320,135.2,312.8,128,304,128z M288,224H128v-64h160V224z"/>
        <g>
          <path d="M496,512c2,0,4-0.1,6-0.2H490C492,511.9,494,512,496,512z"/>
          <path d="M520,32H384V16c0-8.8-7.2-16-16-16H240c-8.8,0-16,7.2-16,16v16H64C28.7,32,0,60.7,0,96v197.5c0,17,6.7,33.2,18.7,45.2
            l69.7,69.7c2.8,2.8,6.1,4.6,9.7,5.9C96.8,420,96,425.9,96,432c0,44.2,35.8,80,80,80s80-35.8,80-80c0-5.5-0.6-10.8-1.6-16h79.8
            h64.4h18.9h33.1h90.5h33.1h5.8H608c17.6,0,32-14.4,32-32V152C640,85.7,586.3,32,520,32z M178.5,479.9c-28.5,1.4-51.9-22-50.4-50.4
            c1.2-24.4,21.1-44.2,45.4-45.4c28.5-1.4,51.9,22,50.4,50.4C222.7,458.9,202.9,478.7,178.5,479.9z M531.7,384H496h-79.9V192h115.7
            V384z M608,384.2h-44.1V178c0-9.9-8.1-18-18-18H402c-9.9,0-18,8.1-18,18v206h-49.8h-94.6C225,364.7,202,352,176,352
            s-49,12.7-63.6,32h-3.1l-67.9-67.9c-6-6-9.4-14.1-9.4-22.6V96c0-17.7,14.3-32,32-32h456c48.4,0,88,39.6,88,88V384.2z"/>
          <path d="M390.2,463h167.4v-14c0-9.9-8.1-18-18-18H408.2c-9.9,0-18,8.1-18,18V463z"/>
          <path d="M563.9,479.4h-180c-9.9,0-18,8.1-18,18v14h216v-14C581.9,487.4,573.8,479.4,563.9,479.4z"/>
        </g>
       </svg>
     );
   }
}

export default MobileHomeIcon
