import PropTypes from "prop-types";
import React from "react";
import PresentableString from "../global/PresentableString";
import CtaContactModal from "../global/CtaContactModal";
import '../../styles/components/agents/AgentIdentity.scss';

const AgentIdentity = ({
  name,
  topArea,
  firstName,
  brokerage,
  agentId,
  preferredAgent,
  lead,
  responseTime,
  addClass,
  phoneNumber,
  formattedNumber,
  leadConsentDisclaimer,
}) => (
  <React.Fragment>
    <div className={`agent-identity-component ${addClass || ""}`}>
      <h1 className="agent-name">{name}</h1>
      <p className="brokerage">Real Estate Agent at <br></br><PresentableString placeholder={false} string={brokerage}/></p>

      {/* placeholder for CTA component */}
      <CtaContactModal
        topArea={topArea}
        text='Get introduced'
        phoneNumber={phoneNumber}
        formattedNumber={formattedNumber}
        submittedText="Message Sent!"
        agentName={name}
        agentId={agentId}
        outlineStyle={false}
        leadConsentDisclaimer={leadConsentDisclaimer}
      />

      {
        (() => {
          if (responseTime) {
            return <span className="response-time">{responseTime}</span>
          }
        })()
      }

    </div>
  </React.Fragment>
);

AgentIdentity.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  brokerage: PropTypes.string,
  agentId: PropTypes.string,
  preferredAgent: PropTypes.bool,
  lead: PropTypes.bool,
  responseTime: PropTypes.string,
  addClass: PropTypes.string,
  phoneNumber: PropTypes.string
};

export default AgentIdentity;
