import React from "react";

class PhoneIcon extends React.Component {

  render () {
    return (
      <svg className="icon phone-icon" viewBox="0 0 128 110">
        <title>phone</title>
        <path fill="currentColor" d="M18.39,10.48l20-4.62a4.39,4.39,0,0,1,3.08.39,4.13,4.13,0,0,1,2.12,2.31l9.24,21.57a4.18,4.18,0,0,1,.2,2.79,5.71,5.71,0,0,1-1.55,2.6L40,45a70.72,70.72,0,0,0,14,20A67.75,67.75,0,0,0,74.05,79l9.43-11.56a5.78,5.78,0,0,1,2.6-1.54,4.17,4.17,0,0,1,2.79.2l21.57,9.24a4.13,4.13,0,0,1,2.31,2.12,4.35,4.35,0,0,1,.39,3.08l-4.62,20A3.68,3.68,0,0,1,107,103.3a4.9,4.9,0,0,1-2.89,1A87.84,87.84,0,0,1,59,92.13,89.63,89.63,0,0,1,26.87,60,87.84,87.84,0,0,1,14.73,14.91a4.92,4.92,0,0,1,1-2.89A3.68,3.68,0,0,1,18.39,10.48Z"></path>
      </svg>
    );
  }
}

export default PhoneIcon
