import React from "react";
import PropTypes from "prop-types";
import { STYLES_URI, ACCESS_TOKEN } from "../services/mapboxGeocoder";

class StaticMap extends React.Component {
  markerToMapboxString (marker) {
    const { lat, lon, size, color, image } = marker;
    return image ? `url-${encodeURIComponent(image)}(${lon},${lat})`
                 : `pin-${size || 's'}+${color || '0A87D8'}(${lon},${lat})`;
  }

  generateMarkers () {
    if (!this.props.markers.length) return ''

    return `${this.props.markers.map(this.markerToMapboxString).join(',')}/`;
  }

  generateCenterZoom () {
    if (this.props.centerLon && this.props.centerLat) {
      return `${this.props.centerLon},${this.props.centerLat},${this.props.zoom}`;
    } else {
      return 'auto';
    }
  }

  generateMapRetinaUrl() {
    return `${STYLES_URI}${this.props.mapStyle}/static/${this.generateMarkers()}${this.generateCenterZoom()}/${this.props.width}x${this.props.height}@2x/?access_token=${ACCESS_TOKEN}&attribution=${this.props.attribution}&logo=${this.props.mapboxLogo}`;
  }

  render () {
    const { lazyload, retina, alt } = this.props;

    const mapRetinaUrl = this.generateMapRetinaUrl();
    const mapNormalUrl = mapRetinaUrl.replace('@2x', '');

    if (this.props.renderAsBackground) {
      return (
        <div style={{backgroundImage: `url('${retina ? mapRetinaUrl : mapNormalUrl}')`}} className={this.props.className} />
      )
    } else {
      if (lazyload) {
        return (
          <img
            className={`lazyload ${this.props.className}`}
            data-srcset={retina ? `${mapNormalUrl} 1x, ${mapRetinaUrl} 2x` : `${mapNormalUrl} 1x`}
            data-src={mapNormalUrl}
            alt={alt} />
        )
      } else {
        return (
          <img
            className={this.props.className}
            srcSet={retina ? `${mapNormalUrl} 1x, ${mapRetinaUrl} 2x` : `${mapNormalUrl} 1x`}
            src={mapNormalUrl}
            alt={alt} />
        )
      }
    }
  }
}

StaticMap.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zoom: PropTypes.number,
  mapStyle: PropTypes.string,
  className: PropTypes.string,
  retina: PropTypes.bool,
  attribution: PropTypes.bool,
  mapboxLogo: PropTypes.bool,
  renderAsBackground: PropTypes.bool,
  lazyload: PropTypes.bool,
  alt: PropTypes.string,
  markers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
};

StaticMap.defaultProps = {
  width: 200,
  height: 200,
  zoom: 14,
  mapStyle: 'v1/homelight/cjqsm3msvakki2sp7j1ugl9gy',
  className: '',
  retina: true,
  attribution: false,
  mapboxLogo: false,
  renderAsBackground: false,
  lazyload: false,
  alt: '',
  markers: []
};

export default StaticMap;
