import PropTypes from "prop-types";
import MoveSafeBricon from "../icons/MoveSafeBricon";
import React from "react";

const MoveSafeHeader = ({agentName}) => (
  <div className='move-safe-header'>
    <div className='move-safe-icon-container'>
      <MoveSafeBricon />
    </div>
    <div className='move-safe-header-text'>
      <h2>{agentName} is Move Safe&trade; Certified</h2>
      <p>{agentName} uses the following tools, technology, and practices that help keep you safe during COVID-19:</p>
    </div>
  </div>
)

MoveSafeHeader.defaultProps = {
  agentName: "Jean"
}

MoveSafeHeader.propTypes = {
  agentName: PropTypes.string
};

export default MoveSafeHeader;
