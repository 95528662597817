import React from "react";
import PropTypes from "prop-types";
import GenericAutocomplete from "../global/GenericAutocomplete";

class AgentAutocomplete extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      agent: {},
    }
    this.selectAgentFromAutocomplete = this.selectAgentFromAutocomplete.bind(this);
    this.formatAgentSelected = this.formatAgentSelected.bind(this);
  }

  selectAgentFromAutocomplete (agent) {
    this.setState({ agent: { ...agent.attributes } });
    this.props.setPreviousAgent(`Previous Agent: ${agent.attributes.full_name}, id ${agent.id}`);
  }

  formatAgentSelected (agent) {
    return agent.attributes.full_name
  }

  render () {
    const mappableOrigins = [
      "https://www.homelight.com",
      "https://staging.homelight.com",
      "https://demo.homelight.com",
    ];
    const host = mappableOrigins.includes(location.origin) ? location.origin.replace(/www\.homelight\.com|homelight\.com/i, "hapi.homelight.com") : "https://review-apps-staging.hapi.homelight.com";
    const HAPI_URI = `${host}/api/agent-search-service/search/by-pattern-public`;

    const renderItem = this.props.renderItem ? this.props.renderItem : agent => (
      <div>
        <strong>{agent.attributes.full_name}</strong>
        <br />
        <small>{agent.attributes.office_display_name}, {agent.attributes.state_code}</small>
      </div>
    );

    return (
      <div>
        <div className="col-xs-12 no-pad agent-autocomplete">
          <GenericAutocomplete
            name={this.props.question.value}
            onSelect={this.props.selectAgentFromAutocomplete ? this.props.selectAgentFromAutocomplete : this.selectAgentFromAutocomplete}
            resultsKey="data"
            resultKey="full_name"
            formatFinalValue={this.formatAgentSelected}
            renderItem={renderItem}
            crossOrigin
            param="filter[pattern]"
            url={HAPI_URI}
            id="agent-name"
            placeHolder="Search by name"
            resultsLimit={5}/>
        </div>
      </div>
    );
  }
}

AgentAutocomplete.propTypes = {
  setPreviousAgent: PropTypes.func,
  renderItem: PropTypes.func,
  selectAgentFromAutocomplete: PropTypes.func
};

AgentAutocomplete.defaultProps = {
  setPreviousAgent: null,
  renderItem: null,
  selectAgentFromAutocomplete: null
};

export default AgentAutocomplete;
