import React from "react";

class EllipsisIcon extends React.Component {
   render () {
     return (
       <svg viewBox="0 0 25 6" height={this.props.height || "25px"} fill={this.props.fill || "black"}>
         <g id="client-portal_02-28-2019_1233" transform="translate(-333.000000, -158.000000)">
           <path d="M347.999964,161.000036 C347.999964,161.677118 347.752569,162.263054 347.257779,162.757844 C346.762988,163.252634 346.177052,163.50003 345.49997,163.50003 C344.822888,163.50003 344.236952,163.252634 343.742162,162.757844 C343.247372,162.263054 342.999976,161.677118 342.999976,161.000036 C342.999976,160.322954 343.247372,159.737018 343.742162,159.242227 C344.236952,158.747437 344.822888,158.500042 345.49997,158.500042 C346.177052,158.500042 346.762988,158.747437 347.257779,159.242227 C347.752569,159.737018 347.999964,160.322954 347.999964,161.000036 Z M354.874948,158.500042 C355.55203,158.500042 356.137966,158.747437 356.632756,159.242227 C357.127546,159.737018 357.374942,160.322954 357.374942,161.000036 C357.374942,161.677118 357.127546,162.263054 356.632756,162.757844 C356.137966,163.252634 355.55203,163.50003 354.874948,163.50003 C354.197866,163.50003 353.61193,163.252634 353.11714,162.757844 C352.622349,162.263054 352.374954,161.677118 352.374954,161.000036 C352.374954,160.322954 352.622349,159.737018 353.11714,159.242227 C353.61193,158.747437 354.197866,158.500042 354.874948,158.500042 Z M336.124993,158.500042 C336.802075,158.500042 337.388011,158.747437 337.882801,159.242227 C338.377591,159.737018 338.624987,160.322954 338.624987,161.000036 C338.624987,161.677118 338.377591,162.263054 337.882801,162.757844 C337.388011,163.252634 336.802075,163.50003 336.124993,163.50003 C335.44791,163.50003 334.861974,163.252634 334.367184,162.757844 C333.872394,162.263054 333.624999,161.677118 333.624999,161.000036 C333.624999,160.322954 333.872394,159.737018 334.367184,159.242227 C334.861974,158.747437 335.44791,158.500042 336.124993,158.500042 Z" />
         </g>
       </svg>
     );
   }
}

export default EllipsisIcon
