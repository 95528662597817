import React from "react";
import PropTypes from "prop-types";

function debounce(context, func, wait = 350) {
  let timeout;
  return function(...args) {
    let later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

class GenericAutocomplete extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      results: [],
      focus: null
    }
    this.search = this.search.bind(this);
    this.select = this.select.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.debouncedSearch = debounce(this, this.performSearch, this.props.debounceRate);
  }

  clickOutside (e) {
    let clickedOutsideComponent = !!this.element && !this.element.contains(e.target);
    let hasResults = this.state.results.length > 0;
    if (hasResults && clickedOutsideComponent) {
      this.setState({results: []});
    } else if (hasResults) {
      this.createClickOutside();
    }
  }

  createClickOutside () {
    const html = document.querySelector('html');
    html.addEventListener('click', _e => {
      this.clickOutside;
      html.removeEventListener('click');
    })
  }

  destroyClickOutside () {
    document.querySelector('html').removeEventListener('click');
  }

  componentWillUnmount () {
    this.destroyClickOutside();
  }

  search () {
    if (this.input.value && this.input.value.length > 2) {
      this.debouncedSearch();
    } else {
      this.setState({results: []}, () => this.destroyClickOutside());
    }
  }

  performSearch () {
    let {
      url, param, crossOrigin, resultsKey
    } = this.props;
    let searchUrl = `${url}?${param}=${this.input.value}`
    let options = {}
    if (crossOrigin) {
      options.credentials = "include"
    }

    fetch(searchUrl, options).then(res => res.json()).then(results => {
      if (resultsKey) {
        this.setState({ results: results[resultsKey] });
      } else {
        this.setState({ results });
      }

      this.createClickOutside();
    })
    .catch(() => {
      this.setState({results: []}, () => this.destroyClickOutside());
    });
  }

  select (result) {
    const { resultKey, formatFinalValue } = this.props
    this.input.value = formatFinalValue ? formatFinalValue(result) : result[resultKey] || this.input.value;

    this.setState({results: []}, () => this.destroyClickOutside());

    if (this.props.onSelect) {
      this.props.onSelect(result);
    }
  }

  moveFocus (dir) {
    let { focus, results } = this.state;
    focus = (focus === null) ? 0 : Math.max(0, Math.min( results.length - 1, focus + dir))
    this.setState({ focus });
  }

  acceptFocus () {
    if (this.state.focus !== null && !!this.state.results[this.state.focus]) {
      this.select(this.state.results[this.state.focus]);
    }
  }

  onKeyDown (e) {
    switch (e.which) {
      // up
      case 38:
        e.preventDefault();
        this.moveFocus(-1);
        break;
      // down
      case 40:
        this.moveFocus(1);
        break;
      // accept
      case 13:
        if ( this.state.results.length > 0 && this.state.focus === null) {
          this.select(this.state.results[0], 0);
        }
        this.acceptFocus();
        e.preventDefault();
        break;
    }
  }

  renderResults () {
    const { renderItem, resultKey, uniqResultIdentifier, resultsLimit } = this.props

    if (!this.state.results) return "";
    if (!this.state.results.length) return "";
    let items = this.state.results;
    if (resultsLimit) {
      items = this.state.results.slice(0, resultsLimit)
    }
    return (
      <ul>
        {items.map( (result, k) => (
          <li
            key={ result[uniqResultIdentifier] }
            className={ k === this.state.focus ? 'active' : '' }
            onClick={() => this.select(result) }
          >
            { renderItem ? renderItem(result) : result[resultKey] }
          </li>
        ))}
      </ul>
    )
  }

  render () {
    const { defaultValue } = this.props;
    let inputref = r => { this.input = r };
    let ref = r => { this.element = r };
    return (
      <div ref={ref} className={`generic-autocomplete ${this.props.addClass}`}>
        <input
          defaultValue={defaultValue}
          autoComplete="off"
          placeholder={this.props.placeHolder}
          onInput={this.search}
          onKeyDown={this.onKeyDown}
          ref={inputref}
          id={this.props.id}
          className={this.props.inputClass}
          name={this.props.name}
        />
        { this.renderResults() }
      </div>
    )
  }
}

/**
  param: The query parameter to add the search term to (REQUIRED)
  url: The Url to use to perform the search (REQUIRED)
  crossOrigin: Whether or not to include credentials (needed for HAPI)
  defaultValue: The initial value of the input
  placeHolder: This is obvious
  name: The name attribute of the input
  inputClass: This is obvious
  addClass: A class to add to the wrapper
  id: An id for the input
  onSelect: Callback for when an item is selected
  renderItem: A function that receives each item and returns eother text or jsx to render each list item
  formatFinalValue: Used in place of resultKey, formats the text to use for the input value once an item is selected
  resultsKey: The key name that contains the list within the response payload.
  resultKey: The key name to use for setting the value of the input
  debounceRate: The rate at which to hit the server (default 100ms)
  uniqResultIdentifier: The key to use for the key attribute of each list item
 resultLimit: Optional limit to results shown
 */

GenericAutocomplete.propTypes = {
  param: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeHolder:  PropTypes.string,
  name: PropTypes.string,
  inputClass: PropTypes.string,
  addClass: PropTypes.string,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  formatFinalValue: PropTypes.func,
  resultKey: PropTypes.string,
  crossOrigin: PropTypes.bool,
  resultsKey: PropTypes.string,
  debounceRate: PropTypes.number,
  uniqResultIdentifier: PropTypes.string,
  renderItem: PropTypes.func,
  resultsLimit: PropTypes.number
};

GenericAutocomplete.defaultProps = {
  defaultValue: null,
  placeHolder: 'Search',
  name: null,
  inputClass: '',
  addClass: '',
  id: null,
  onSelect: null,
  formatFinalValue: null,
  resultKey: null,
  crossOrigin: false,
  resultsKey: null,
  debounceRate: 100,
  uniqResultIdentifier: 'id',
  renderItem: null,
  resultsLimit: null
};

export default GenericAutocomplete;
