import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../global/LoadingSpinner";

class PropertyTypeInterstitial extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      timeReached: false
    };
    this.friendlyPropertyName.bind(this);
    this.buildDescription.bind(this);
  }

  friendlyPropertyName (propertyType, plural=false) {
    // fallback - this shouldn't happen, but in case.
    if (!propertyType) {
      return null;
    }
    if (!plural || (propertyType.charAt(propertyType.length -1) === "s")) {
      return `${propertyType.toString().split("_").join(" ")}`;
    }
    if(propertyType.toString().split("_")[0] === 'tenancy') {
      // custom edge case
      return 'tenancies in common';
    }
    if (propertyType.charAt(propertyType.length -1) === "y") {
      propertyType = `${propertyType.subString(0,propertyType.length - 1)}ie`;
    }
    return `${propertyType.toString().split("_").join(" ")}s`;
  }



  buildDescription (propertyType, leadType) {
    const propertyName = this.friendlyPropertyName(propertyType);
    const pluralPropertyName = this.friendlyPropertyName(propertyType, plural=true);
    if (!propertyName){
      return `Thanks! We're looking for agents who focus on this type of property.`
    }
    if (leadType === "buyer") {
      return `According to our research, an agent who focuses on buying ${pluralPropertyName} will be a better negotiator for you than one who handles a variety of property types.`
    }
    return `Because you're looking to sell a ${propertyName}, we're looking for agents who focus on selling ${pluralPropertyName}. Our research shows that an agent who focuses on a specific property type outperforms those who don't.`
  }

  componentDidMount() {
    this.setTimer();
  }

  setTimer() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      this.setState({timeReached: true});
      this.props.answerQuestion({"value": this.props.propertyType});
      // move on after 6 seconds
      this._timer = null;
    }, 6000);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }


  render () {
    const { propertyType, leadType } = this.props;

    let description =  this.buildDescription(propertyType, leadType);
      return (
      <div id="qaas-interstitial" className="question-contents">
        <h2 className="interstitial-question">We're analyzing all similar properties sold near you in the past 10 years.</h2>
        <p className="interstitial-question">{ description }</p>
        <LoadingSpinner style='chasing' text="Performing market analysis..."/>
      </div>
    );
  }
}

PropertyTypeInterstitial.propTypes = {
  propertyType: PropTypes.string,
  leadType: PropTypes.string,
};

export default PropertyTypeInterstitial;
