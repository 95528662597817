import React from "react";
import CheckIcon from "../icons/CheckIcon";
import PropTypes from "prop-types";

const MultiselectQuestion = props => {
  const {
    config,
    question,
    answers,
    answerQuestion,
    completeDynamicText,
    newQuizStyles
  } = props;

  const [ selectedAnswers, setSelectedAnswers ] = React.useState(answers[question.value] || []);
  const [ errorFields, setErrorFields ] = React.useState({});

  const optionIsSelected = option => {
    return selectedAnswers.some(answer => answer.id === option.id);
  };

  const selectOption = option => {
    let selections;
    if (question.single_select) {
      selections = [option];
    } else if (optionIsSelected(option)) {
      selections = selectedAnswers.filter(answer => answer.id !== option.id);
      removeError(option.id);
    } else {
      if (option.clear_values) {
        selections = [option];
      } else {
        // if selecting a regular option, make sure the option to clear values is removed
        selections = [
          ...selectedAnswers.filter(answer => !answer.clear_values),
          option
        ];
      }
    }

    setSelectedAnswers(selections);
  };

  const removeError = id => {
    if (errorFields[id]) {
      let errors = {...errorFields};
      delete errors[id];
      setErrorFields(errors);
    }
  };

  const isEmptyValue = answer => answer === null || answer === undefined || answer === '';

  const submitAnswers = () => {
    const defaultValidationMessage = 'Please enter a value (minimum 3 characters)';
    let validationErrors = false;
    let extend = {};
    selectedAnswers.forEach(answer => {
      if (answer.free_form && answer.value.trim().length < 3) {
        const validationMessage = answer.validation_message || defaultValidationMessage;
        setErrorFields({...errorFields, [answer.id]: validationMessage});
        validationErrors = true;
      }
    });

    if (validationErrors) { return; }

    let answerValues = selectedAnswers.filter(answer => !isEmptyValue(answer));
    let value = answerValues.map(answer => {
      if (answer.free_form) {
        let key = `${question.value}_${answer.id}`;
        extend = { [key]: answer.value }
        return null
      } else if (answer.extend_id) {
        extend[answer.extend_id] = answer.value
      } else if (answer.clear_values) {
        // Want ability to return [] explicitly but pass validation for empty response
        extend = {explicitEmpty: true};
      }

      return answer.value;
    }).filter(x => x);

    answerQuestion({ value, extend });
  };

  // soft validation for submit button styling
  const requirementsNotMet = () => {
    return (question.required && !selectedAnswers.length)
      || selectedAnswers.some(answer => answer.free_form && answer.value.trim().length < 3);
  };

  const renderCheckbox = isSelected => {
    if (question.show_checkbox) {
      return (
        <div className='checkbox-wrapper'>
          <input
            className='multiselect-checkbox'
            type='checkbox'
            checked={isSelected}
            readOnly
          />
          <CheckIcon />
        </div>
      );
    }

    return <CheckIcon />;
  };

  const renderCustomInput = option => {
    const error = errorFields[option.id];
    return (
      <div className={`input-wrapper ${error ? 'error' : ''}`} data-error={error}>
        <input
          key={`${option.id}-input`}
          className='multiselect-custom-input'
          type='text'
          placeholder='Start typing'
          onChange={e => onInputChange(e, option)}
          autoFocus
        />
      </div>
    );
  };

  const onInputChange = (e, option) => {
    if (!optionIsSelected(option)) {
      selectOption(option);
    }

    const inputValue = e.target.value;
    const selections = [...selectedAnswers];
    const customSelection = selections.find(selection => selection.id === option.id);
    if (customSelection) {
      customSelection.value = inputValue;
    } else {
      // pass in all existing values on option object, along with input value
      selections.push({ ...option, ...{ value: inputValue } });
    }

    if (inputValue.length >= 3) {
      removeError(option.id);
    }

    setSelectedAnswers(selections);
  };

  const buttonStyle = newQuizStyles ? 'button-no-outline conversational' : 'button button-outline';

  const renderedOptions = question.options.map(option => {
    const isSelected = optionIsSelected(option);
    const text = completeDynamicText(option.text);
    const isCustom = !!option.free_form;
    const identifierClassName = option.id.toString().replace(/[^A-Z0-9]+/ig, '-').toLowerCase();
    let classes = [`answer-${identifierClassName} ${buttonStyle} multi-select`];
    if (isSelected) {
      classes.push('selected');
    }

    return (
      <React.Fragment key={option.id}>
        <div onClick={() => selectOption(option)} className={classes.join(' ')}>
          {renderCheckbox(isSelected)}
          <a >
            {text}
          </a>
        </div>
        {isCustom && optionIsSelected(option) && renderCustomInput(option)}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {renderedOptions}
      <a
        className={`button multiselect-submit ${requirementsNotMet() ? 'disabled' : ''}`}
        onClick={submitAnswers}
      >
        {config.finish_btn_text || 'Submit'}
      </a>
    </React.Fragment>
  );
};

MultiselectQuestion.propTypes = {
  config: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({}).isRequired,
  answers: PropTypes.shape({}).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  completeDynamicText: PropTypes.func.isRequired,
  newQuizStyles: PropTypes.bool.isRequired
};

export default MultiselectQuestion;
