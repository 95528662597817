import React from "react";
import PropTypes from "prop-types";
import StaticMap from "../global/StaticMap";

class MultistepConfirmLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'address'
    };

    if (props.answers && this.requiresUnit()) {
      this.state['secondary_address'] = props.answers['secondary_address'];
    }

    this.handleChange = this.handleChange.bind(this);
    this.setUnitStage = this.setUnitStage.bind(this);
  }

  // QuizService::Instructions removes 'secondary_address' if lob returns property as deliverable (no unit needed)
  requiresUnit () {
    return this.props.step.questions.some(q => q.value === 'secondary_address');
  }

  setUnitStage () {
    this.setState({stage: 'unit'});
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  restartQuiz (e) {
    e.preventDefault();
    location = location.pathname;
  }

  renderStaticMap () {
    const { answers } = this.props;
    const lon = answers.center_lon || (answers.geocode && answers.geocode.lng);
    const lat = answers.center_lat || (answers.geocode && answers.geocode.lat);

    if (lon && lat) {
      return (
        <StaticMap
          className='map'
          markers={[{ lon, lat }]}
          centerLon={lon}
          centerLat={lat}
          width='550'
          height='225'
        />
      );
    }
  }

  renderAddressStage (address) {
    const { step, completeDynamicText } = this.props;

    return (
      <React.Fragment>
        {step.heading && (
          <h2>{step.heading}</h2>
        )}
        {step.subheading && (
          <p className='confirm-subheading'>{completeDynamicText(step.subheading)}</p>
        )}
        {this.renderStaticMap()}
        <div className='address'>
          <div className='address-text'>{address}</div>
          {this.renderActionButtons()}
        </div>
      </React.Fragment>
    );
  }

  renderUnitStage (address) {
    const { onSubmit, step } = this.props;
    const unitQuestion = step.questions.find(q => q.value === 'secondary_address');

    return (
      <React.Fragment>
        <h2 className='unit-header'>{unitQuestion && unitQuestion.text}</h2>
        {this.renderStaticMap()}
        <div className='address'>
          <div className='address-text'>{address}</div>
          {this.renderUnitInput()}
          <div className='actions'>
            <a className='button button-secondary' onClick={onSubmit}>No Unit Number</a>
            <a className='button next-button' onClick={onSubmit}>Submit</a>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderUnitInput () {
    const unitValue = this.state.secondary_address;

    return (
      <div className='unit-input'>
        <input
          placeholder='Enter unit number'
          name='secondary_address'
          value={unitValue}
          onChange={this.handleChange}
          type='text'
        />
      </div>
    );
  }

  renderActionButtons () {
    const { onSubmit, step } = this.props;
    const { stage } = this.state;
    const isAddressStage = stage === 'address';
    const buttonText = step.button_text && isAddressStage ? step.button_text : 'Next';
    const confirmAction = isAddressStage && this.requiresUnit() ? this.setUnitStage : onSubmit;

    return (
      <div className='actions'>
        <a className='button button-secondary' onClick={this.restartQuiz}>No</a>
        <a className='button next-button' onClick={confirmAction}>{buttonText}</a>
      </div>
    );
  }

  render () {
    const { answers, transition } = this.props;
    const { stage } = this.state;
    const addressDisplay = answers.full_address || (answers.geocode && answers.geocode.full_address);

    return (
      <div className='confirm-location-component multistep-confirm-location'>
        <div className={`${transition ? '' : 'animate-in'}`}>
          <div className={`${transition ? 'animate-in' : ''}`}>
            {stage === 'unit' ? this.renderUnitStage(addressDisplay) : this.renderAddressStage(addressDisplay)}
          </div>
        </div>
      </div>
    )
  }
}

MultistepConfirmLocation.propTypes = {
  answers: PropTypes.shape({}).isRequired,
  step: PropTypes.shape({}).isRequired,
  transition: PropTypes.bool,
  completeDynamicText: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

MultistepConfirmLocation.defaultProps = {
  transition: false
};

export default MultistepConfirmLocation;
