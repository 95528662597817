import React from 'react'

const TicIcon = ({height, fill}) => (
  <svg viewBox="0 0 672 513.2" height={height || "25px"} fill={fill || "black"}>
    <path d="M204,256h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C192,250.6,197.4,256,204,256z"/>
    <path d="M428,352h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C416,346.6,421.4,352,428,352z"/>
    <path d="M108,352h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C96,346.6,101.4,352,108,352z"/>
    <path d="M108,256h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C96,250.6,101.4,256,108,256z"/>
    <path d="M204,352h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C192,346.6,197.4,352,204,352z"/>
    <path d="M660.4,104.5L660.4,104.5l-0.4-0.4c-2.3-1.9-4-2.9-4-2.9l-0.2,0.3L496.5,2.4c-5.3-3.3-12.1-3.2-17.3,0.2L337,96.8l-3,0.2
      l-30-19.6L184.3,3.3c0,0-4.3-4.5-11.2-4.5c-6.8,0-12.2,4.5-12.2,4.5L10.2,104.7c0,0-10.2,5.7-10.2,23.3v376c0,4.4,3.6,8,8,8h9.5H24
      h129h40h135h16h129h40h135h7h9c4.4,0,8-3.6,8-8V128C672,116.4,665.1,108.5,660.4,104.5z M352,125.3L488.3,35L640,129.4V480H525v-60
      c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v60H352V128V125.3z M173.2,34.2L272,95l48,33v352H205v-60c0-6.6-5.4-12-12-12h-40
      c-6.6,0-12,5.4-12,12v60H32V128L173.2,34.2z"/>
    <path d="M428,256h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C416,250.6,421.4,256,428,256z"/>
    <path d="M524,256h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C512,250.6,517.4,256,524,256z"/>
    <path d="M524,352h40c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6,0-12,5.4-12,12v40C512,346.6,517.4,352,524,352z"/>
  </svg>
)

export default TicIcon
