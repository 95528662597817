import React from "react";
import PropTypes from "prop-types";
import CloseLightIcon from "../icons/CloseLightIcon";
import '../../styles/components/global/NoticeBanner.scss';

class NoticeBanner extends React.Component {
  constructor (props) {
    super(props);
    this.removeBanner = this.removeBanner.bind(this);

    this.state = {
      display: true
    };
  }

  removeBanner() {
    this.setState({ display: false });
  }

  render () {
    const { noticeText } = this.props;
    let { display } = this.state;
    const closeBannerIconStyles = {
      width: '15px',
      height: '15px'
    };
    return (
      <div className={`notice-banner ${display ? 'show-notice-banner' : 'hide-notice-banner'}`}>
        <div className='close-banner-icon' style={closeBannerIconStyles} onClick={this.removeBanner}>
          <CloseLightIcon />
        </div>
        <p>{noticeText}</p>
      </div>
    );
  }
}

NoticeBanner.propTypes = {
  noticeText: PropTypes.string.isRequired
};

export default NoticeBanner;
