import React from "react";
import AgentProfileQuizTransactionQuestion from "./AgentProfileQuizTransactionQuestion";
import CheckIcon from "../icons/CheckIcon";
import QuizController from "../global/QuizController";
import '../../styles/components/agents/AgentProfileQuiz.scss';

class AgentProfileQuiz extends React.Component {

  constructor (props) {
    super(props);
    let agent = JSON.parse(props.agent)
    let step = 0;
    let agent_possessive_first_name = (agent.first_name[-1] === 's' ? `${agent.first_name}'` : `${agent.first_name}'s`);
    let answers = {};

    this.state = {
      step,
      agent,
      agent_possessive_first_name,
      answers,
    };

    this.scrollBreakpoint = 1140;
    this.answerQuestion = this.answerQuestion.bind(this);
    this.onStepSubmit = this.onStepSubmit.bind(this);
    this.requestIntro = this.requestIntro.bind(this);
  }

  componentDidMount () {
    if (this.props.sticky) {
      this.setState({componentElement: document.querySelector(".agent-profile-quiz-component")});
      this.watchScroll();
    }
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  trackConversion () {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackConversion(true);
      this.trackEvent('Lead', 'conversion', 'agent_right_sidebar', 1);
    } catch (err) { console.error(err) }
  }


  watchScroll () {
    let aq           = document.querySelector('.agent-quiz');
    let content      = document.querySelector('.content');
    let contentHeight = content.offsetHeight;
    let upperBound    = 71;
    let componentHeight = 391;
    let componentMarginBottom = 30;
    let lowerBound = contentHeight - componentHeight + componentMarginBottom;

    if (innerWidth < this.scrollBreakpoint) {
      return;
    }

    PubSub.sub('page-height-changed', function () {
      contentHeight = content.offsetHeight;
    });

    window.addEventListener('scroll', e => {

      let pastUpperBound = pageYOffset > upperBound;
      let pastLowerBound = pageYOffset >= lowerBound;

      if (innerWidth < this.scrollBreakpoint) {
        window.removeEventListener('scroll');
        return aq.removeAttribute('style');
      }

      if (pastUpperBound && pastLowerBound) {
        aq.style.position = 'fixed';
        aq.style.top = `-${ componentHeight + (pageYOffset - contentHeight)}px`;
      } else if (pastUpperBound) {
        aq.style.position = 'fixed';
        aq.style.top = 0;
      } else {
        aq.removeAttribute('style');
      }
    });
  }

  onStepSubmit (answers) {
    const updatedAnswers = Object.assign(this.state.answers, answers);
    this.setState({ answers: updatedAnswers });
  }

  renderBuyOrSellQuestion () {
    return (
      <AgentProfileQuizTransactionQuestion
        answerQuestion={this.answerQuestion}
        agentFirstName={this.state.agent.first_name}
        agentPossessiveFirstName={this.state.agent_possessive_first_name}
      />
    );
  }

  getUserSpecificCopy () {
    const { user_type } = this.state.answers;
    let copy = {
      propertyTypeHeader: 'What type of home?',
      priceHeader: 'What is the estimated price range?',
      timelineHeader: ''
    };

    if (user_type) {
      const isBuyer = user_type === 'buyer';
      const actionWord = isBuyer ? 'buy' : 'sell';
      copy.propertyTypeHeader = `What type of home are you looking to ${actionWord}?`;
      copy.priceHeader = isBuyer ? 'How much are you looking to spend?'
        : 'What is your estimated home value?';
      copy.timelineHeader = `How soon are you looking to ${actionWord}?`;
    }

    return copy;
  }

  static getContactCopy (agentName, interest) {
    let map = {
      research: [
        'Connect with a HomeLight Concierge',
        `Learn more about ${agentName} and other top agents.`
      ],
      considering_agent: [
        `Great, we can help you get connected with ${agentName}`,
        `Find out more about ${agentName} and other top agents.`
      ],
      other: [
        'Save your answers to easily compare agents',
        `Find out more about ${agentName} and other top agents.`
      ]
    };

    const header = !!map[interest] ? map[interest][0] : 'Save your answers to easily compare agents';
    const description = !!map[interest] ? map[interest][1] : `Find out more about ${agentName} and other top agents.`;
    return { contactHeader: header, contactDescription: description };
  }

  buildCopyContext () {
    const { answers: {interest}, agent } = this.state;
    const agentName = agent.first_name || 'this agent';

    const { propertyTypeHeader, priceHeader, timelineHeader  } = this.getUserSpecificCopy();
    const { contactHeader, contactDescription } = AgentProfileQuiz.getContactCopy(agentName, interest);

    return {
      agentFirstName: agentName,
      whoContact: interest === 'question' ? agentName : 'we',
      propertyTypeHeader: propertyTypeHeader,
      priceHeader: priceHeader,
      timelineHeader: timelineHeader,
      contactHeader: contactHeader,
      contactDescription: contactDescription
    };
  }

  render () {

    if (this.state.submitted) {
      return (
        <div className="agent-profile-quiz-component submitted-screen">
          <CheckIcon />
          <h4>Submitted!</h4>
          <p>We'll reach out to confirm your details and answer any questions you may have.</p>
        </div>
      );
    }

    return (
      <div className={`agent-profile-quiz-component ${this.props.addClass || ""}`}>
        <div className="apqc-question-wrap">{ this.renderStep() }</div>
      </div>
    );
  }

  renderStep () {
    const { user_type } = this.state.answers;
    if (!user_type) {
      return this.renderBuyOrSellQuestion();
    }

    const copyContext = this.buildCopyContext();

    return <QuizController source='web'
                           source_form={ this.props.quizSlug }
                           source_page_type='quiz_flow'
                           quizId={ this.props.quizSlug }
                           config_url={ `/quizzes/${this.props.quizSlug}` }
                           copyContext={ copyContext }
                           onStepSubmit={ this.onStepSubmit }
                           onQuizComplete={ this.requestIntro }
                           extendState={{ user_type: user_type }}
                           areaSlug={this.props.areaSlug}
                           urlPersistence={ false } />;
  }

  requestIntro (answers) {
    let { agent } = this.state;

    server.post('/quiz_introduction', {
      lead_id:  answers.lead_id,
      agent_id: agent.id,
    })
      .then(res => {
        this.setState({submitted: true});
        this.trackConversion();
      }).catch(err => {
        this.setState({submitted: true, submittedWithErrors: true});
      });
  }

  answerQuestion (key, value) {
    let { answers } = this.state;

    if (key && value) {
      answers[key] = value;
      this.trackEvent('Lead', 'answer_profile_quiz_question', `agent_right_sidebar_${key}`, 0);
    }

    this.setState(prev => {
      return { step: prev.step + 1, answers}
    });
  }

}

export default AgentProfileQuiz;
