import React, { useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import PageHeader from './PageHeader';
import HeaderMenu from './HeaderMenu';
import HeaderLogo from './HeaderLogo';
import SearchBar from '../property/components/SearchBar';
import tracking from '../services/tracking_service';
import '../../styles/global/_shared_ultra_sub_nav.scss';

const SharedUltraSubNav = ({
  addClass,
  logoUrl,
  lightNav,
  signInPath,
  signedInProps,
  phoneNumber,
  formattedNumber,
  favoritesFeatureFlag,
  notificationSettingsFeatureFlag,
  myHomeFeatureFlag,
  sharedUltraSubscription,
  mapboxToken,
  homeSearchUrl,
  propertyFromAddressUrl,
  useAutocompleteApi
}) => {
  useEffect(() => {
    tracking.trackEvent(
      'View',
      'shared_sub_nav_shown',
      sharedUltraSubscription.provider_id
    )
  }, [])

  let agentName = 'My Agent'
  if (typeof window !== 'undefined' && window.innerWidth >= 767) {
    agentName = sharedUltraSubscription.provider_name
  }

  const agentImage = sharedUltraSubscription.provider_image ?
    <img className="agent-image" src={sharedUltraSubscription.provider_image} alt="" /> :
    <div className="agent-image image-placeholder">{sharedUltraSubscription.provider_name[0]}</div>

  return (
    <nav className={`page-header-component shared-subscription-nav clearfix ${addClass}`}>
      <div className="content-wrapper">
        <div className="menu-logo">
          <HeaderLogo logo="white" logoUrl={logoUrl}/>
        </div>
        <div className="vertical-line" />
        <div className="agent-share-section">
          <div className="agent-image-wrapper">
            {agentImage}
          </div>
          <div className="agent-info-wrapper">
            <div className="agent-name">
              {agentName}
            </div>
            <div className="agent-brokerage">
              {sharedUltraSubscription.brokerage_name}
            </div>
          </div>
        </div>
        <div className="search-bar-section">
          <ErrorBoundary>
            <SearchBar
              mapboxToken={mapboxToken}
              homeSearchUrl={homeSearchUrl}
              propertyFromAddressUrl={propertyFromAddressUrl}
              useAutocompleteApi={useAutocompleteApi}
            />
          </ErrorBoundary>
        </div>
        <div className="menu">
          <HeaderMenu
            lightNav={lightNav}
            signInPath={signInPath}
            phoneNumber={phoneNumber}
            formattedNumber={formattedNumber}
            favoritesFeatureFlag={favoritesFeatureFlag}
            notificationSettingsFeatureFlag={notificationSettingsFeatureFlag}
            myHomeFeatureFlag={myHomeFeatureFlag}
            {...signedInProps}
          />
        </div>
      </div>
    </nav>
  );
}

export default SharedUltraSubNav;
