import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DropdownQuestion = ({question, answers, completeDynamicText}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(answers[question.value]);

  const styles = {
    selectStyle: {
      backgroundColor: 'white',
      borderRadius: '0.8rem',
      height: '4.8rem',
      '&:focus': {backgroundImage: 'none'},
    },
  };

  const onInputChange = e => {
    setSelectedAnswer(e.target.value);
  };

  const renderedOptions = question.options.map(option => {
    const text = completeDynamicText(option.text);
    return <option value={option.value}>{text}</option>;
  });

  return (
    <React.Fragment>
      <select
        name={question.value}
        style={styles.selectStyle}
        value={selectedAnswer}
        onChange={e => onInputChange(e)}
      >
        {renderedOptions}
      </select>
    </React.Fragment>
  );
};

DropdownQuestion.propTypes = {
  question: PropTypes.shape({
    dropdown: PropTypes.bool,
    single_select: PropTypes.bool,
    multiselect: PropTypes.bool,
    show_checkbox: PropTypes.bool,
    agent_search: PropTypes.bool,
    text: PropTypes.string,
    value: PropTypes.string,
    buttons: PropTypes.bool,
    required: PropTypes.bool,
    validator: PropTypes.string,
    create_lead_on_completion: PropTypes.bool,
    geocode: PropTypes.bool,
    geocode_types: PropTypes.string,
    geocode_value: PropTypes.string,
    emit: PropTypes.bool,
    phone: PropTypes.bool,
    textarea: PropTypes.bool,
    type: PropTypes.string,
    always_ask: PropTypes.bool,
    currency: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        value: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }).isRequired,
  answers: PropTypes.shape({
    area_slug: PropTypes.string,
    center_lat: PropTypes.number,
    center_lon: PropTypes.number,
    price: PropTypes.number,
    customer_price_estimate: PropTypes.number,
    additional_property_details: PropTypes.string,
    authenticity_token: PropTypes.string,
    bathroom_count: PropTypes.string,
    bedroom_count: PropTypes.string,
    condition: PropTypes.string,
    confirm_occupant_at_closing: PropTypes.string,
    current_instructions: PropTypes.arrayOf(
      PropTypes.shape({
        button_text: PropTypes.string,
        disclaimer: PropTypes.string,
        confirm: PropTypes.bool,
        async_persistence: PropTypes.bool,
        confirm_question: PropTypes.string,
        confirm_type: PropTypes.string,
        desc: PropTypes.string,
        heading: PropTypes.string,
        id: PropTypes.string,
        questions: PropTypes.arrayOf(PropTypes.shape({})),
        track: PropTypes.string,
      })
    ),
    disclaimer: PropTypes.bool,
    display_city: PropTypes.string,
    email: PropTypes.string,
    entry_action: PropTypes.string,
    entry_controller: PropTypes.string,
    entry_path: PropTypes.string,
    find_and_attach_property: PropTypes.shape({
      city_name: PropTypes.string,
      county: PropTypes.string,
      display_address: PropTypes.string,
      latitude: PropTypes.number,
      lob_status: PropTypes.string,
      longitude: PropTypes.number,
      primary_line: PropTypes.string,
      primary_number: PropTypes.string,
      search_id: PropTypes.string,
      secondary_designator: PropTypes.string,
      secondary_number: PropTypes.string,
      state_name: PropTypes.string,
      street_name: PropTypes.string,
      street_predirection: PropTypes.string,
      street_suffix: PropTypes.string,
      uuid: PropTypes.string,
      zip_code: PropTypes.string,
      zip_code_plus_4: PropTypes.string,
    }),
    finished_size: PropTypes.string,
    full_address: PropTypes.string,
    full_name: PropTypes.string,
    garage_size: PropTypes.string,
    heard_about_homelight_on: PropTypes.string,
    lead_score: PropTypes.number,
    lead_probability: PropTypes.number,
    lot_size: PropTypes.string,
    property_deliverability: PropTypes.string,
    property_type: PropTypes.string,
    property_uuid: PropTypes.string,
    question_id: PropTypes.string,
    quiz_session_uuid: PropTypes.string,
    referrer: PropTypes.string,
    removed_questions: PropTypes.shape([PropTypes.string]),
    source: PropTypes.string,
    source_form: PropTypes.string,
    source_page_type: PropTypes.string,
    step: PropTypes.number,
    timeline: PropTypes.string,
    total_steps: PropTypes.number,
    user_manual_estimated_price: PropTypes.number,
    user_type: PropTypes.string,
    why: PropTypes.string,
    year_built: PropTypes.string,
  }).isRequired,
  completeDynamicText: PropTypes.func.isRequired,
};

export default DropdownQuestion;
