import React from 'react';
import PropTypes from 'prop-types';
import {createNumberMask} from '../../utils/createNumberMask';
import {maskInput} from '../../utils/vanillaTextMask';

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  componentDidMount() {
    const currencyMask = createNumberMask({
      prefix: '$',
      allowDecimal: true,
    });

    const currencyMaskNoDecimal = createNumberMask({
      prefix: '$',
      allowDecimal: false,
    });

    let mask = this.props.decimal === false ? currencyMaskNoDecimal : currencyMask;

    this.mask = maskInput({
      mask,
      inputElement: this.input,
      guide: false,
    });
  }

  componentWillUnmount() {
    this.mask.destroy();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.value !== nextProps.value;
  }

  handleOnChange(e) {
    const {onChange = () => {}} = this.props;
    onChange(e);
  }

  handleOnBlur() {
    const {onBlur = () => {}} = this.props;
    onBlur();
  }

  handleOnKeyDown(e) {
    const {onKeyDown = () => {}} = this.props;
    onKeyDown(e);
  }

  render() {
    let ref = r => {
      this.input = r;
    };
    const {disabled} = this.props;
    return (
      <input
        autoComplete="transaction-amount"
        pattern="\\d*"
        maxLength="12"
        placeholder={this.props.placeholder}
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        readOnly={this.props.readOnly}
        ref={ref}
        type="tel"
        name={this.props.name}
        onKeyUp={this.handleOnChange}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        onKeyDown={this.handleOnKeyDown}
        disabled={disabled}
      />
    );
  }
}

CurrencyInput.defaultProps = {
  placeholder: '',
  readOnly: false,
  name: '',
  decimal: false,
  onChange: () => {},
  disabled: false,
};

CurrencyInput.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  decimal: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CurrencyInput;
