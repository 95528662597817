import React from "react";

class CheckCircleBricon extends React.Component {

  render() {
    const color = this.props.color || '#46B6FF';
    return (
      <svg viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-517.000000, -359.000000)" fillRule="nonzero">
            <g transform="translate(517.000000, 359.000000)">
                <path d="M24,12 C24,18.6274355 18.6274355,24 12,24 C5.37256452,24 0,18.6274355 0,12 C0,5.37256452 5.37256452,0 12,0 C18.6274355,0 24,5.37256452 24,12 Z" fill={color}></path>
                <path d="M10.6119677,18.3539032 L19.5151935,9.45067742 C19.8175161,9.14835484 19.8175161,8.65814516 19.5151935,8.35582258 L18.4203387,7.26096774 C18.1180161,6.95859677 17.6278065,6.95859677 17.3254355,7.26096774 L10.0645161,14.5218387 L6.67456452,11.1318871 C6.37224194,10.8295645 5.88203226,10.8295645 5.57966129,11.1318871 L4.48480645,12.2267419 C4.18248387,12.5290645 4.18248387,13.0192742 4.48480645,13.3215968 L9.51706452,18.3538548 C9.81943548,18.6562258 10.3095968,18.6562258 10.6119677,18.3539032 Z" fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default CheckCircleBricon;
