/**
* Simple Generic Modal
*
* Example usage:
*
*  <GenericModal show={this.props.show} register={this.setModalInstance}>
*    <h1>Are you {this.props.agentName}?</h1>
*    <div className='sub-heading'>HomeLight has identified you as a top performing agent based on your history of closed transactions.</div>
*    <h4 className='sub-heading2'>Claim your profile now and begin matching with buyers and sellers in your local market.</h4>
*    <a href={this.props.buttonLink} className="button button-outline">Claim my HomeLight Profile</a>
*  </GenericModal>
*
* Props:
* register: Pass in a function here from your wrapper parent function to receive an instance of GenericModal
* clickOutsideToDismiss: Default is true. If set to false, clicking outside of this modal will not close it
* confirmCallback: The callback to invoke when clicking the confirm button
* dismissCallback: The callback to invoke when clicking the dismiss button
* buttons: (Optional) An array of buttons to generate. If no array is provided, no buttons will be rendered Examples:
* className: (Optional) A class to apply to outer element of the modal
*
* Button Examples.
* Default: ["Ok"] -> Just the confirm button
* [null, "Cancel"] -> No Confirm, only cancel
* ["Got it!"] -> Only confirm button
* ["Yes Please", "No Thanks"] -> Confirm and dismiss buttons
* [] -> No buttons
*
**/

import React from "react";
import CloseLightIcon from '../icons/CloseLightIcon';
import '../../styles/components/global/GenericModal.scss';

class GenericModal extends React.Component {
  constructor (props) {
    super(props);
    this.clickOutside = this.clickOutside.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      showModal: this.props.show
    };
  }

  componentDidMount () {
    if (this.props.register) {
      this.props.register(this);
    }
    let { clickOutsideToDismiss } = this.props;
    if (clickOutsideToDismiss === undefined || clickOutsideToDismiss === true) {
      this.createClickOutside();
    }
  }

  closeModal () {
    const { dismissCallback } = this.props;

    this.setState({ showModal: false });
    dismissCallback && dismissCallback();
  }

  clickOutside (e) {
    if (this.element.contains(e.target)) {
      return;
    }

    if (this.state.showModal) {
      this.closeModal();
    }
  }

  createClickOutside () {
    this.wrapper.addEventListener('click', this.clickOutside);
  }

  destroyClickOutside () {
    this.wrapper.removeEventListener('click', this.clickOutside);
  }

  componentWillUnmount () {
    this.destroyClickOutside();
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.show !== this.state.showModal) {
      this.setState({ showModal: !this.state.showModal });
    }
  }

  generateButtons () {
    let buttons = this.props.buttons || [];
    let [ accept, reject ] = buttons;

    // no buttons
    if (buttons && buttons.length === 0) {
      return [];
    }
    // default buttons
    if (!accept && !reject) {
      return this.createButton(true, "OK");
    }
    // any combo of buttons
    return [accept && this.createButton(true, accept), reject && this.createButton(false, reject)];
  }

  createButton (confirmBtn, text) {
    return <a onClick={this.dismissModal.bind(this, confirmBtn)} className={`action-button button ${confirmBtn ? 'button-primary' : 'button-outline'}`}>{text}</a>
  }

  dismissModal (confirmBtn) {
    let { confirmCallback, dismissCallback } = this.props;
    this.setState({ showModal: false });
    if (confirmBtn) {
      confirmCallback && confirmCallback();
    } else {
      dismissCallback && dismissCallback();
    }
  }

  render () {
    let self = this;
    let [ confirmButton, dismissButton ] = this.generateButtons();
    let outerRef = r => { this.wrapper = r }
    let innerRef = r => { this.element = r }

    let classes = ['generic-modal-component', this.state.showModal ? 'generic-modal-show' : ''];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div ref={outerRef} className={ classes.join(' ') }>
        <div className='row'>
          <div ref={innerRef} className='inner-modal col-12'>
            <div className='close-icon' onClick={this.closeModal}>
              <CloseLightIcon />
            </div>
            {this.props.children}
            {dismissButton}
            {confirmButton}
          </div>
        </div>
      </div>
    );
  }
}

export default GenericModal;
