import React from "react";

class CondoIcon extends React.Component {

  render () {

    let uuid = 'condo-icon';

    return (
      <svg viewBox="0 0 576 512" height={this.props.height || "25px"} fill={this.props.fill || "black"}>
        <path fill="currentColor" id={`condo-${uuid}`} d="M396.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-128-96h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm-256 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm128 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zM568 32c4.42 0 8-3.58 8-8V8c0-4.42-3.58-8-8-8H8C3.58 0 0 3.58 0 8v16c0 4.42 3.58 8 8 8h23.98v448H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h560c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-24V32h24zM320 480h-64v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80zm192 0H352v-80c0-26.47-21.53-48-48-48h-32c-26.47 0-48 21.53-48 48v80H63.98V32H512v448zM140.8 224h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm26.31 157.66l16.25 2.26c4.3.6 8.11-2.24 9.07-6.36 9.96-42.83 49.74-74.28 95.58-74.28s85.61 31.45 95.58 74.28c.96 4.12 4.77 6.96 9.07 6.36l16.25-2.26c4.6-.64 7.9-4.92 6.94-9.34C403.22 314.29 349.72 271.5 288 271.5s-115.22 42.79-127.83 100.81c-.96 4.43 2.34 8.71 6.94 9.35z"></path>
      </svg>
    );
  }
}

export default CondoIcon
