import React from "react";
import PropTypes from "prop-types";
import InterstitialValueProps from "./InterstitialValueProps";

class AdditionalQuestionInterstitial extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      timeReached: false
    };
  }

  componentDidMount() {
    this.setTimer();
  }

  setTimer() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      this.setState({timeReached: true});
      this.props.answerQuestion({"value": this.props.secondaryUserType});
      // move on after 10 seconds
      this._timer = null;
    }, 10000);
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  render () {
    const { valueProps } = this.props;
    return (
      <div id="qaas-interstitial" className="question-contents">
        <div className="interstitial-value-props">
          <h2 className="interstitial-question-heading">Thanks, we're almost done!</h2>
          <h3 className="interstitial-question-heading">We're looking at</h3>
          <InterstitialValueProps valueProps={ valueProps }/>
        </div>
        <div className="progress-bar-container">
          <div className="progress-bar">
            <div className="fill"></div>
          </div>
        </div>
        <p className="interstitial-desc">Analyzing your matches...</p>
      </div>
    );
  }
}

AdditionalQuestionInterstitial.propTypes = {
  valueProps: PropTypes.object,
};

export default AdditionalQuestionInterstitial;
