import React from "react";
import StaticMap from "../global/StaticMap";

class ConfirmLocation extends React.Component {
  constructor(props) {
    super(props);
    let firstQuestion = props.step.questions[0];
    let value;

    if (props.answers && firstQuestion && firstQuestion.value) {
      value = props.answers[firstQuestion.value];
    } else {
      value = '';
    }

    if (firstQuestion && firstQuestion.value) {
      this.state = {
        [firstQuestion.value]: value
      }
    }

    this.handleChange = this.handleChange.bind(this);
  }

  renderUnitInput () {
    let { step } = this.props;
    if (!step.questions || step.questions.length === 0) { return; }
    let firstQuestion = step.questions[0];
    let value = this.state[firstQuestion.value];

    // TODO: Refactor this to make dynamic
    if (firstQuestion.value === 'secondary_address') {
      return (
        <div className="input-group">
          <p>{ step.desc }</p>
          <label>{ firstQuestion.text }</label>
          <input placeholder="#1234" name={firstQuestion.value} value={value} onChange={this.handleChange} type="text" />
        </div>
      )
    } else {
      return;
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  restartQuiz (e) {
    e.preventDefault();
    window.location = location.pathname;
  }

  renderStaticMap () {
    let { answers } = this.props;
    let lon = answers.center_lon || (answers.geocode && answers.geocode.lng);
    let lat = answers.center_lat || (answers.geocode && answers.geocode.lat);
    let markers = [{ lon: lon, lat: lat }];

    if (markers && markers[0].lon) {
      return <StaticMap className="map" markers={markers} centerLon={lon} centerLat={lat} width="550" height="225" />
    }
  }

  render () {
    const { answers, step, transition } = this.props;
    return (
      <div className="confirm-location-component">
        <div className={`${transition ? '' : 'animate-in'}`}>
          <div className={`${transition ? 'animate-in' : ''}`}>
            {step.heading && (<h2>{step.heading}</h2>)}
            { this.renderStaticMap() }
            <div className="address">
              <div style={{display: "inline-block", maxWidth: "458px"}}>{ answers.full_address || (answers.geocode && answers.geocode.full_address) }</div>
              <a className="edit-address" href="#" onClick={this.restartQuiz}>Edit address</a>
            </div>
            { this.renderUnitInput() }
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmLocation;
