import React from 'react'
import PropTypes from 'prop-types'

const PartnerLogo = ({logoUrl, logoWidth}) => (
  <div className="partner-logo-component">
    <img
      alt="Partner Logo"
      src={logoUrl}
      width={logoWidth} />
  </div>
)

PartnerLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  logoWidth: PropTypes.string
}

PartnerLogo.defaultProps = {
  logoWidth: ''
}

export default PartnerLogo
