import React from 'react'
import {maskInput} from 'vanilla-text-mask'
import CloseLightIcon from '../icons/CloseLightIcon'
import MessageIcon from '../icons/MessageIcon'
import CheckIcon from '../icons/CheckIcon'
import LoadingSpinner from './LoadingSpinner'
import Tooltip from './Tooltip';
import tracking from '../services/tracking_service';
import { cookies } from '../services/storage_service';
import { server } from '../../utils/server';

import '../../styles/components/global/ContactModal.scss';
class ContactModal extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  componentDidMount () {
    const { id } = this.props;
    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    let phoneInput = document.getElementById(`cmc-phone-${id}`);
    this.phoneMask = maskInput({
      inputElement: phoneInput,
      mask: phoneMask,
      guide: false
    });

    this.setState({
      formNodes: {
        $fullName:   document.getElementById(`cmc-full-name-${id}`),
        $email:      document.getElementById(`cmc-email-${id}`),
        $phone:      document.getElementById(`cmc-phone-${id}`),
        $reason:     document.getElementById(`cmc-reason-dropdown-${id}`),
        $message:    document.getElementById(`cmc-message-${id}`)
      }
    });
  }

  renderConsentCopy() {
    const { leadConsentDisclaimer } = this.props;

    let disclaimerCopy = `By clicking "Submit", you agree to receive recurring text messages and/or calls from HomeLight (e.g., account news/alerts and promotional offers) to the number provided above even if it is on any Do-Not-Call list. Std. msg. rates apply. To unsubscribe from texts, reply STOP to any text message you receive from HomeLight. Unsubscribe from calls by calling 866-627-3398. Texts and calls made using automatic telephone dialing system. Consent not required as a condition of purchase. HomeLight may introduce you to eligible agents or investors as part of your request.
      <br/><br/>
      By clicking "Submit", you acknowledge you have read <a href='https://www.homelight.com/privacy-policy' target='_blank'>HomeLight’s Privacy Policy</a> and agree to the <a href='https://www.homelight.com/terms' target='_blank'>Terms of Service</a>.`
    if (leadConsentDisclaimer) {
      disclaimerCopy = leadConsentDisclaimer.replace(/{{finish_btn_text \| Create account}}/g, 'Submit');
    }

    return <span dangerouslySetInnerHTML={{ __html: disclaimerCopy }} />
  }

  trackEvent (category, action, label, value) {
    try {
      let tracker = tracking;
      tracker.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  trackConversion () {
    try {
      let tracker = tracking;
      tracker.trackConversion(true);
      this.trackEvent('Lead', 'conversion', `agent_ask_question_button`, 0);
    } catch (err) { console.error(err) }
  }

  closeModal(e) {
    this.trackEvent('Lead', 'close_ask_question_modal', `agent_ask_question_button`, 0);
    this.props.toggle(e);
  }

  validatedLead () {
    let { $fullName, $email, $phone, $reason, $message } = this.state.formNodes;
    let { topArea } = this.props;

    let lead = {
      area_slug:  topArea && topArea.slug,
      city:       topArea && topArea.name,
      full_name:  $fullName.value,
      email:      $email.value,
      phone:      $phone.value,
      reason:     $reason.value,
      message:    $message.value,
      source:     'web',
      source_page_type: 'agent_profile',
      source_form: 'profile_contact_button',
      referrer:   cookies.get('original_referrer'),
      entry_controller: cookies.get('entry_controller'),
      entry_action: cookies.get('entry_action'),
      entry_path: cookies.get('entry_path')
    };

    let validations = [
      [ $fullName,  () => /\w \w/.test(lead.full_name), 'Please provide your first and last name'],
      [ $email,     () => /.*@.*\..*/.test(lead.email), 'Please provide a valid email address'],
      [ $phone,     () => /\(\d\d\d\) \d\d\d-\d\d\d\d/.test(lead.phone), 'Please provide a valid phone number'],
      [ $reason,    () => !!lead.reason, 'Please provide a topic' ],
      // [ $message ,  () => lead.message.length > 30, 'Please provide at least 30 characters for this message' ]
    ];

    let errors = validations.filter(field => {
      let [ $node, validate, message ] = field;
      let valid = validate();
      const parent = $node.closest('.question');
      if (!valid) {
        parent.classList.add('error');
        parent.setAttribute('data-error', message);

        return true;
      } else {
        parent.classList.remove('error');
        parent.setAttribute('data-error', '');
      }
    });

    return errors.length ? false : lead;

  }

  submitLead () {

    let lead = this.validatedLead();

    if (!lead) { return; }

    this.setState({submitting: true});

    server.post('/www_lead', lead)
      .then(res => {
        server.post('/quiz_introduction', {
          lead_id:  res.id,
          agent_id: this.props.agentId,
          message: lead.message
        })
      })
      .then(res => {
        this.trackConversion();
        this.setState({submitting: false});
        this.setState({submitted: true});
        this.props.setSubmitted && this.props.setSubmitted();
      })
      .catch(err => {
        this.setState({submitting: false});
        this.setState({submitted: true, submittedWithErrors: true});
        this.props.setSubmitted && this.props.setSubmitted();
      });
  }

  render() {
    const { id } = this.props;
    const tcpaTooltip = "Partners including SoFi.<br/><br/>Note, partner participation does not constitute an endorsement.";

    return (
      <div className={`contact-modal-component ${this.props.show ? 'active' : ''}`}>
        <a onClick={this.closeModal.bind(this)}>
          <div className="modal-background">
            <CloseLightIcon/>
          </div>
        </a>
        <div className="modal-box">
          <div className="screen active">
            <div className="row">
              <div className="sidebar col-xs-12 col-md-5 col-lg-5">
                <div className="sidebar-content">
                  {
                    this.state.submitted ? (
                      <div className="submitted-screen">
                        <div className="graphic-wrap">
                          <MessageIcon/>
                          <CheckIcon/>
                        </div>
                      </div>
                      ) : (
                      <div>
                        <h3>Contact {this.props.agentName}</h3>
                        <p className="desc">A HomeLight concierge will reach out on {this.props.agentName}'s behalf within 3 mins during business hours.</p>
                        <p className="desc">Here's what you can expect:</p>
                        <ul className="next-steps">
                          <li className="step">
                            <div className="number">1.</div>
                            <p className="desc">HomeLight will reach out to confirm your details and answer any questions you may have.</p>
                          </li>
                          <li className="step">
                            <div className="number">2.</div>
                            <p className="desc"> We'll do our best to connect you with {this.props.agentName} and if you'd like, introduce you to other top performing agents in the area. </p>
                          </li>
                          <li className="step">
                            <div className="number">3.</div>
                            <p className="desc">You hire the perfect real estate agent!</p>
                          </li>
                        </ul>
                        <div className="service-info">
                          <div className='immediate-service'>Need immediate service?</div>
                          <div className="desc">Call us between 9am - 5pm (PST)</div>
                          <p className="phone-number">
                            <a href={`tel:${this.props.phoneNumber}`}>{this.props.formattedNumber}</a>
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              {
                this.state.submitted && (
                  <div className="form col-xs-12 col-md-7 col-lg-7">
                    <div className="form-content">
                      <h3>Message Sent</h3>
                      <p>A HomeLight Advisor will reach out to confirm your details and connect you with {this.props.agentName}.  Upon your request, we will also connect you with up to 2 additional top agents that meet your criteria.</p>
                      <p>We use historical and public real estate transaction data to identify the top performing real estate agents in your area.</p>
                      <br></br>
                      <a className="button button-outline" onClick={this.closeModal.bind(this)}>Close</a>
                    </div>
                  </div>
                ) || (
                  <div className="form col-xs-12 col-md-7 col-lg-7">
                    <div className="form-content">
                      <div className="question">
                        <label>Full Name*</label>
                        <input placeholder="Full Name" length="255" type="text" id={`cmc-full-name-${id}`} />
                      </div>

                      <div className="question">
                        <label>Email*</label>
                        <input placeholder="Email Address" className="email-input" length="255" type="email" id={`cmc-email-${id}`} />
                      </div>

                      <div className="question">
                        <label>Phone*</label>
                        <input placeholder="Phone Number" className="phone-input" length="15" type="tel" id={`cmc-phone-${id}`} />
                      </div>

                      <div className="dropdown question">
                        <label>Question Topic*</label>
                        <select className="required dropdown-menu" id={`cmc-reason-dropdown-${id}`}>
                          <option value="">Select</option>
                          <option value="interested_in_agents_listing">I have a question about {this.props.agentName}'s listings.</option>
                          <option value="interested_in_agent_representing_me">I have a question as a Buyer</option>
                          <option value="interested_in_selling">I have a question as a Seller</option>
                          <option value="interested_in_contacting">I have a personal question for {this.props.agentName}</option>
                          <option value="none">Other</option>
                        </select>
                      </div>

                      <div className="question">
                        <label>Message</label>
                        <textarea placeholder={`Hi ${this.props.agentName}, I saw your profile on HomeLight and I'd like to schedule a time to talk.`} id={`cmc-message-${id}`}></textarea>
                      </div>
                      <div className="actions">
                        {
                          this.state.submitting && <LoadingSpinner/> || (
                            <div>
                              <a className="button" onClick={() => this.submitLead() }>Submit</a>
                              <a className="button button-outline" onClick={this.closeModal.bind(this)}>Cancel</a>
                            </div>
                          )
                        }
                        <div className="tcpa-disclosure-content" id="tcpa-disclosure-content">
                          {this.renderConsentCopy()}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactModal
