
function normalize(result) {
  const getValue = (ctx, type, returnObject = false) => {
    let [ val ] = ctx.filter( x => x.id.indexOf(type) > -1 );
    return (returnObject && val) || (val && val.text);
  };

  const normalizeSlug = (text) => {
    return text === undefined ? undefined : text.toLowerCase().split(' ').join('-');
  };

  const normalizeSlugFromArray = (arrayOfStrings) => {
    return arrayOfStrings.map(function(text) {
      return normalizeSlug(text);
    }).join('-');
  };

  let { context, geometry, place_name, address, text, place_type, id } = result;
  let isAddress    = place_type && place_type[0] === 'address';
  let isCity       = place_type && place_type[0] === 'place';
  let isZip        = place_type && place_type[0] === 'postcode';
  let isNeighborhood = place_type && place_type[0] === 'neighborhood';
  let state        = getValue(context, 'region.', true);
  let county       = getValue(context, 'district.');
  let city         = getValue(context, 'place.');
  let postal_code  = isZip ? text : getValue(context, 'postcode.');
  let lat          = geometry && geometry.coordinates && geometry.coordinates[1];
  let lng          = lat && geometry.coordinates[0];
  let neighborhood = place_name.replace(', United States', '');
  let neighborhoodCity;
  let full_address = place_name && place_name.replace(', United States', '');
  let types        = place_type;
  let description;
  let street_address;
  let slug;

  state = state && [ state.short_code && state.short_code.replace('US-', ''),  state.text].filter(x => x)[0]
  if (isCity) {
    city = text;
    description = `${city}, ${state}`;
    slug = normalizeSlugFromArray([ city, state ]);
  } else if (isAddress) {
    street_address = address && text && `${address} ${text}`;
    description = full_address;
    slug = normalizeSlugFromArray([ city, state ]);
  } else if (isZip) {
    description = postal_code;
    slug = postal_code;
  } else if (isNeighborhood) {
    neighborhoodCity = getValue(context, 'place.', true);
    description = neighborhood;
    // Needs a unique normalization method that finalizes to 'city-slug/neighborhood-slug'
    slug = normalizeSlug(neighborhoodCity.text + '-' + state) + '/' + normalizeSlug(text);
  }

  return {
    id,
    slug,
    city,
    state,
    types,
    postal_code,
    county,
    street_address,
    full_address,
    formatted_address: full_address,
    description,
    lat,
    lng
  };
}

/**

  types  (optional)

  Filter results to include only a subset (one or more) of the available feature types.
  Options are  country ,  region ,  postcode ,  district ,  place ,  locality , neighborhood ,  address , and  poi .
  Multiple options can be comma-separated.
  Note that  poi.landmark is a deprecated type that, while still supported, returns the same data as is returned using the poi type.

**/

const BASE_URI        = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const STYLES_URI      = 'https://api.mapbox.com/styles/';
const ACCESS_TOKEN    = 'pk.eyJ1IjoiaG9tZWxpZ2h0IiwiYSI6ImNqNmZka24yNDJtd3QzMm8xYm1hbWFhcnIifQ.BT1EY0MP0qndOTsWsvv8FA';

function geoSearch(term, props = {}) {
  const BIAS            = document.querySelector('meta[name=location-biasing]').getAttribute('content');

  props.bias  = props.bias || BIAS

  const query = encodeURIComponent(term);
  const proximity = props.bias && `&proximity=${encodeURIComponent(props.bias)}`;
  const uri = [
    BASE_URI,
    query,
    ".json?autocomplete=true&access_token=",
    ACCESS_TOKEN,
    "&country=",  encodeURIComponent(props.country || "us"),
    "&types=",    props.types || "address",
    proximity || ""
  ].join('');

  return fetch(uri, { headers: {'Content-Type': 'application/json'} })
           .then(response => response.json())
           .then(results => results && results.features.map(normalize))
};

export { geoSearch, BASE_URI, STYLES_URI, ACCESS_TOKEN };
