import React from "react";

import InterstitialValueProps from "./InterstitialValueProps";

const Interstitial = ({valueProps}) => (
  <div id="qaas-interstitial" className="question-contents">
    <h2>We’re finding the best real estate agents for you!</h2>
    <div className="interstitial-value-props">
      <InterstitialValueProps valueProps={ valueProps }/>
    </div>
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="fill"></div>
      </div>
    </div>
    <p className="interstitial-desc">Analyzing your matches...</p>
  </div>
)

export default Interstitial;
