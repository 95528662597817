import React from 'react';
import PropTypes from 'prop-types';
import tracking from '../services/tracking_service';

class CtaContactModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      submitted: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount () {
    this.tracker = tracking;
  }

  toggleModal(e) {
    this.setState(prevState => ({
      showModal: !this.state.showModal
    }));
    if (!this.state.showModal) {
      this.tracker.trackEvent('Agent', 'Submit', 'ContactAgentProfilePage');
      this.ContactModal ||= require('./ContactModal').default
    }
  }

  setSubmitted () {
    this.tracker.trackEvent('Agent', 'Submit', 'AgentContact');
    this.setState({submitted: true});
  }

  handleClick() {
    const {submitted} = this.state;
    const {onClick} = this.props;

    if (!submitted) this.toggleModal();
    if (onClick) onClick();
  }

  render() {
    const {outlineStyle, leadConsentDisclaimer} = this.props;
    const {ContactModal} = this;
    return (
      <div className="contact-modal-wrapper">
        <a className={`contact-cta button ${outlineStyle ? "button-outline" : ""}`} onClick={() => !this.state.submitted && this.toggleModal()}>
          { (this.state.submitted && this.props.submittedText) || this.props.text}
        </a>
        {
          ContactModal && (
            <ContactModal
              topArea={this.props.topArea}
              setSubmitted={() => this.setSubmitted()}
              show={this.state.showModal}
              toggle={this.toggleModal.bind(this)}
              agentName={this.props.agentName}
              agentId={this.props.agentId}
              id={this.props.agentId}
              phoneNumber={this.props.phoneNumber}
              formattedNumber={this.props.formattedNumber}
              leadConsentDisclaimer={leadConsentDisclaimer}
            />
          )
        }
      </div>
    );
  }
}

CtaContactModal.propTypes = {
  outlineStyle: PropTypes.bool
}

CtaContactModal.defaultProps = {
  outlineStyle: true
}

export default CtaContactModal
