import React from "react";

class AgentProfileQuizTransactionQuestion extends React.Component {
  render () {
    return (
      <div>
        <h3>Is {this.props.agentFirstName} the right agent for you?</h3>
        <p className="sub-heading">
          Answer a few questions to see how well {this.props.agentPossessiveFirstName} strengths match your needs
        </p>
        <div className="question buy-sell">
          <h5 className="question-text">Are you looking to Buy or Sell?</h5>
          <div className="container">
            <div className="row row--double-col">
              <div className="col-xs-6">
                <a className="button" onClick={ () => this.props.answerQuestion('user_type', 'buyer') }>Buy</a>
              </div>
              <div className="col-xs-6">
                <a className="button" onClick={ () => this.props.answerQuestion('user_type', 'seller') }>Sell</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AgentProfileQuizTransactionQuestion;
