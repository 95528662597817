import React from 'react'
import PropTypes from 'prop-types'
import tracking from '../services/tracking_service';

import '../../styles/components/global/HeaderMenu.scss'

class HeaderMenu extends React.Component {
  constructor (props) {
    super(props);

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.signOut = this.signOut.bind(this);
    this.signOutClient = this.signOutClient.bind(this);
  }

  componentDidMount () {
    this.trackEvent = tracking.trackEvent;
  }

  getEnv() {
    if (/sandbox|demo|staging|ops/.test(window.location.hostname)) {
      return "staging";
    } else if (/local|dev/.test(window.location.hostname)) {
      return "development";
    } else {
      return "production"
    }
  }

  signedInAsClient () {
    const { signedIn, userType } = this.props;
    return signedIn && (userType === 'client' || userType === 'client-lead');
  }

  signOutClient () {
    const rootFromEnv = {
      development: 'http://localhost:3001',
      staging: 'https://staging.hapi.homelight.com',
      production: 'https://hapi.homelight.com'
    }
    const env = this.getEnv();
    const logoutUrl = `${rootFromEnv[env]}/api/user-auth-service/user-login/logout`;

    fetch(logoutUrl, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include'
    }).then( () => { window.location.reload(true) });
  }

  toggleMobileMenu (e) {
    e.preventDefault();

    const parent = document.querySelector('.page-header-component');
    const menuOpen = parent.classList.contains('mobile-menu-open');
    if (menuOpen) {
      // add explicit closed class for animation
      parent.classList.add('mobile-menu-closed');
    } else {
      parent.classList.remove('mobile-menu-closed');
    }
    parent.classList.toggle('mobile-menu-open');
    document.body.classList.toggle('fixed');
  }

  renderProMenu () {
    const { favoritesFeatureFlag } = this.props;

    return (
      <ul className='nav-menu client-signed-in'>
        <li className='top-link about-links'>
          <a className='anchor-link anchor-link about-us' href='#' rel='nofollow'>About Us</a>
          <hr className='divider visible-xs'/>
          {this.renderDesktopSubMenu()}
        </li>
        <li className="top-link for-agents-link">
          <a className='anchor-link anchor-link' href='/agents'>For Agents</a>
        </li>
        <li className='top-link user-links'>
          <a className='anchor-link user-icon'>
            {this.renderUserIcon()}
          </a>
          <div className='sub-menu'>
            {this.getProLinks()}
            { favoritesFeatureFlag && this.getUserNavOptions() }
            <a className='anchor-link auth-link sign-out-link desktop-sign-out' onClick={this.signOut}>Sign Out</a>
          </div>
        </li>
        <li className='top-link sign-out-links'>
          <a className='anchor-link sign-out-link auth-link mobile' onClick={this.signOut}>Sign Out</a>
        </li>
      </ul>
    );
  }

  signOut () {
    const {signOutPath} = this.props;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(signOutPath, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({authenticity_token: token})
    }).then(() => {
      if (this.props.signedIn) {
        this.signOutClient();
      } else {
        window.location.href = '/';
      }
    }).catch(err => {
      console.error(err);
      if (typeof window.Sentry !== 'undefined') {
        window.Sentry.captureException('Failure to sign out:', JSON.stringify(err));
      }
    });
  }

  getProLinks () {
    const {
      userType,
      profilePath,
      preferencesPath,
      agreementLinkText,
      agreementPath,
      closingDocPath,
      helpPath,
      referralsPath,
      referralCount,
      clientResourceCenterPath,
      passwordPath,
      enableNewAgentProfile,
      agentDashboardUrl
    } = this.props;
    const capsUserType = `${userType.charAt(0).toUpperCase()}${userType.slice(1)}`;
    const helpText = `${capsUserType} Help Center`;

    let referralsBadge;
    if (userType === 'agent' && referralCount > 0) {
      referralsBadge = <span className='badge badge-important'><span>{referralCount}</span></span>;
    }

    if (userType === 'agent' || userType === 'investor') {
      return enableNewAgentProfile ? (
        <React.Fragment>
          <a href={agentDashboardUrl} className="anchor-link" target="_blank">
            Go to the Agent App
          </a>
          <hr className="divider" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {profilePath && (
            <a href={profilePath} className="anchor-link" target="_blank">
              Go to profile
            </a>
          )}
          {preferencesPath && (
            <a href={preferencesPath} className="anchor-link" target="_blank">
              Preferences
            </a>
          )}
          {referralsPath && (
            <a className='anchor-link' href={referralsPath}>
              Referrals&nbsp;
              {referralsBadge}
            </a>
          )}
          {agreementPath && agreementLinkText && (
            <a href={agreementPath} className="anchor-link" target="_blank">
              {agreementLinkText}
            </a>
          )}
          {closingDocPath && (
            <a href={closingDocPath} className="anchor-link" target="_blank">
              Closing Documents
            </a>
          )}
          {helpPath && helpText && (
            <a href={helpPath} className="anchor-link" target="_blank">
              {helpText}
            </a>
          )}
          {clientResourceCenterPath && (
            <a href={clientResourceCenterPath} className="anchor-link" target="_blank">
              Resources and Marketing
            </a>
          )}
          <a href={passwordPath} className='anchor-link'>Change Password</a>
          <hr className="divider"/>
        </React.Fragment>
      );
    }

    return null;
  }

  getUserNavOptions () {
    const { clientResultsPath, myHomeFeatureFlag, notificationSettingsFeatureFlag, userType } = this.props;
    let userNavOptions = [
      {
        label: 'Home Search',
        link: `/homes`,
      },
      {
        label: 'Favorite Homes',
        link: `/homes/favorites`,
      },
      {
        label: 'Recently Viewed',
        link: `/homes/recently-viewed`,
      },
      {
        label: 'Saved Searches',
        link: '/homes/saved-property-searches',
      },
      {
        label: 'Manage Subscription',
        link: '/ultra-low-rates/dashboard',
      },
    ];
    if (userType === 'client-lead') {
      userNavOptions.unshift({
        label: "My Dashboard",
        link: clientResultsPath
      })
    }

    if (myHomeFeatureFlag) {
      userNavOptions.push({
        label: "My Home",
        link: '#'
      })
    }

    if (notificationSettingsFeatureFlag) {
      userNavOptions.push({
        label: "Notification Settings",
        link: '/homes/notification-settings'
      })
    }

    return (
      <React.Fragment>
        {userNavOptions.map((option) => (
          <a className='anchor-link' key={option.label} href={option.link}>{option.label}</a>
        ))}
        <hr className='divider'/>
      </React.Fragment>
    );
  }

  renderSignInMenu () {
    const { signInPath } = this.props;

    return (
      <ul className='nav-menu sign-in'>
        <li className="top-link about-us-links">
          <a className="anchor-link about-us" href="#" rel="nofollow">
            About Us
          </a>
          <div className="sub-menu">
            <a className="anchor-link" href="/about-us">
              Company
            </a>
            <a className="anchor-link" href="/testimonials">
              Testimonials
            </a>
            <a className="anchor-link" href="/press">
              Press
            </a>
            <a className="anchor-link" href="/careers">
              Careers
            </a>
          </div>
        </li>
        <li className="top-link for-agents-link">
          <a className='anchor-link' href='/agents'>For Agents</a>
          <hr className='divider visible-xs' />
        </li>
        <li className='top-link sign-in-link'>
          <a className='anchor-link' href={signInPath}>
            <span className='link-blue'>Sign In</span>
          </a>
          <hr className='divider visible-xs' />
        </li>
      </ul>
    );
  }

  renderClientMenu () {
    const { favoritesFeatureFlag } = this.props;

    return (
      <ul className='nav-menu client-signed-in'>
        <li className='top-link about-links'>
          <a className='anchor-link anchor-link about-us' href='#' rel='nofollow'>About Us</a>
          <hr className='divider visible-xs'/>
          {this.renderDesktopSubMenu()}
        </li>
        <li className="top-link for-agents-link">
          <a className='anchor-link anchor-link' href='/agents'>For Agents</a>
        </li>
        <li className='top-link client-menu'>
          {this.renderUserShortcut('client')}
          <div className='sub-menu'>
            { favoritesFeatureFlag && this.getUserNavOptions() }
            <a className='anchor-link auth-link sign-out-link hidden-xs' onClick={this.signOutClient}>Sign Out</a>
          </div>
        </li>
      </ul>
    );
  }

  renderUserShortcut(userType) {
    const { clientResultsPath } = this.props;
    return (
      <a
        className="anchor-link menu-header client-menu-header user-icon"
        href={userType === "client" ? clientResultsPath : undefined}
      >
        {this.renderUserIcon()}
      </a>
    );
  }

  renderUserIcon() {
    const { userName } = this.props;
    const [firstLetter, ...restOfName] = userName;

    return (
      <React.Fragment>
        <div className="profile-wrapper">
          <div className="icon-wrapper">
            {userName !== "" ? (
              <div className="letter">{firstLetter.toUpperCase()}</div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="user-icon"
                viewBox="0 0 448 512"
                width="14"
                height="16"
              >
                <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
              </svg>
            )}
          </div>
          {userName && (
            <div className="user-name">{`${firstLetter.toUpperCase()}${restOfName.join(
              ""
            )}`}</div>
          )}
          <svg className="caret-down" width="10" height="6" viewBox="0 0 10 7">
            <path
              d="M4.73442 6.34674L0.110139 1.75996C-0.036713 1.61311 -0.036713 1.37564 0.110139 1.22879L0.728792 0.610139C0.875644 0.463287 1.11311 0.463287 1.25996 0.610139L5 4.31269L8.74004 0.610139C8.88689 0.463287 9.12436 0.463287 9.27121 0.610139L9.88986 1.22879C10.0367 1.37564 10.0367 1.61311 9.88986 1.75996L5.26558 6.34674C5.11873 6.49359 4.88127 6.49359 4.73442 6.34674Z"
            />
          </svg>
        </div>
        <hr className="divider" />
      </React.Fragment>
    );
  }

  renderDesktopSubMenu () {
    return (
      <div className='sub-menu'>
        <a className='anchor-link' href='/about-us'>Company</a>
        <a className='anchor-link' href='/testimonials'>Testimonials</a>
        <a className='anchor-link' href='/press'>Press</a>
        <a className='anchor-link' href='/careers'>Careers</a>
        <hr className='divider' />
        {this.signedInAsClient() && (
          <a className='anchor-link auth-link sign-out-link' onClick={this.signOutClient}>Sign Out</a>
        )}
      </div>
    );
  }

  getUserSpecificMenu () {
    const { signedIn } = this.props;
    if (signedIn) {
      return this.signedInAsClient() ? this.renderClientMenu() : this.renderProMenu();
    }
    return this.renderSignInMenu();
  }

  render () {
    const {
      lightNav,
      signedIn,
      userType
    } = this.props;

    return (
      <div className={`header-menu-component ${lightNav ? 'light-nav' : ''}`}>
        {signedIn && (
          <div className='client-menu client-menu-mobile'>
            {this.renderUserShortcut(userType)}
          </div>
        )}
        {this.getUserSpecificMenu()}
        <a className='hamburger-icon-wrap' onClick={this.toggleMobileMenu}>
          <span className='hamburger-icon-line' />
          <span className='hamburger-icon-line' />
          <span className='hamburger-icon-line' />
        </a>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  lightNav: PropTypes.bool,
  signedIn: PropTypes.bool,
  userName: PropTypes.string,
  userType: PropTypes.string,
  referralCount: PropTypes.number,
  referralsPath: PropTypes.string,
  passwordPath: PropTypes.string,
  clientResultsPath: PropTypes.string,
  signInPath: PropTypes.string.isRequired,
  signOutPath: PropTypes.string,
  photoPath: PropTypes.string,
  profilePath: PropTypes.string,
  preferencesPath: PropTypes.string,
  agreementLinkText: PropTypes.string,
  agreementPath: PropTypes.string,
  closingDocPath: PropTypes.string,
  helpPath: PropTypes.string,
  favoritesFeatureFlag: PropTypes.bool,
  notificationSettingsFeatureFlag: PropTypes.bool,
  myHomeFeatureFlag: PropTypes.bool,
  clientResourceCenterPath: PropTypes.string,
  agentDashboardUrl: PropTypes.string,
  enableNewAgentProfile: PropTypes.bool
};

HeaderMenu.defaultProps = {
  lightNav: true,
  signedIn: false,
  userName: '',
  userType: '',
  referralCount: 0,
  referralsPath: '',
  passwordPath: '',
  clientResultsPath: '/client-portal',
  signOutPath: '',
  photoPath: '',
  profilePath: '',
  preferencesPath: '',
  agreementLinkText: '',
  agreementPath: '',
  closingDocPath: '',
  helpPath: '',
  favoritesFeatureFlag: false,
  notificationSettingsFeatureFlag: false,
  myHomeFeatureFlag: false,
  clientResourceCenterPath: '',
  agentDashboardUrl: '',
  enableNewAgentProfile: false
};

export default HeaderMenu
