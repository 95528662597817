import React from "react";
import CheckCircleBricon from "../icons/CheckCircleBricon";

class InterstitialValueProps extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      numToDisplay: 1
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ numToDisplay: this.state.numToDisplay + 1}), 1500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    return this.props.valueProps.map((valuePropObj, i) => {
      let title = valuePropObj["title"];
      let description = valuePropObj["description"];
      // create a unique key for each value prop based on its title; default to its
      // index value if not present
      let key = title ? title.toLowerCase().replace(' ', '-') : i
      let active = (this.state.numToDisplay - 1) >= i;
      let visible = active ? "visible" : "hidden";

      return (
        <div key={ key } className={`value-prop ${visible}`}>
          <div className="value-prop-heading">
            <div className="icon-container"><CheckCircleBricon /></div>
            <h3 className="interstitial-question">{ title }</h3>
          </div>
          <p className="interstitial-question">{ description }</p>
        </div>
      );
    })
  }
}

export default InterstitialValueProps;
