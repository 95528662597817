import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../global/LoadingSpinner";
import AgentRestrictedTransactions from "./AgentRestrictedTransactions";
import AgentAreaTransactions from "./AgentAreaTransactions";
import AgentAllTransactions from "./AgentAllTransactions";

class AgentProfileTransactionsWrapper extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      transactions: this.props.transactions ? this.props.transactions : [],
      loading: !this.props.transactions,
      restricted: false,
      onClient: false
    }
    this.processTransactions.bind(this);
  }

  componentWillMount() {
    this.processTransactions(this.state.transactions);
  }

  loadMapbox() {
    const scriptLoader = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/script-loader') : modules.import('modules/script-loader');
    scriptLoader.loadScripts('mapbox_gl_1_9_1', 'google_places');
  }

  componentDidMount () {
    this.loadMapbox();

    let missingAgentHighLights = !document.querySelectorAll('.agent-highlights').length;

    if (pageYOffset > 100 || missingAgentHighLights) {
      this.getTransactions();
    } else {
      window.addEventListener('scroll', e => { this.getTransactions() }, { once: true });
    }

    this.setState({ onClient: true })
  }

  componentDidUpdate () {
    PubSub.emit('page-height-changed');
  }

  getTransactions () {
    let url = `/agent/profile/${this.props.id}/recent-transactions/?preview=preview`;

    if (/(\?|&)intro/.test(location.search)) {
      url += '?intro=t';
    }

    server.get(url).then(transactions => {
      this.processTransactions(transactions);
    }).catch( err => this.setState({ loading: false }));
  }

  processTransactions(transactions) {
    let areaTransactions;
    if (transactions && !transactions.restricted) {
      areaTransactions = transactions.filter(tx => tx.city && tx.lat && tx.lng);
      this.setState({transactions, areaTransactions, loading: false});
    } else {
      this.setState({loading: false, restricted: !!transactions.restricted });
      PubSub.emit('page-height-changed');
    }
  }

  renderHiddenState () {
    if (this.state.loading) {
      return <section id="agent-transactions" className="section agent-transactions"><LoadingSpinner text="Loading Transactions"/></section>
    } else if (this.state.restricted) {
      return <AgentRestrictedTransactions/>
    }
    return <div></div>
  }

  render () {
    let hide = !this.state.transactions.length || this.state.loading;
    const { onClient } = this.state;


    return (hide && this.renderHiddenState()) || (
      <section id="agent-transactions" className="section agent-transactions">
        <h2>{this.props.agentName}'s Transactions</h2>
        {onClient && !!this.state.areaTransactions.length && <AgentAreaTransactions transactions={this.state.transactions}/>}
        <AgentAllTransactions agentName={this.props.agentName} all={this.state.transactions}/>
      </section>
    )
  }
}

AgentProfileTransactionsWrapper.propTypes = {
  agentName:  PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.object)
};

export default AgentProfileTransactionsWrapper;
