import React from "react";

class UserTieIcon extends React.Component {
  render () {
    return (
      <svg className="user-tie-icon" viewBox="0 0 49 56">
        <path d="M24.0624426,28.3750492 C21.5559387,28.3750492 19.2463739,27.7663261 17.1337482,26.5488817 C15.0211225,25.3314372 13.3560874,23.6664021 12.1386429,21.5537764 C10.9211985,19.4411507 10.3124754,17.1315859 10.3124754,14.625082 C10.3124754,12.1185781 10.9211985,9.80901326 12.1386429,7.69638754 C13.3560874,5.58376182 15.0211225,3.91872673 17.1337482,2.70128226 C19.2463739,1.48383779 21.5559387,0.875114739 24.0624426,0.875114739 C26.5689465,0.875114739 28.8785113,1.48383779 30.991137,2.70128226 C33.1037628,3.91872673 34.7687979,5.58376182 35.9862423,7.69638754 C37.2036868,9.80901326 37.8124098,12.1185781 37.8124098,14.625082 C37.8124098,17.1315859 37.2036868,19.4411507 35.9862423,21.5537764 C34.7687979,23.6664021 33.1037628,25.3314372 30.991137,26.5488817 C28.8785113,27.7663261 26.5689465,28.3750492 24.0624426,28.3750492 Z M34.374918,31.9199626 C36.8814219,31.9915776 39.1909867,32.6898181 41.3036125,34.0146842 C43.4162382,35.3395503 45.0812733,37.0762003 46.2987177,39.2246327 C47.5161622,41.3730651 48.1248853,43.700534 48.1248853,46.2070379 L48.1248853,50.7187459 C48.1248853,52.1510336 47.6235838,53.3684797 46.6209826,54.3710809 C45.6183814,55.3736822 44.4009353,55.8749836 42.9686476,55.8749836 L5.15623771,55.8749836 C3.72395,55.8749836 2.50650389,55.3736822 1.50390266,54.3710809 C0.501301435,53.3684797 0,52.1510336 0,50.7187459 L0,46.2070379 C0,43.700534 0.608723053,41.3730651 1.82616752,39.2246327 C3.04361199,37.0762003 4.70864708,35.3395503 6.8212728,34.0146842 C8.93389852,32.6898181 11.2434633,31.9915776 13.7499672,31.9199626 L18.9062049,52.4374918 L22.3436967,37.8281516 L18.9062049,31.812541 L29.2186803,31.812541 L25.7811885,37.8281516 L29.2186803,52.4374918 L34.374918,31.9199626 Z"></path>
      </svg>
    );
  }
}

export default UserTieIcon
