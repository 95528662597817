import React from "react";
import ArrowRightIcon from '../icons/ArrowRightIcon'
import ChevronIcon from '../icons/ChevronIcon'
import PhoneIcon from '../icons/PhoneIcon'
import SearchIcon from '../icons/SearchIcon'
import BbbIcon from '../icons/BbbIcon'
import CloseLightIcon from '../icons/CloseLightIcon'
import HomeIcon from '../icons/HomeIcon'
import CalendarOutlineIcon from '../icons/CalendarOutlineIcon'
import FileDollarOutlineIcon from '../icons/FileDollarOutlineIcon'
import CommentsOutlineIcon from '../icons/CommentsOutlineIcon'
import CheckIcon from '../icons/CheckIcon'
import UserTieIcon from '../icons/UserTieIcon'
import MoneyBillIcon from '../icons/MoneyBillIcon'
import SaleSignIcon from '../icons/SaleSignIcon'
import CheckCircleIcon from '../icons/CheckCircleIcon'
import LoaderIcon from '../icons/LoaderIcon'
import StarRatingIcon from '../icons/StarRatingIcon'
import MarkerIcon from '../icons/MarkerIcon'
import MobileHomeIcon from '../icons/MobileHomeIcon'
import CondoIcon from '../icons/CondoIcon'
import TicIcon from '../icons/TicIcon'
import EllipsisIcon from '../icons/EllipsisIcon'
import ExpandIcon from '../icons/ExpandIcon'

const QuizIcons = {
  'ArrowRightIcon': <ArrowRightIcon />,
  'ChevronIcon': <ChevronIcon />,
  'PhoneIcon': <PhoneIcon />,
  'SearchIcon': <SearchIcon />,
  'BbbIcon': <BbbIcon />,
  'CloseLightIcon': <CloseLightIcon />,
  'HomeIcon': <HomeIcon />,
  'CalendarOutlineIcon': <CalendarOutlineIcon />,
  'FileDollarOutlineIcon': <FileDollarOutlineIcon />,
  'CommentsOutlineIcon': <CommentsOutlineIcon />,
  'CheckIcon': <CheckIcon />,
  'UserTieIcon': <UserTieIcon />,
  'MoneyBillIcon': <MoneyBillIcon />,
  'SaleSignIcon': <SaleSignIcon />,
  'CheckCircleIcon': <CheckCircleIcon />,
  'LoaderIcon': <LoaderIcon />,
  'StarRatingIcon': <StarRatingIcon />,
  'MarkerIcon': <MarkerIcon />,
  'MobileHomeIcon': <MobileHomeIcon />,
  'CondoIcon': <CondoIcon />,
  'TicIcon': <TicIcon />,
  'EllipsisIcon': <EllipsisIcon />,
  'ExpandIcon': <ExpandIcon />
}

export default QuizIcons
