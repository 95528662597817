import React from "react";
import PropTypes from "prop-types";
import '../../styles/components/profiles/ProfileServiceAreas.scss';

const ProfileServiceAreas = ({areas, addClass, variation, firstName}) => {
  const titleMapping = {
    'homeSearch': `Browse homes for sale in markets that ${firstName} serves`,
    'topAgents': `Find top agents in markets that ${firstName} serves`
  }

  return (
  <React.Fragment>
    <label>{titleMapping[variation]}</label>
    <ul className={`profile-service-areas-component ${addClass || ""}`}>
      {
        JSON.parse(areas).map( (area, i) => {
          return (
            <li className="area" key={`service-areas-${i}`}>
              <a className="link" href={area.url} target="blank">{area.name}</a>
            </li>
          )
        })
      }
    </ul>
  </React.Fragment>
  )
};

ProfileServiceAreas.propTypes = {
  areas: PropTypes.string,
  addClass: PropTypes.string,
  variation: PropTypes.string,
  firstName: PropTypes.string
};

export default ProfileServiceAreas;
