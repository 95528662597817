import React from "react";
import PropTypes from "prop-types";
import FormattedPhoneNumber from "../global/FormattedPhoneNumber";

class SignupSignin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fullName: this.props.defaultAnswers && this.props.defaultAnswers.full_name || '',
      email: this.props.defaultAnswers && this.props.defaultAnswers.email || '',
      phone: this.props.defaultAnswers && this.props.defaultAnswers.phone || '',
      tab: "sign_up",
      phoneNumberInvalid: false,
      getPhone: false
    };

    this.chooseTab = this.chooseTab.bind(this);
    this.fillGoogleFields = this.fillGoogleFields.bind(this);
    this.phoneNumberSet = this.phoneNumberSet.bind(this);
    this.phoneNumberUnset = this.phoneNumberUnset.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  handleFormChange(key, value) {
    this.setState(() => ({[key]: value}));
  }

  signUp(e) {
    this.props.onSignupComplete && this.props.onSignupComplete(e);
  }

  chooseTab(e) {
    const element = e.target;
    const chosenTab = element.getAttribute('data-tab-name');
    this.setState({tab: chosenTab});
  }

  fillGoogleFields (userProfile) {
    const name = userProfile.getName();
    const email = userProfile.getEmail();

    this.setState({fullName: name, email: email, getPhone: true});
  }

  componentDidMount() {
    this.authenticity_token = document.querySelector('[name=csrf-token]').content;
  }

  phoneNumberSet(formattedPhoneNumber) {
    this.setState({phone: formattedPhoneNumber, phoneNumberInvalid: false });
  }

  phoneNumberUnset() {
    this.setState({phone: "" });
  }

  render () {
    const { fullName, email, phone } = this.state;

    return (
      <div className='signup-signin'>
        <div className="ss-tabs">
          <div className={`ss-tab ${this.state.tab === "sign_up" ? "active" : ""}`}
               data-tab-name="sign_up"
               onClick={this.chooseTab}>
            Sign up
          </div>
          <div className={`ss-tab ${this.state.tab === "sign_in" ? "active" : ""} ${this.props.disableSignIn ? "disabled":""}`}
               data-tab-name="sign_in"
               onClick={this.props.disableSignIn ? null : this.chooseTab}>
            Sign in
          </div>
        </div>

        { this.state.getPhone ? (
          <div className="tab-area">
            <h2>What is the best way to contact you?</h2>
            <div className="row">
              <label>Phone number</label>
            </div>

            <div className="row">
              <FormattedPhoneNumber fieldName="phone"
                                    value={phone}
                                    invalid={this.state.phoneNumberInvalid}
                                    errorMsg="The phone number appears to be invalid."
                                    onValid={this.phoneNumberSet}
                                    onInvalid={this.phoneNumberUnset}/>
            </div>

            <div className="actions">
              <a className="button" onClick={this.signUp}>Sign up</a>
            </div>
          </div>
          ) : null }
        {/* If we get full name and email from google signin we set it here and then hide this page and only show the
        above screen to get phone*/}
        { this.state.tab === "sign_up" ? (<div className="tab-area" style={{display: this.state.getPhone ? "none" : ""}}>
          <h2>Sign up to continue</h2>
          <div className="row">
            <label>Name</label>
          </div>

          <div className="row">
            <input type="text" name="full_name" value={fullName} onChange={e => this.handleFormChange('fullName', e.target.value)} placeholder="Your name"/>
          </div>

          <div className="row">
            <label>Email</label>
          </div>

          <div className="row">
            <input type="text" name="email" value={email} onChange={e => this.handleFormChange('email', e.target.value)} placeholder="jane@homelight.com"/>
          </div>

          <div className="row">
            <label>Phone</label>
          </div>

          <div className="row">
            <FormattedPhoneNumber fieldName="phone"
                                  value={phone}
                                  invalid={this.state.phoneNumberInvalid}
                                  errorMsg="The phone number appears to be invalid."
                                  onValid={this.phoneNumberSet}
                                  onInvalid={this.phoneNumberUnset}/>
          </div>

          <div className="actions">
            <a className="button" onClick={this.signUp}>Sign up</a>
          </div>
        </div>) : (
        <div className="tab-area">
          <h2>Sign in</h2>

          <form action="/users/login" method="post">
            <input type="hidden" name="authenticity_token" value={this.authenticity_token} />
            <div className="row">
              <label>Email</label>
            </div>

            <div className="row">
              <input type="text" name="user[email]" value={email} onChange={e => this.handleFormChange('email', e.target.value)} placeholder="jane@homelight.com"/>
            </div>

            <div className="row">
              <label>Password</label>
            </div>

            <div className="row">
              <input type="password" name="user[password]" placeholder="********"/>
            </div>

            <div className="row">
              <button className="button">Sign in</button>
            </div>
          </form>
        </div>
        )}
      </div>
    );
  }
}

SignupSignin.defaultProps = {
  disableSignIn: true
};

export default SignupSignin;
