// This code is a port of React Code Input https://github.com/suweya/react-verification-code-input
// Remember to include /polyfill/array_fill file in the manifest where you are including this file

import React from "react";
import PropTypes from "prop-types";
import '../../styles/components/global/SegmentedCodeInput.scss';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

class SegmentedCodeInput extends React.Component {

  constructor(props) {
    super(props);
    const { fields, values } = props;
    let vals = values.concat(Array(fields).fill('')).slice(0, fields);
    let autoFocusIndex = values.length >= fields ? 0 : vals.filter(x => x).length;

    this.state = { values: vals, autoFocusIndex };
    this.iRefs = vals.map(x => React.createRef());
    this.id = Date.now();
    this.triggerChange = this.triggerChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  triggerChange (values = this.state.values) {
    const { onChange, onComplete, fields } = this.props;
    const val = values.join('');
    onChange && onChange(val);
    if (onComplete && val.length >= fields) {
      onComplete(val);
    }
  };

  onChange(e) {
    const index = parseInt(e.target.dataset.id);
    if (this.props.type === 'number') {
      e.target.value = e.target.value.replace(/[^\d]/gi, '');
    }

    if (this.props.type === 'number' && !e.target.validity.valid) {
      return;
    }
    const { fields } = this.props;
    let next;
    const value = e.target.value;
    let { values } = this.state;
    values = Object.assign([], values);
    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }
      next = this.iRefs[nextIndex];
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fields) {
          values[cursor] = item;
        }
      });
      this.setState({ values });
    } else {
      next = this.iRefs[index + 1];
      values[index] = value;
      this.setState({ values });
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }

    this.triggerChange(values);
  };

  onKeyDown(e) {
    const index = parseInt(e.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = this.iRefs[prevIndex];
    const next = this.iRefs[nextIndex];
    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const vals = [...this.state.values];
        if (this.state.values[index]) {
          vals[index] = '';
          this.setState({ values: vals });
          this.triggerChange(vals);
        } else if (prev) {
          vals[prevIndex] = '';
          prev.current.focus();
          this.setState({ values: vals });
          this.triggerChange(vals);
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
    }
  };

  onFocus(e) {
    e.target.select(e);
  };

  render() {
    const { values, autoFocusIndex } = this.state;
    const {
      loading,
      title,
      fieldHeight,
      fieldWidth,
      fields,
      autoFocus,
      type
    } = this.props;
    const INPUT_STYLE = {
      width: fieldWidth,
      height: fieldHeight
    };
    const ROOT_STYLE = {
      width: fields * fieldWidth
    };
    const LOADING_STYLE = {
      lineHeight: `${fieldHeight}px`
    };
    return (
      <div className={`segmented-code-input-container`}>
        {title && <p className="title">{title}</p>}
        <div className="segmented-code-input">
          {values.map((value, index) => (
            <input
              type={type}
              pattern={type === 'number' ? '[0-9]*' : null}
              autoFocus={autoFocus && index === autoFocusIndex}
              style={INPUT_STYLE}
              key={`${this.id}-${index}`}
              data-id={index}
              value={value}
              ref={this.iRefs[index]}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              // onKeyUp={this.onKeyUp}
              onFocus={this.onFocus}
            />
          ))}
        </div>
        {loading && (
          <div className="loading" style={LOADING_STYLE}>
            <div className="blur" />
            <svg
              className="spin"
              viewBox="0 0 1024 1024"
              data-icon="loading"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill="#006fff"
                d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
              />
            </svg>
          </div>
        )}
      </div>
    );
  }
}

SegmentedCodeInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number']),
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  fields: PropTypes.number,
  loading: PropTypes.bool,
  title: PropTypes.string,
  fieldWidth: PropTypes.number,
  fieldHeight: PropTypes.number,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string)
};

SegmentedCodeInput.defaultProps = {
  type: 'number',
  fields: 4,
  fieldWidth: 58,
  fieldHeight: 54,
  values: [],
  autoFocus: true
};

export default SegmentedCodeInput;
