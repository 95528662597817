import React from "react";

class FlagIcon extends React.Component {

  render () {

    let uuid = 'flag-icon';

    return (
      <svg id={`flag-${uuid}`} viewBox="0 0 14 15">
        <path d="M9.57028968,3.457054 C10.4452876,3.457054 11.5116913,3.16538789 12.7695008,2.58205609 C13.0429377,2.45445209 13.3163745,2.47268135 13.5898113,2.63674346 C13.8632482,2.80080557 13.9999666,3.04689873 13.9999666,3.37502295 L13.9999666,10.0195384 C13.9999666,10.3294333 13.8723626,10.5664121 13.617155,10.7304742 C12.5416369,11.4778681 11.4296602,11.8515652 10.2812255,11.8515652 C9.84372653,11.8515652 9.38799831,11.7968779 8.91404125,11.6875031 C8.6406044,11.6328158 8.24867839,11.5143266 7.7382628,11.3320352 C7.30076384,11.1862024 6.97263963,11.0859421 6.75389015,11.0312547 C6.38930782,10.9401092 6.05206876,10.8945363 5.74217381,10.8945363 C4.61196831,10.8945363 3.57290829,11.1041713 2.62499374,11.523441 L2.62499374,14.0937474 C2.62499374,14.2760388 2.56119195,14.430986 2.43358795,14.55859 C2.30598395,14.686194 2.15103668,14.7499958 1.96874531,14.7499958 L1.53124635,14.7499958 C1.34895498,14.7499958 1.19400771,14.686194 1.06640371,14.55859 C0.938799706,14.430986 0.874997914,14.2760388 0.874997914,14.0937474 L0.874997914,3.53908506 C0.674477698,3.39325221 0.514972591,3.21096084 0.39648343,2.99221136 C0.277994268,2.77346188 0.218749478,2.53648314 0.218749478,2.28127556 C0.218749478,1.8437766 0.373697165,1.47463685 0.68359212,1.17385632 C0.993487076,0.873075788 1.36718424,0.731799944 1.8046832,0.750029206 C2.20572405,0.768258469 2.54752011,0.914091315 2.83007138,1.18752816 C3.11262265,1.46096501 3.26301291,1.78908923 3.28124218,2.17190082 C3.29947144,2.40887956 3.26301291,2.62762904 3.17186744,2.82814925 C3.7734285,2.60939977 4.39321883,2.50002503 5.031238,2.50002503 C5.46873696,2.50002503 5.92446518,2.5547124 6.39842224,2.66408714 C6.67185909,2.73700377 7.0637851,2.85549294 7.57420069,3.01955505 C8.01169965,3.16538789 8.33982387,3.26564821 8.55857334,3.32033558 C8.92315567,3.41148106 9.26039473,3.457054 9.57028968,3.457054 Z" id="flag"></path>
      </svg>
    );
  }
}

export default  FlagIcon;
