import React from "react";
import PropTypes from "prop-types";
import StarRatingIcon from "../icons/StarRatingIcon";
import JumpLink from "../global/JumpLink";
import '../../styles/components/profiles/ReviewSummary.scss';

class ReviewSummary extends React.Component {

  jumpLink (e) {
    e.preventDefault();
    let body = document.querySelectorAll('body, html');
    let elem = e.target;
    let link = elem.getAttribute('jump');
    let height = document.querySelector(`#${link}`).getBoundingClientRect().top + window.scrollY;
    body.animate({scrollTop: height}, 300);
  }

  calculateStarRating (rating = 0) {
    let wholeRating = rating | 0;
    let fractionalRating = rating.split('.').length >= 2 ? rating.split('.')[1] : 0
    let stars = [];
    let i = 0;
    let totalStars = 5;

    for (i; i < wholeRating; i +=1) {
      stars.push(<StarRatingIcon key={i} />)
    }

    if (fractionalRating > 0 && fractionalRating <= 5) {
      stars.push(<StarRatingIcon key={stars.length} type="half"/>)
    }

    if (fractionalRating > 5) {
      stars.push(<StarRatingIcon key={stars.length}/>)
    }

    if (stars.length < totalStars) {
      for (var s = stars.length; s < totalStars; s +=1) {
        stars.push(<StarRatingIcon key={s} type="empty" />)
      }
    }

    return stars;
  }

  render() {
    let reviewCopy = this.props.reviewCount === 1 ? 'Review' : 'Reviews';
    return (
      <React.Fragment>
        <div className={`review-summary-component ${this.props.addClass || ""}`}>
          <span className="rating-value">{this.props.rating}</span>
          <div className="star-count-wrap">
            <span className="stars">
              { this.calculateStarRating(this.props.rating) }
            </span>
            <JumpLink selector="reviews" addClass="review-count" linkText={`(${this.props.reviewCount} ${reviewCopy})`} track="agent_profile_reviews"/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ReviewSummary.propTypes = {
  rating: PropTypes.string,
  reviewCount: PropTypes.number,
  addClass: PropTypes.string
}

export default ReviewSummary;
