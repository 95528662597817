import React from "react";

const SignedContractBricon = (props) => (
  <svg className='signed-contract-bricon' width="40px" height='40px' viewBox='0 0 40 40'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-16.000000, -1144.000000)'>
        <g transform='translate(0.000000, 576.000000)'>
          <g transform='translate(16.000000, 568.000000)'>
            <circle id='Oval' fill='#EB4869' cx='20' cy='20' r='20'/>
            <path d='M20.8178125,25.254375 C20.725625,25.069375 20.565,25.050625 20.5,25.050625 C20.435,25.050625 20.274375,25.0690625 20.186875,25.2440625 L19.9471875,25.7234375 C19.748125,26.1228125 19.165,26.07875 19.0259375,25.658125 L18.5,24.0809375 L18.1684375,25.0771875 C17.984375,25.6290625 17.4690625,26 16.8871875,26 L16.5,26 C16.22375,26 16,25.77625 16,25.5 C16,25.22375 16.22375,25 16.5,25 L16.8871875,25 C17.038125,25 17.171875,24.90375 17.2196875,24.760625 L17.788125,23.053125 C17.89125,22.7465625 18.176875,22.5403125 18.5,22.5403125 C18.823125,22.5403125 19.10875,22.74625 19.2115625,23.053125 L19.6453125,24.354375 C20.2625,23.8484375 21.3346875,24.05125 21.7078125,24.796875 C21.766875,24.915 21.879375,24.9828125 22.0003125,24.9925 L22.0003125,22.42625 L26.0003125,18.4546875 L26.0003125,17 L21.75,17 C21.3375,17 21,16.6625 21,16.25 L21,12 L14.75,12 C14.334375,12 14,12.334375 14,12.75 L14,27.25 C14,27.665625 14.334375,28 14.75,28 L25.25,28 C25.665625,28 26,27.665625 26,27.25 L26,26 L22,25.9965625 C21.49625,25.986875 21.044375,25.7065625 20.8178125,25.254375 Z M26,15.809375 C26,15.6125 25.921875,15.421875 25.78125,15.28125 L22.721875,12.21875 C22.58125,12.078125 22.390625,12 22.190625,12 L22,12 L22,16 L26,16 L26,15.809375 Z M23,22.8425 L23,25 L25.1559375,25 L30.2084375,19.913125 L28.0871875,17.791875 L23,22.8425 L23,22.8425 Z M31.766875,17.2290625 L30.7709375,16.233125 C30.4603125,15.9225 29.95625,15.9225 29.645625,16.233125 L28.7940625,17.0846875 L30.9153125,19.2059375 L31.766875,18.354375 C32.0778125,18.04375 32.0778125,17.5396875 31.766875,17.2290625 Z' id='file-signature-solid' fill='#FFFFFF' fillRule='nonzero'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SignedContractBricon;
