import React from 'react'
import ChevronIcon from '../icons/ChevronIcon'
import '../../styles/components/global/JumpLink.scss';

class JumpLink extends React.Component {
  constructor (props) {
    super(props);
  }

  isNavUrl (link) {
    return /^\//.test(link) || /^http/.test(link);
  }

  jumpLink (e) {
    e.preventDefault();
    let link = this.props.selector;
    if (this.isNavUrl(link)) {
      window.open(link, '_self');
    } else {
      let element = document.getElementById(link)
      element.scrollIntoView(true);

      if (this.props.track) {
        this.trackEvent('Interaction', 'jump_link', this.props.track, 1);
      }
    }
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  render() {
    return (
      <React.Fragment>
        <a onClick={this.jumpLink.bind(this)} className={`jump-link-component ${this.props.addClass}`}>
          {this.props.linkText}
          {this.props.chevron && <ChevronIcon direction={this.props.chevron}/>}
        </a>
      </React.Fragment>
    );
  }
}

export default JumpLink
