import React from 'react'
import PropTypes from 'prop-types';

const MoveSafeBricon = ({ width, height }) => (
  <svg width={width} height={height} className="move-safe-bricon" viewBox="0 0 151.08 132.44">
    <defs>
      <style>{".cls-1{fill:#fff;}.cls-2{fill:#eb4869;}.cls-3{fill:#273653;}"}</style>
    </defs>
    <g>
      <g>
        <path className="cls-1" d="M105.51,0a45.64,45.64,0,0,0-30,11.23A45.58,45.58,0,0,0,3.34,62.71C7.56,73.63,16.94,84.79,24.65,93.3c8,8.84,26.78,26.17,45.66,37.68a10,10,0,0,0,10.41,0c19-11.55,37.72-28.86,45.71-37.68,7.71-8.51,17.09-19.67,21.31-30.59A45.58,45.58,0,0,0,105.51,0Z" />
        <rect className="cls-2" x="66.38" y="75.59" width="18.32" height="18.32" rx="2" />
        <path className="cls-1" d="M105.51,0a45.64,45.64,0,0,0-30,11.23A45.58,45.58,0,0,0,3.34,62.71a68.37,68.37,0,0,0,7.91,14.22A10,10,0,0,0,21.41,81,10,10,0,0,0,23.12,91.6c.58.65.8.9,1.2,1.33l.33.37c8,8.84,26.79,26.17,45.66,37.68a10,10,0,0,0,10.41,0c19-11.55,37.72-28.87,45.71-37.68,7.71-8.51,17.09-19.66,21.31-30.59A45.58,45.58,0,0,0,105.51,0Zm-30,36.41A10,10,0,0,0,84,31.81a25.58,25.58,0,0,1,45.23,23.43l-.08.18c-3.4,8.89-13.28,19.79-17.5,24.45a221.05,221.05,0,0,1-36.09,30.7,221,221,0,0,1-36-30.7l-.34-.38c-.36-.39-.56-.61-1.07-1.19A10.05,10.05,0,0,0,30.59,75a9.9,9.9,0,0,0-2,.2,10.52,10.52,0,0,0,.66-2.24,10,10,0,0,0-1.66-7.47,47.83,47.83,0,0,1-5.64-10l-.1-.24a25.57,25.57,0,0,1,45.24-23.4,9.94,9.94,0,0,0,8.42,4.6Z" />
        <path className="cls-2" d="M105.51,5a40.58,40.58,0,0,1,37.58,55.87C139.18,71,130.15,81.74,122.72,89.94c-7.79,8.6-26.1,25.49-44.6,36.77a5,5,0,0,1-5.21,0C54.49,115.48,36.17,98.57,28.35,89.94L28,89.57c-.39-.42-.61-.67-1.16-1.29a5,5,0,1,1,7.47-6.65c.52.59.73.82,1.1,1.22l.33.37a222.69,222.69,0,0,0,39.76,33.32,222.33,222.33,0,0,0,39.79-33.32c4.41-4.86,14.73-16.24,18.46-26a.49.49,0,0,0,0-.11,30.24,30.24,0,0,0,2.27-11.57A30.57,30.57,0,0,0,79.75,29.11a5,5,0,0,1-8.42,0,30.58,30.58,0,0,0-54.07,28l0,.11a53.19,53.19,0,0,0,6.21,11.07,5,5,0,1,1-8.18,5.75A63.31,63.31,0,0,1,8,60.87,40.58,40.58,0,0,1,75.54,18.22,40.37,40.37,0,0,1,105.51,5" />
        <polygon className="cls-3" points="112.5 68.45 112.5 63.67 111.15 63.67 111.15 67.22 112.5 68.45" />
        <path className="cls-1" d="M75.54,34.75h0a10,10,0,0,0-6.67,2.55L23.93,77.5a10,10,0,0,0-.79,14.12L38.06,78.3l3.44,3.6L45,85.52l30.5-27.28,37.78,34.63a5.41,5.41,0,0,0,.64.52c.15.11.39.31.7.51a8.89,8.89,0,0,0,.86.48l0,0a7.39,7.39,0,0,0,.77.36,9.78,9.78,0,0,0,1.08.35,6.07,6.07,0,0,0,.75.19,9.56,9.56,0,0,0,1.9.19,10,10,0,0,0,3.93-.8c.25-.11.45-.21.61-.3a9.63,9.63,0,0,0,1.15-.67,6.43,6.43,0,0,0,.6-.48,9.55,9.55,0,0,0,.75-.65c.12-.11.23-.23.34-.35a6.27,6.27,0,0,0,.49-.6c.16-.21.36-.47.57-.79s.34-.6.43-.76l0-.06a8.75,8.75,0,0,0,.73-1.85v-.06a8.33,8.33,0,0,0,.21-.88,7,7,0,0,0,.11-1,4.78,4.78,0,0,0,.06-.79V56.17a10,10,0,0,0-10-10H103.65a10,10,0,0,0-7.75,3.68L82.3,37.38a10,10,0,0,0-6.76-2.63Z" />
        <path className="cls-2" d="M75.54,39.75a5,5,0,0,1,3.38,1.32L98.65,59.15v-3a5,5,0,0,1,5-5H120a5,5,0,0,1,5,5V85.5a3.31,3.31,0,0,1,0,.33,5.11,5.11,0,0,1,0,.55,4.48,4.48,0,0,1-.12.46,4.19,4.19,0,0,1-.14.47c-.07.16-.14.31-.22.46s-.14.27-.22.4-.22.3-.33.45-.12.18-.2.26-.05,0-.07.07a5.37,5.37,0,0,1-.51.44,1.86,1.86,0,0,1-.27.22,5.07,5.07,0,0,1-.64.37c-.08,0-.15.09-.23.12a4.94,4.94,0,0,1-2,.4h0a5.17,5.17,0,0,1-.95-.09l-.35-.1a3.65,3.65,0,0,1-.56-.18,3.37,3.37,0,0,1-.38-.19,4,4,0,0,1-.46-.24l-.38-.29c-.09-.08-.2-.14-.29-.22L75.5,51.5,33.93,88.68a5,5,0,1,1-6.67-7.45L72.21,41a5,5,0,0,1,3.33-1.27M115,74.14v-13h-6.35v7.15L115,74.14" />
      </g>
    </g>
  </svg>
)

MoveSafeBricon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
}

MoveSafeBricon.defaultProps = {
  width: "34px",
  height: "30px"
}

export default MoveSafeBricon
