import React from 'react'
import PropTypes from 'prop-types'
import HeaderLogo from './HeaderLogo'
import PartnerLogo from './PartnerLogo'
import '../../styles/components/global/PageHeader.scss'

const PageHeader = ({ children, addClass, logoUrl, partnerLogoUrl, partnerLogoWidth }) => (
  <>
    <nav className={`page-header-component clearfix ${addClass}`}>
      <div className="menu-logo">
        <HeaderLogo logo="white" logoUrl={logoUrl}/>

        {partnerLogoUrl && (
          <PartnerLogo logoUrl={partnerLogoUrl} logoWidth={partnerLogoWidth} />
        )}
      </div>
      <div className="menu">
        {children}
      </div>
    </nav>
  </>
)

PageHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  addClass: PropTypes.string
}

PageHeader.defaultProps = {
  addClass: "",
  partnerLogoUrl: "",
  partnerLogoWidth: ""
}

export default PageHeader
