/****

  PUBSUB
    # FILTER TRANSACTIONS
    PubSub.emit('AgentTransactionMap--filter', {
      filter (transactions) {
        return ... filter the transactions ...
      }
    })
    # FILTER transactions by date etc...
    PubSub.emit('AgentTransactionMap--filter', {
      filter (transactions) {
        return transactions.filter(tx => {
          return moment(tx.date).get('year') === 2016
        })
      }
    })
    # Pan to a location
    PubSub.emit('AgentTransactionMap--pan', {coords: [-87.6298, 41.8781], zoom: 10})

****/

import React from "react";
import '../../styles/components/agents/AgentTransactionMap.scss';
import { ACCESS_TOKEN } from "../services/mapboxGeocoder";

class AgentTransactionMap extends React.Component {

  constructor (props) {
    super(props);
    let transactions = props.transactions;
    this.state = { transactions };

    this.updateMapBounds = this.updateMapBounds.bind(this);
  }

  componentDidMount () {
    let startTime = new Date().getTime();
    let giveUp = startTime + 15000;

    let interval = setInterval(() => {
      if (window.mapboxgl) {
        clearInterval(interval);
        this.onMapReady();
        this.mountMapBoxElement();
        this.registerPubSubs();
      } else if (new Date().getTime() > giveUp) {
        clearInterval(interval);
        PubSub.emit('AgentTransactionMap--maps-unavailable');
      }
    }, 5);
  }

  // Map is not guaranteed to be ready for centering with transaction bounds on init - this is calls fitBounds again when map is fully rendered
  updateMapBounds (bounds) {
    return () => {
      this.state.map.fitBounds(bounds, {padding: 20, maxZoom: 10, speed: 5});
    };
  }

  onMapReady () {
    PubSub.emit('AgentTransactionMap--maps-loaded');
    mapboxgl.accessToken = ACCESS_TOKEN;
  }

  mountMapBoxElement () {
    let transactions = this.normalizedTransactions();

    let { adjustedRangeLat, adjustedRangeLng } = GeoLib.transactionGeometryStats(transactions);
    let bounds = new mapboxgl.LngLatBounds();
    let geoJSON = this.createGeoJSON(transactions);
    let map = new mapboxgl.Map({
      container: this.mapElement, // this is an id
      style: `mapbox://styles/homelight/cjqsm3msvakki2sp7j1ugl9gy`
    });

    map.scrollZoom.disable();

    transactions.forEach(x => {
      let bounded = ( (adjustedRangeLng[0] <= x.lng) && (x.lng <= adjustedRangeLng[1]) ) && ( (adjustedRangeLat[0] <= x.lat) && (x.lat <= adjustedRangeLat[1]))

      if (bounded) {
        bounds.extend([x.lng, x.lat]);
      }
    });
    // Ensure bounds has _ne and _sw values, otherwise `fitBounds` will throw an exception
    if (Object.entries(bounds).length === 0) {
      bounds.extend([adjustedRangeLng[1], adjustedRangeLat[1]]);
    }

    map.addControl(new mapboxgl.NavigationControl({showCompass: false}));
    map.fitBounds(bounds, {padding: 20, maxZoom: 10, speed: 5});
    this.setState({ map, geoJSON });
    this.addClusters(map, geoJSON);

    map.on('load', this.updateMapBounds(bounds));
  }

  registerPubSubs () {

    if (!window.PubSub) { return; }

    PubSub.sub('AgentTransactionMap--pan', data => {
      let coords = new mapboxgl.LngLat(data.coords[0], data.coords[1]);
      this.panTo(data.coords, data.zoom);
    });

    PubSub.sub('AgentTransactionMap--filter', data => this.filterTransactions(data.filter, data.transactions));
    PubSub.sub('AgentTransactionMap--show-all', () => this.showAllTransactions());
    PubSub.sub('AgentTransactionMap--add-marker', data => this.addMarker(data));
    PubSub.sub('AgentTransactionMap--fit-bounds', data => this.fitBounds(data.bounds));
    PubSub.sub('AgentTransactionMap--zoom', zoom => this.zoom(zoom));
  }

  addMarker (data) {
    let marker = data.marker || new mapboxgl.Marker(data.element);

    marker.setLngLat(data.coords);

    if (data.popup) {
      let popup = `
        <div class="map-pop-address">${data.popup.line1 || ""}</div>
        <div class="map-pop-sale-info">${data.popup.line2 || ""}</div>
        <div class="map-pop-property-info">${data.popup.line3 || ""}</div>
      `;
      marker.setPopup(new mapboxgl.Popup().setHTML(popup));
    }

    marker.addTo(this.state.map);
  }

  panTo (center, zoom) {
    zoom = zoom || this.state.map.getZoom();
    this.state.map.easeTo({ center, zoom });
  }

  fitBounds (bounds) {
    this.state.map.fitBounds(bounds, {padding: 20, maxZoom: 10, speed: 5});
  }

  zoom (zoom) {
    this.state.map.setZoom(zoom);
  }

  filterTransactions (filter, transactions) {
    let filteredData  = transactions || filter(this.state.transactions);
    let geoJSON       = this.createGeoJSON(filteredData);
    this.state.map.getSource('transactions').setData(geoJSON);
  }

  showAllTransactions () {
    let { map, transactions } = this.state;
    let geoJSON = this.createGeoJSON(transactions);
    map.getSource('transactions').setData(geoJSON);
  }

  normalizedTransactions () {
    return lodash.uniqBy(this.state.transactions, tx => `${tx.lng}${tx.lat}`).filter(tx => tx.lng && tx.lat);
  }

  createGeoJSON (transactions) {
    let features = transactions.map(transaction => {
      let { address, city, state, propertyType, represented, price, date } = transaction;
      return {
        type: 'Feature',
        properties: { address, city, state, propertyType, represented, price, date },
        geometry: {
          type: 'Point',
          coordinates: [ transaction.lng, transaction.lat ]
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features
    };
  }

  addClusters (map, data) {

    map.on('load', () => {

      map.addSource('transactions', {
        type: 'geojson',
        data,
        cluster: true,
        clusterMaxZoom: 18, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'transactions',
        filter: ['has', 'point_count'],
        paint: {
          // step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          'circle-color': [
            'step',
            ['get', 'point_count'],
            'rgba(70, 182, 255, 0.8)',
            100,
            'rgba(255,165,59, 0.8)'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // Add the count of transactions being clustered
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'transactions',
        filter: ['has', 'point_count'],
        paint: { 'text-color': '#ffffff' },
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      // When zoomed in far enough,
      // create an unclustered point to represent the transaction
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'transactions',
        filter: ['!has', 'point_count'],
        paint: {
          'circle-color': 'rgb(70, 182, 255)',
          'circle-radius': 6,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // zoom when clicking on a cluster
      map.on('click', 'clusters', function (e) {
        var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id;

        map.getSource('transactions').getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        });
      });

      // show details when cliking on a transaction
      map.on('click', 'unclustered-point', function (e) {
        let feature = e.features[0];
        let coordinates = feature.geometry.coordinates.slice();
        let { address, city, state, propertyType, represented, price, date } = feature.properties;
        let description = [];

        description.push(address ? `<div class="map-pop-address">${address}, ${city}</div>` : `<div class="map-pop-address">${city}, ${state}</div>`);

        if (price) {
          description.push(`<div class="map-pop-sale-info">Sold ${moment(date).fromNow()} for ${price}</div>`);
        }

        description.push(`<div class="map-pop-property-info">${propertyType} &bull; Represented ${represented}</div>`);

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description.join(''))
            .addTo(map);
      });

      let makePointer = function () {
        map.getCanvas().style.cursor = 'pointer';
      }

      let clearPointer = function () {
        map.getCanvas().style.cursor = '';
      }

      map.on('mouseenter', 'clusters', makePointer);
      map.on('mouseleave', 'clusters', clearPointer);
      map.on('mouseenter', 'unclustered-point', makePointer);
      map.on('mouseleave', 'unclustered-point', clearPointer);

    });
  }

  render () {
    let ref = elem => { this.mapElement = elem };
    return <div ref={ref} style={{height:'100%'}}></div>
  }

}

export default AgentTransactionMap;
