import React from "react";
import LoadingSpinner from "../global/LoadingSpinner";
import FormattedPhoneNumber from "../global/FormattedPhoneNumber";
import SegmentedCodeInput from "../global/SegmentedCodeInput";
import '../../styles/components/qaas/VerifyPhone.scss';

class VerifyPhone extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      editPhoneNumber: false,
      resend: true
    };

    this.validator = modules.import("v4/modules/input-validation");
    this.tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');

    this.last4 = this.last4.bind(this);
    this.editPhoneNumber = this.editPhoneNumber.bind(this);
    this.sendCode = this.sendCode.bind(this);
    this.phoneNumberSet = this.phoneNumberSet.bind(this);
    this.phoneNumberUnset = this.phoneNumberUnset.bind(this);
    this.checkCode = this.checkCode.bind(this);
  }

  last4() {
    return this.state.phoneNumber.slice(-4);
  }

  editPhoneNumber() {
    this.setState({editPhoneNumber: true})
  }

  componentDidMount() {
    this.tracking.trackEvent('QuizFlow', 'show', 'VerifyCode');
  }

  phoneNumberSet(formattedPhoneNumber) {
    this.setState({ phoneNumber: formattedPhoneNumber, phoneNumberInvalid: false });
  }

  phoneNumberUnset() {
    this.setState({ phoneNumber: "" });
  }

  loadingText() {
    if (this.state.checking) {
      return "Checking your verification code..."
    }
    return this.state.resend ? "Resending verification code..." : "Sending verification code...";
  }

  checkCode(code) {
    this.setState({ loading: true, checking: true, verificationError: false });
    this.tracking.trackEvent('QuizFlow', 'click', 'VerifyCode');

    fetch("/leads/check-verification", {
      method: 'POST',
      body: JSON.stringify({ phone_number: this.state.phoneNumber, code: code }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(_response => {
      if (this.props.lead_token) {
        fetch("/leads/verified-by-sms", {
          method: 'POST',
          body: JSON.stringify({ lead_token: this.props.lead_token, phone_number: this.state.phoneNumber }),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
      }

      this.tracking.trackEvent('QuizFlow', 'success', 'VerifyCode');
      this.props.onVerify(code);
      this.setState({ showModal: false });
    })
    .catch(_error => {
      this.tracking.trackEvent('QuizFlow', 'error', 'VerifyCode');
      this.setState({ verificationError: true });
    })
    .finally(() => {
      this.setState({ loading: false, checking: false });
    });
  }

  sendCode(resend=false) {
    if(this.validator["phone"](this.state.phoneNumber, true)){
      this.setState({phoneNumberInvalid: false});
      this.tracking.trackEvent('QuizFlow', 'click', 'SendVerificationCode');
      this.setState({ loading: true, resend: resend, verificationError: false });

      fetch("/leads/start-verification", {
        method: 'POST',
        body: JSON.stringify({ phone_number: this.state.phoneNumber }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(_response => {
        this.tracking.trackEvent('QuizFlow', 'success', 'SendVerificationCode');
        this.setState({ verificationSent: true, editPhoneNumber: false });
      })
      .catch(_error => {
        this.tracking.trackEvent('QuizFlow', 'error', 'SendVerificationCode');
        alert("Something went wrong, please try again.");
      })
      .finally(() => {
        this.setState({ loading: false, resend: false });
      });


    } else {
      this.setState({phoneNumberInvalid: true});
    }
  }

  render () {
    return (
      <div className="verify-phone-number">
        <input type="hidden" name="phone" value={this.state.phoneNumber} />
        { this.state.loading ? <LoadingSpinner text={this.loadingText()}/> : <div>
          {this.props.step.heading && (<h2>{this.props.step.heading}</h2>)}
          {this.props.step.desc && (<p>{this.props.step.desc}</p>)}
          <div className="verify-input-container">
            { this.state.editPhoneNumber ?
              <div>
                <div className="with-padding">
                  <div className="input-container">
                    <FormattedPhoneNumber fieldName="phone"
                                          value={this.state.phoneNumber}
                                          invalid={this.state.phoneNumberInvalid}
                                          errorMsg="The phone number appears to be invalid."
                                          onValid={this.phoneNumberSet}
                                          onInvalid={this.phoneNumberUnset}/>
                  </div>
                  <div className="with-padding">
                    <button className="button" onClick={this.sendCode}>Send code</button>
                  </div>
                </div>
              </div> :
              <div>
                <div className="code-prompt desc with-padding">
                  Enter the 4-digit code we sent to your phone number ending in ({this.last4()}).
                </div>

                <div className="with-padding">
                  <SegmentedCodeInput onComplete={this.checkCode}/>
                  { this.state.verificationError ?
                    <div className="error-message with-padding">
                      The verification code you entered appears to be invalid.
                    </div> : null
                  }
                </div>

                <div className="modal-link-prompt with-padding">
                  Didn't get it? Click
                  <a className="modal-link" onClick={(e) => this.sendCode(e,true)}> here </a>
                  to resend
                </div>

                <div className="modal-link-prompt with-padding">
                  <a className="modal-link" onClick={this.editPhoneNumber}>Edit phone number</a>
                </div>
              </div>
            }
          </div>
        </div> }
      </div>
    )
  }
}

export default VerifyPhone;
