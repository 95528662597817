import PropTypes from "prop-types";
import React from "react";
import MoveSafeBricon from "../icons/MoveSafeBricon";
import '../../styles/components/profiles/ProfilePic.scss';

const ProfilePic = ({url, alt, addClass, moveSafe, inAgentCard, lazyLoad}) => {
  const side = !!inAgentCard ? 84 : 60;

  const style = {
    width: side,
    height: side,
    maxWidth: side,
    maxHeight: side
  };

  const img = !!lazyLoad ?
    <img data-src={url} alt={alt} className={`lazyload profile-pic ${addClass || ""}`} style={style} /> :
    <img src={url} className={`profile-pic ${addClass || ""}`} style={style} />;

  return(
    <React.Fragment>
      { img }
      <div className='move-safe-profile-badge'>
        { moveSafe && <MoveSafeBricon /> }
      </div>
    </React.Fragment>
  );
}

ProfilePic.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  addClass: PropTypes.string,
  moveSafe: PropTypes.bool,
  inAgentCard: PropTypes.bool,
  lazyLoad: PropTypes.bool
};

export default ProfilePic;
