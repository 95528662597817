import React from "react";

const FileWithCheckmarkBricon = (props) => (
  <svg className='file-with-checkmark-bricon' width="40px" height='40px' viewBox='0 0 40 40'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-16.000000, -1208.000000)'>
        <g transform='translate(0.000000, 576.000000)'>
          <g transform='translate(16.000000, 632.000000)'>
            <circle fill='#EB4869' cx='20' cy='20' r='20'/>
            <path d='M26,15.8106563 L26,16 L22,16 L22,12 L22.1893437,12 C22.38825,12 22.5790312,12.0790312 22.7196875,12.2196562 L25.7803438,15.2803125 C25.9209798,15.4209801 26,15.6117445 26,15.8106563 Z M21.75,17 L26,17 L26,27.25 C26,27.6642187 25.6642187,28 25.25,28 L14.75,28 C14.3357813,28 14,27.6642187 14,27.25 L14,12.75 C14,12.3357813 14.3357813,12 14.75,12 L21,12 L21,16.25 C21,16.6625 21.3375,17 21.75,17 Z M23.7905,20.429125 L22.9102187,19.5416875 C22.764375,19.3946562 22.5269375,19.3936875 22.3799063,19.5395625 L19.06625,22.8265625 L17.629375,21.3780625 C17.4835312,21.2310313 17.2460625,21.2300625 17.0990312,21.3759375 L16.211625,22.25625 C16.0645938,22.4020937 16.063625,22.6395312 16.2095,22.7865625 L18.7907813,25.3887187 C18.936625,25.53575 19.1740625,25.5367187 19.3210937,25.3908437 L23.788375,20.9594375 C23.9354062,20.8135937 23.936375,20.5761562 23.7905,20.429125 Z' id='file-check-solid' fill='#FFFFFF' fillRule='nonzero'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FileWithCheckmarkBricon;
