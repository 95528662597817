import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../global/LoadingSpinner";

class VerifyTwilioSecurityCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      securityCode: '',
      sentCode: false,
      resend: false,
      verificationError: false,
      verificationStatus: 'pending'
    };

    this.tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');

    this.sendCode = this.sendCode.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.limitSecurityCodeInput = this.limitSecurityCodeInput.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.recordEditContactInfoSalesEvent = this.recordEditContactInfoSalesEvent.bind(this);
  }
  componentDidMount () {
    this.sendCode();
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  onUnload () {
    fetch("/leads/record-duplicate-pii-sales-event", {
      method: 'POST',
      body: JSON.stringify({ event: 'duplicate_pii_lead_never_verified', lead_token: this.props.leadToken }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }

  verifyCode() {
    this.setState({ loading: true });
    const { email, phone, channel, leadToken } = this.props;
    const code = this.state.securityCode;

    const urlQueryParams = new URLSearchParams({
      phone_number: phone,
      email: email,
      code: code,
      channel: channel
    })

    fetch("/leads/check-twilio-verification-code?" + urlQueryParams.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(response => {
      this.setState({ verificationStatus: response.verification_status, verificationError: false });
      if (response.verification_status === "approved") {
        fetch("/leads/record-duplicate-pii-sales-event", {
          method: 'POST',
          body: JSON.stringify({ event: 'duplicate_pii_lead_verified', lead_token: leadToken }),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })

        if (channel === 'email') {
          this.tracking.trackEvent('QuizFlow', 'Match', 'Email_Auth_Success');
        } else {
          this.tracking.trackEvent('QuizFlow', 'Match', 'SMS_Auth_Success');
        }
        document.querySelector('#error-message').setAttribute('class', '');
        this.props.onVerify(this.state.verificationStatus);
      } else {
        if(channel === 'email') {
          this.tracking.trackEvent('QuizFlow', 'Match', 'Email_Auth_Error');
        } else {
          this.tracking.trackEvent('QuizFlow', 'Match', 'SMS_Auth_Error');
        }
        this.setState({ verificationError: true });
      }
    })
    .finally(() => {
      this.setState({ loading: false });
    })
  }

  sendCode() {
    const { phone, email, channel } = this.props;
    const { sentCode, resend } = this.state;
    if (!sentCode || resend) {
      fetch("/leads/send-twilio-verification-code", {
        method: 'POST',
        body: JSON.stringify({ phone_number: phone, email: email, channel: channel }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(_response => { this.setState({ sentCode: true, resend: false, loading: false }) });
    }
  }

  resendCode() {
    this.tracking.trackEvent('QuizFlow', 'Match', 'Requests_Resend');
    this.setState({ loading: true, resend: true }, () => { this.sendCode() })
  }

  limitSecurityCodeInput(event) {
    const code = event.target.value;
    const maxLength = 6;
    if (code !== undefined && code.toString().length >= maxLength) {
      event.preventDefault();
    }
  }

  handleInputChange(event) {
    this.setState({ securityCode: event.target.value })
  }

  loadingText() {
    if (this.state.resend) {
      return "Resending verification code...";
    }
    return "Checking your verification code...";
  }

  recordEditContactInfoSalesEvent () {
    fetch("/leads/record-duplicate-pii-sales-event", {
      method: 'POST',
      body: JSON.stringify({ event: 'duplicate_pii_lead_edited_info', lead_token: this.props.leadToken }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }

  renderSpamMessage(channel) {
    return channel === "email" ? <p>(check your spam folder)</p> : ''
  }

  renderInputForm() {
    const { heading, protectedAddress, piiIndexStep, channel } = this.props;
    const editPiiRegex = /qaas=\d*/
    const editPiiUrl = window.location.href.replace(editPiiRegex, `qaas=${piiIndexStep}`);
    const { securityCode, verificationError } = this.state;
    if (verificationError) {
      document.querySelector('#error-message').innerHTML = `The security code you entered appears to be invalid`;
      document.querySelector('#error-message').setAttribute('class', 'error')
    } else {
      document.querySelector('#error-message').setAttribute('class', '');
    }

    return (
      <div id='verify-identity'>
        <h3 className="heading">{heading}</h3>
        <p className='enter-code'>
          For your security, we sent a 6-digit code to {protectedAddress}.
        </p>
        <p>
          Please enter the code below to continue.
        </p>
        <div className='input-group security-code'>
          <input className='security-code-input' type='number' placeholder='Enter security code' name='security_code' onKeyPress={this.limitSecurityCodeInput} value={this.state.securityCode} onChange={this.handleInputChange}/>
          <div className='actions'>
            <a className={`button next-button ${ securityCode ? '' : 'block-continue'}`} onClick={ this.verifyCode }>
              Continue
            </a>
          </div>
        </div>
        <div className='resend-code'>
          <p className='resend-code-prompt'>
            {`Didn't get it?  `}
            <a onClick={this.resendCode}>Resend code</a>
          </p>
          {this.renderSpamMessage(channel)}
        </div>
        <p className='resend-code'>
          <a href={editPiiUrl} onClick={this.recordEditContactInfoSalesEvent}>Edit account information</a>
          </p>
      </div>
    )
  }

  render () {
    const component = this.state.loading ? <LoadingSpinner text={this.loadingText()}/> : this.renderInputForm()
    return (component)
  }
}

VerifyTwilioSecurityCode.propTypes = {
  heading: PropTypes.string,
  channel: PropTypes.string.isRequired,
  protectedAddress: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  onVerify: PropTypes.func.isRequired,
  piiIndexStep: PropTypes.number.isRequired
}

VerifyTwilioSecurityCode.defaultProps = {
  heading: 'We sent you a code',
  phone: null,
  email: null
}

export default VerifyTwilioSecurityCode;
