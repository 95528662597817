import React from "react";
import '../../styles/components/global/FormattedPhoneNumber.scss';

class FormattedPhoneNumber extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      [this.props.fieldName]: this.props.value
    };

    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
  }

  formatPhoneNumber(event) {
    const name = event.target.name;
    let value = event.target.value;
    value = value.replace(/\D/g,'');
    value = value.substring(0,10);
    let size = value.length;

    if(size < 4){
      value = '('+value;
    } else if(size < 7) {
      value = '('+value.substring(0,3)+') '+value.substring(3,6);
    } else {
      value = '('+value.substring(0,3)+') '+value.substring(3,6)+'-'+value.substring(6,10);
    }
    this.setState({ [this.props.fieldName]: value });

    if (value.length === 14 && this.props.onValid) {
      this.props.onValid(value);
    } else if(this.props.onInvalid) {
      this.props.onInvalid(value);
    }
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="formatted-phone-number">
        <input type="tel"
               className={`phone-number ${this.props.invalid ? 'error' : ''} `}
               name={this.props.fieldName}
               placeholder="Enter your phone number"
               value={ this.state[this.props.fieldName] || '' }
               onChange={ this.formatPhoneNumber }/>
        { this.props.invalid ?
          <div className="error-message">
            {this.props.errorMsg}
          </div> : null
        }
      </div>
    );
  }
}

export default FormattedPhoneNumber;
