import React from 'react'
import PropTypes from 'prop-types'

const HomeIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34">
    <path
      fillRule="evenodd"
      d="m38.5 15-4.7-3.8V5.3c0-.2-.2-.4-.4-.4h-1.5c-.3 0-.5.2-.5.4v3.9L21 .5c-1-.7-2.2-.7-3 0L.1 15c-.2.2-.3.6 0 .8l.7 1c.2.2.6.3.8 0l3.1-2.5v18.5c0 .6.6 1.2 1.2 1.2h9.7c.7 0 1.2-.6 1.2-1.2V23h4.9v9.8c0 .7.5 1.2 1.2 1.2h9.6c.7 0 1.2-.6 1.2-1.2V14.3l3.1 2.5c.3.3.7.2.9 0l.7-1 .2-.4c0-.2-.1-.3-.3-.4Zm-7 16.6h-7.3v-9.8c0-.6-.6-1.2-1.2-1.2h-7.3c-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8v9.8H7.2V12.3l12.1-9.9 12.1 10v19.2Zm0 0"
      fill={fill}
    />
  </svg>
)

HomeIcon.propTypes = {
  fill: PropTypes.string
}

HomeIcon.defaultProps = {
  fill: 'currentColor'
}

export default HomeIcon
