import React from "react";
import { maskInput } from "../../utils/vanillaTextMask";

class PhoneInput extends React.Component {

  componentDidMount () {
    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.phoneMask = maskInput({
      inputElement: this.input,
      mask: phoneMask,
      guide: false
    });
  }

  componentWillUnmount () {
    this.phoneMask.destroy();
  }

  render () {
    let ref = r => { this.input = r }
    return (<input ref={ref} type="tel" { ...this.props } onChange={this.props.onChange} />)
  }
}

export default PhoneInput;
