import React from 'react'
import PropTypes from 'prop-types'
import { geoSearch } from '../services/mapboxGeocoder'
import SearchIcon from '../icons/SearchIcon'
import LoadingSpinner from './LoadingSpinner'
import '../../styles/components/global/MapboxPlaceAutocomplete.scss'

function debounce(context, func, wait = 350) {
  let timeout;
  return function() {
    let args = arguments;
    let later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

class MapboxPlaceAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      defaultResult: null,
      focus: null,
    };
    this.search = this.search.bind(this);
    this.select = this.select.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.debouncedSearch = debounce(this, this.performSearch, this.props.speed);
    this.renderButton = this.renderButton.bind(this);
  }

  clickOutside(e) {
    let {selectOnClickOutside} = this.props;

    let clickedOutsideComponent = !!this.element && !this.element.contains(e.target);
    let hasResults = this.state.results.length > 0;
    if (hasResults) {
      this.setState({defaultResult: this.state.results[0]});

      if (clickedOutsideComponent) {
        if (selectOnClickOutside) {
          this.select(this.state.results[0]);
        }

        this.setState({results: []});
      } else {
        this.createClickOutside();
      }
    }
  }

  createClickOutside() {
    document
      .getElementsByTagName('html')[0]
      .addEventListener('click', this.clickOutside, {once: true});
  }

  destroyClickOutside() {
    document.getElementsByTagName('html')[0].removeEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    this.destroyClickOutside();
  }

  search() {
    if (this.props.onInput) {
      this.props.onInput(this.input.value);
    }

    if (this.input.value && this.input.value.length > 2) {
      this.debouncedSearch();
    } else {
      this.setState({results: []}, () => this.destroyClickOutside());
    }
  }

  performSearch() {
    let bias =
      this.props.bias &&
      this.props.bias.length &&
      this.props.bias.filter(x => x).length === 2 &&
      this.props.bias.join(',');
    let {types, updateResults} = this.props;
    if (this.input) {
      geoSearch(this.input.value, {types, bias})
        .then(results => {
          this.setState({results});
          updateResults && updateResults(results);
          this.createClickOutside();
        })
        .catch(err => {
          this.setState({results: []}, () => this.destroyClickOutside());
        });
    }
  }

  select(result) {
    this.setState({results: []}, () => this.destroyClickOutside());

    if (this.props.setLoading)  {
      this.props.setLoading();
    }

    if (result) {
      this.input.value = result.description.replace(', United States', '');
    }

    if (this.props.onSelect) {
      this.props.onSelect(result);
    }
  }

  moveFocus(dir) {
    let {focus, results} = this.state;
    focus = focus === null ? 0 : Math.max(0, Math.min(results.length - 1, focus + dir));
    this.setState({focus});
  }

  acceptFocus() {
    if (this.state.focus !== null && !!this.state.results[this.state.focus]) {
      this.select(this.state.results[this.state.focus]);
    }
  }

  onKeyDown(e) {
    switch (e.which) {
      // up
      case 38:
        e.preventDefault();
        this.moveFocus(-1);
        break;
      // down
      case 40:
        this.moveFocus(1);
        break;
      // accept
      case 13:
        if (this.state.results.length > 0 && this.state.focus == null) {
          this.select(this.state.results[0], 0);
        }
        this.acceptFocus();
        e.preventDefault();
        break;
    }
  }

  renderResults() {
    if (!this.state.results) return '';
    if (!this.state.results.length) return '';
    return (
      <ul>
        {this.state.results.map((result, k) => (
          <li
            key={k}
            className={k === this.state.focus ? 'active' : ''}
            onClick={() => this.select(result)}
          >
            {result.description}
          </li>
        ))}
      </ul>
    );
  }

  renderButton() {
    const {showLoading, buttonText, loading} = this.props;
    return showLoading && loading ? <LoadingSpinner /> : buttonText;
  }

  render() {
    const {defaultValue, showButton, showSearchIcon, buttonClass} = this.props;
    let inputref = r => {
      this.input = r;
    };
    let ref = r => {
      this.element = r;
    };

    return (
      <div
        ref={ref}
        className={`mapbox-place-autocomplete ${
          showSearchIcon ? 'mapbox-place-autocomplete--add-search-icon' : ''
        } ${this.props.addClass || ''}`}
      >
        {showSearchIcon && <SearchIcon />}
        <input
          defaultValue={defaultValue}
          autoComplete="off"
          placeholder={this.props.placeHolder || 'Enter a Location'}
          onInput={this.search}
          onKeyDown={this.onKeyDown}
          ref={inputref}
          id={this.props.id}
          className={this.props.inputClass || ''}
          style={{background: this.props.inputColor}}
          name={this.props.name}
        />
        {showButton ? (
          <div className="actions">
            <a
              className={buttonClass}
              onClick={() => this.select(this.state.results[0])}
            >
              {this.renderButton()}
            </a>
          </div>
        ) : null}
        {this.renderResults()}
      </div>
    );
  }
}

MapboxPlaceAutocomplete.propTypes = {
  speed: PropTypes.number,
  id: PropTypes.string,
  addClass: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onInput: PropTypes.func,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  inputClass: PropTypes.string,
  selectOnClickOutside: PropTypes.bool
};

MapboxPlaceAutocomplete.defaultProps = {
  speed: 350,
  id: "",
  addClass: "",
  placeholder: "",
  onSelect () {},
  onInput () {},
  buttonText: "Next",
  showButton: false,
  showSearchIcon: false,
  inputClass: "",
  selectOnClickOutside: false
}

export default MapboxPlaceAutocomplete
