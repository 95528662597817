import React from "react";
import JumpWrapper from "../global/JumpWrapper";
import ReviewSummary from "./ReviewSummary";
import '../../styles/components/profiles/ProfileMetricSummary.scss';

class ProfileMetricSummary extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      metrics: JSON.parse(this.props.metrics)
    };
  }

  linkedFragment (metric) {
    let links = {
      transactions: "#agent-transactions",
      profile_details: '.profile-details-list-component'
    };
    return (
      <JumpWrapper selector={links[metric.jump]} track={metric.jump}>
        <React.Fragment>
          <p className="metric-name">{metric.label}</p>
          <span className="metric-value">
            { metric.value}
          </span>
        </React.Fragment>
      </JumpWrapper>
    )
  }

  generateMetricFragment (metric) {
    if (metric.jump && (metric.jump === 'transactions' ? this.props.transaction_count > 0 : true) ) {
      return this.linkedFragment(metric)
    } else {
      return (
        <React.Fragment>
          <p className="metric-name">{metric.label}</p>
          <span className="metric-value">
            { metric.value}
          </span>
        </React.Fragment>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className={`profile-metric-summary ${this.props.addClass || ""}`}>
          <ul className="metrics">
            {
              this.state.metrics.map( (metric, i) => {
                return (
                  <li className="metric" key={`metric-summary-${i}`}>
                    { this.generateMetricFragment(metric) }
                  </li>
                )
              })
            }

            {
              (() => {
                if (this.props.reviewCount > 0) {
                  return (<ReviewSummary reviewCount={this.props.reviewCount} rating={this.props.reviewRating}/>)
                }
              })()
            }
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileMetricSummary;
