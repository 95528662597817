import NoticeBanner from "./NoticeBanner";
import React from "react";

const EliteAgentBanner = () => {
  const noticeText = 'Elite Agents are the top 1% of HomeLight. They consistently provide the best service to HomeLight clients and get the best results.'
  return (
    <NoticeBanner noticeText={noticeText} />
  );
}

export default EliteAgentBanner;
