const { initializeSentry } = require("../utils/initializeSentry")
initializeSentry()

require("lazysizes");

noJQ = true;

var context = require.context("components/loadable/agentProfile", true);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(context);

// styles initializations and base requires
require("../styles/agentProfile/base.scss");
