import React from "react";

class JumpWrapper extends React.Component {

  constructor (props) {
    super(props);
  }

  jumpLink (e) {
    e.preventDefault();
    let body = document.querySelectorAll('body, html');
    let link = this.props.selector;
    let height = document.querySelector(`#${link}`).getBoundingClientRect().top + window.scrollY;
    body.animate({scrollTop: height}, 300);

    if (this.props.track) {
      this.trackEvent('Interaction', 'jump_link', this.props.track, 1);
    }
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  render() {
    return (
      <React.Fragment>
        <a onClick={this.jumpLink.bind(this)} className={`jump-wrapper-component ${this.props.addClass || ""}`}>
          {this.props.children}
        </a>
      </React.Fragment>
    );
  }
}

export default JumpWrapper;
