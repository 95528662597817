import React from "react";

const LaptopBricon = (props) => (
  <svg className='laptop-bricon' width="40px" height='40px' viewBox='0 0 40 40'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-16.000000, -816.000000)'>
        <g transform='translate(0.000000, 576.000000)'>
          <g transform='translate(16.000000, 240.000000)'>
            <circle fill='#EB4869' cx='20' cy='20' r='20'/>
            <path d='M29.5,25 L21.923125,25 C21.9,25.6190625 21.4634375,26 20.9,26 L19,26 C18.4159375,26 17.968125,25.4540625 17.9759375,25 L10.5,25 C10.225,25 10,25.225 10,25.5 L10,26 C10,27.1 10.9,28 12,28 L28,28 C29.1,28 30,27.1 30,26 L30,25.5 C30,25.225 29.775,25 29.5,25 Z M28,13.5 C28,12.675 27.325,12 26.5,12 L13.5,12 C12.675,12 12,12.675 12,13.5 L12,24 L28,24 L28,13.5 Z M26,22 L14,22 L14,14 L26,14 L26,22 Z' id='laptop-solid' fill='#FFFFFF' fillRule='nonzero'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LaptopBricon;
