import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from '../icons/ChevronIcon';
import '../../styles/components/global/BreadCrumb.scss';

import tracking from '../../components/services/tracking_service';

const BreadCrumb = ({crumbs, addClass, gaInfo, customDelimiter}) => {
  const parsedCrumbs = JSON.parse(crumbs) || [];
  const efectiveDelimiter = customDelimiter ? customDelimiter : <ChevronIcon direction='right' />

  const crumbWrapper = node => {
    if (node !== null) {
      node.scrollLeft = window.innerWidth;
    }
  };

  const trackEvent = (gaInfo, gaLabel) => {
    if (gaInfo && gaInfo.category !== undefined && gaInfo.action !== undefined && gaLabel !== undefined) {
      tracking.trackEvent(gaInfo.category, gaInfo.action, gaLabel)
    }
  }

  return (
    <div ref={crumbWrapper} itemProp="breadcrumb" className={`${addClass}`}>
      {
        parsedCrumbs.map((crumb, i) => {
          const isLastCrumb = i + 1 === parsedCrumbs.length;
          const crumbClass = `crumb${isLastCrumb ? ' last-crumb' : ''}`;
          const delimiter = !isLastCrumb ? efectiveDelimiter : null;

          return crumb.url ? (
            <React.Fragment key={`bcc-${i}`}>
              <a className={crumbClass} alt={crumb.label} href={crumb.url} onClick={() => trackEvent(gaInfo, crumb.gaLabel)}>{crumb.label}</a>
              { delimiter }
            </React.Fragment>
          ) : (
            <React.Fragment key={`bcc-${i}`}>
              <div className={crumbClass}>{crumb.label}</div>
              <span>{ delimiter }</span>
            </React.Fragment>
          )
        })
      }
    </div>
  );
};

BreadCrumb.propTypes = {
  crumbs: PropTypes.string,
  addClass: PropTypes.string,
  gaInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string
  })
}

BreadCrumb.defaultProps = {
  crumbs: '',
  addClass: '',
  gaInfo: null
}

export default BreadCrumb;
