import $ from "jquery"

export default {
  get ({ url, cors_enabled }) {
    return new Promise( (resolve, reject) => {
      this.ajax({ type: 'GET', url, cors_enabled }).then(resolve).fail(reject);
    });
  },

  put ({ url, data, cors_enabled }) {
    return new Promise( (resolve, reject) => {
      this.ajax({ type: 'PUT', url, data, cors_enabled }).then(resolve).fail(reject);
    });
  },

  post ({ url, data, cors_enabled }) {
    return new Promise( (resolve, reject) => {
      this.ajax({ type: 'POST', url, data, cors_enabled }).then(resolve).fail(reject);
    });
  },

  delete ({ url, cors_enabled }) {
    return new Promise( (resolve, reject) => {
      this.ajax({ type: 'DELETE', url, cors_enabled }).then(resolve).fail(reject);
    });
  },

  defaultHeaders () {
    if (process.env.NODE_ENV !== "production" && process.env.HOMELIGHT_URL) {
      const username = process.env.APP_USERNAME
      const password = process.env.APP_PASSWORD

      return { 'Authorization': 'Basic ' + btoa(username + ":" + password) }
    }

    return {}
  },

  ajax ({ url, type, data, cors_enabled }) {
    if (type === 'POST') {
      data = data || {};
      data.authenticity_token = $('[name=csrf-token]').attr('content');
    }
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }

    const contentType = 'application/json';
    const dataType    = 'json';
    const headers = this.defaultHeaders();
    let xhrFields = {};

    if (cors_enabled === true) {
      xhrFields = { withCredentials: true };
    }

    return $.ajax({ url, type, headers, data, contentType, dataType, xhrFields });
  }
}
