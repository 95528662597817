import React, { useState, useEffect } from "react";
import { InlineWidget, CalendlyEventListener  } from "react-calendly";
import { useIsMobile } from "../../hooks/useResponsive";
import { HOMELIGHT_PRIMARY_COLOR } from "../../utils/constants";
import LoadingSpinner from "../global/LoadingSpinner";

// NOTE: if you want to track that a calendly meeting was created you need to pass the lead_token
// as the utmContent prop so that when the calendly webhook is triggered in hapi we are able to fetch
// the lead there and correctly track the conversion.

const CalendlyQuizStep = ({
  url,
  onSubmit,
  email = '',
  name = '',
  utm = {},
  primaryColor = HOMELIGHT_PRIMARY_COLOR 
}) => {
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile()

  const submitButtonStyles = {
    display: 'block', 
    margin: isMobile ? '0 auto' : '24px auto', 
    width: isMobile ? '' : '240px'
  }

  useEffect(() => {
    document.querySelector('.quiz-component').classList.add("quiz-component--large")
    return () => {
      document.querySelector('.quiz-component').classList.remove("quiz-component--large")
    }
  }, []);

  const handleSubmit = (event) => {
    setLoading(true)
    onSubmit(event)
  }

  return (
    <>
      <InlineWidget
        styles={{
          height: isMobile && showSubmitButton ? 520 : 630
        }}
        pageSettings={{
          primaryColor
        }}
        prefill={{
          email,
          name
        }}
        url={url}
        utm={utm}
      />
      <CalendlyEventListener onEventScheduled={() => setShowSubmitButton(true)}>
        {
          showSubmitButton && (
            loading ? (
              <div className="calendly-loading-spinner">
                <LoadingSpinner style='chasing' text='' height='34px' />
              </div>
            ) : (
              <a className='button next-button' style={submitButtonStyles} onClick={handleSubmit}>
                Go to your dashboard
              </a>
            )
          )
        }
      </CalendlyEventListener>
    </>
  )
}

export default CalendlyQuizStep;