import React from "react";
import PropTypes from "prop-types";
import '../../styles/components/agents/AgentSpecialties.scss';

const AgentSpecialties = ({addClass, specialties}) => (
  <React.Fragment>
    <label>Specialties</label>
    <ul className={`agent-specialties-component ${addClass || ""}`}>
      {
        JSON.parse(specialties).map( (specialty, i) => {
          return (
            <li className="specialty" key={`agent-specialtys-${i}`}>
              {specialty.label}
            </li>
          )
        })
      }
    </ul>
  </React.Fragment>
);

AgentSpecialties.propTypes = {
  specialties: PropTypes.string
};

export default AgentSpecialties;
