import React from "react";

const CamcorderBricon = (props) => (
  <svg className='camcorder-bricon' width="40px" height='40px' viewBox='0 0 40 40'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-16.000000, -880.000000)'>
        <g transform='translate(0.000000, 576.000000)'>
          <g transform='translate(16.000000, 304.000000)'>
            <circle fill='#EB4869' cx='20' cy='20' r='20'/>
            <path d='M21.3388889,15 L13.3277778,15 C12.5944444,15 12,15.5944444 12,16.3277778 L12,24.3388889 C12,25.0722222 12.5944444,25.6666667 13.3277778,25.6666667 L21.3388889,25.6666667 C22.0722222,25.6666667 22.6666667,25.0722222 22.6666667,24.3388889 L22.6666667,16.3277778 C22.6666667,15.5944444 22.0722222,15 21.3388889,15 Z M26.6,16.0472222 L23.5555556,18.1472222 L23.5555556,22.5194444 L26.6,24.6166667 C27.1888889,25.0222222 28,24.6083333 28,23.9 L28,16.7638889 C28,16.0583333 27.1916667,15.6416667 26.6,16.0472222 Z' fill='#FFFFFF' fillRule='nonzero'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CamcorderBricon;

