import React from "react";
import PropTypes from "prop-types";
import ChooseVerificationMethod from "./ChooseVerificationMethod";
import VerifyTwilioSecurityCode from "./VerifyTwilioSecurityCode";

class VerifyIdentity extends React.Component {

  constructor(props) {
    super(props);
    this.tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
    this.onUnload = this.onUnload.bind(this);
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  onUnload () {
    fetch("/leads/record-duplicate-pii-sales-event", {
      method: 'POST',
      body: JSON.stringify({ event: 'duplicate_pii_lead_never_verified', lead_token: this.props.leadToken }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }

  render () {
    const { duplicatePiiStatus, phone, email, onVerify, newQuizStyles, piiIndexStep, leadToken } = this.props;
    const lastFour = phone && phone.slice(-4);
    const protectedPhone = phone && `(***) ***-${lastFour}`;
    const domain = email && email.match(/@[a-z.]+/)[0];
    const protectedEmail = email ? `${email[0]}****${domain}` : ``;

    if (duplicatePiiStatus === "phone_and_email_exist") {
      this.tracking.trackEvent('QuizFlow', 'Match', 'Email_SMS');
      return(
        <div className={`animate-in ${newQuizStyles ? 'conversational-button-question-container' : ''}`}>
          <div className={`animate-in ${newQuizStyles ?'conversational-button-question-container' : ''}`}>
            <ChooseVerificationMethod
              leadToken={leadToken}
              phone={phone}
              email={email}
              protectedEmail={protectedEmail}
              protectedPhone={protectedPhone}
              onVerify={onVerify}
              piiIndexStep={piiIndexStep}
            />
          </div>
        </div>
      )
    } else {
      const emailDelivery = duplicatePiiStatus === 'email_exists'
      const deliveryMethod = emailDelivery ? 'email' : 'phone number';
      const channel = emailDelivery ? 'email' : 'sms';
      const protectedAddress = emailDelivery ? protectedEmail : protectedPhone;
      const heading = `This ${deliveryMethod} already exists in our system`
      if (channel === 'email') {
        this.tracking.trackEvent('QuizFlow', 'Match', 'Email');
      } else {
        this.tracking.trackEvent('QuizFlow', 'Match', 'SMS');
      }
      return (
        <div className={`animate-in ${newQuizStyles ? 'conversational-button-question-container' : ''}`}>
          <div className={`animate-in ${newQuizStyles ?'conversational-button-question-container' : ''}`}>
            <VerifyTwilioSecurityCode
              leadToken={leadToken}
              heading={heading}
              phone={phone}
              email={email}
              description={`For your security, we sent a 6-digit code to <${protectedAddress}>. Please enter the code below to continue.`}
              onVerify={onVerify}
              channel={channel}
              piiIndexStep={piiIndexStep}
            />
          </div>
        </div>
      )
    }
  }
}

VerifyIdentity.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  duplicatePiiStatus: PropTypes.string.isRequired,
  onVerify: PropTypes.func.isRequired,
  newQuizStyles: PropTypes.bool.isRequired,
  piiIndexStep: PropTypes.number.isRequired
}

VerifyIdentity.defaultProps = {
  phone: null,
  email: null
}

export default VerifyIdentity;
