import React from 'react'
import PropTypes from 'prop-types';
import '../../styles/components/global/Tooltip.scss'

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayTooltip: false
    };
    this.hideTooltip = this.hideTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
  }

  hideTooltip () {
    this.setState({displayTooltip: false})
  }
  showTooltip () {
    this.setState({displayTooltip: true})
  }

  renderMessage() {
    const { message, color, asHTML } = this.props;
    if (asHTML) {
      return (<div className={`tooltip-message tooltip-message--${color}`} dangerouslySetInnerHTML={{__html: message}}/>);
    } else {
      return (<div className={`tooltip-message tooltip-message--${color}`}>{message}</div>);
    }
  }

  render() {
    const { position, color } = this.props;

    return (
      <span className='tooltip'
            onMouseLeave={this.hideTooltip}
      >
        {this.state.displayTooltip &&
        <div className={`tooltip-bubble tooltip-${position} tooltip-bubble--${color}`}>
          { this.renderMessage() }
        </div>
        }
        <span
          className='tooltip-trigger'
          onMouseOver={this.showTooltip}
        >
          {this.props.children}
        </span>
      </span>
    )
  }
}

Tooltip.propTypes = {
  asHTML: PropTypes.bool,
  position: PropTypes.string.isRequired,
  color: PropTypes.string
}

Tooltip.defaultProps = {
  asHTML: false,
  color: ""
}

export default Tooltip
