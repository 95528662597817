import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from './PageHeader'
import PhoneNumber from './PhoneNumber'
import HeaderMenu from './HeaderMenu'
import SharedUltraSubNav from './SharedUltraSubNav'

class GlobalHeader extends React.Component {
  render () {
    if (this.props.sharedUltraSubscription) {
      return (
        <SharedUltraSubNav {...this.props} />
      )
    }

    const {
      addClass,
      logoUrl,
      lightNav,
      signInPath,
      signedInProps,
      phoneNumber,
      formattedNumber,
      partnerLogoUrl,
      partnerLogoWidth,
      partnerPhone,
      favoritesFeatureFlag,
      notificationSettingsFeatureFlag,
      myHomeFeatureFlag
    } = this.props;
      return (
        <PageHeader
          className='global-header'
          addClass={`global-header ${addClass}`}
          logoUrl={logoUrl}
          partnerLogoUrl={partnerLogoUrl}
          partnerLogoWidth={partnerLogoWidth}
        >
          {partnerPhone && (
            <PhoneNumber phone={partnerPhone}/>
          )}
          <HeaderMenu
            lightNav={lightNav}
            signInPath={signInPath}
            phoneNumber={phoneNumber}
            formattedNumber={formattedNumber}
            favoritesFeatureFlag={favoritesFeatureFlag}
            notificationSettingsFeatureFlag={notificationSettingsFeatureFlag}
            myHomeFeatureFlag={myHomeFeatureFlag}
            {...signedInProps}
          />
        </PageHeader>
      );
  }
}

GlobalHeader.propTypes = {
  lightNav: PropTypes.bool,
  logoUrl: PropTypes.string,
  addClass: PropTypes.string,
  signInPath: PropTypes.string,
  signedInProps: PropTypes.any,
  partnerLogoUrl: PropTypes.string,
  partnerLogoWidth: PropTypes.string,
  partnerPhone: PropTypes.string,
  favoritesFeatureFlag: PropTypes.bool,
  notificationSettingsFeatureFlag: PropTypes.bool,
  myHomeFeatureFlag: PropTypes.bool
};

GlobalHeader.defaultProps = {
  lightNav: true,
  logoUrl: '',
  addClass: '',
  signInPath: '',
  signedInProps: {},
  partnerLogoUrl: '',
  partnerLogoWidth: '',
  partnerPhone: '',
  favoritesFeatureFlag: false,
  notificationSettingsFeatureFlag: false,
  myHomeFeatureFlag: false
};


export default GlobalHeader
