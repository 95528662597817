import React from 'react';
import PropTypes from 'prop-types';

const StarRatingIcon = ({type, fill}) => {
  const full = (
    <svg width="18" height="16" viewBox="0 0 18 16">
      <path fill={fill} d="M8.45573 0.556577L6.41536 4.69357L1.8503 5.35911C1.03165 5.47784 0.703566 6.48709 1.29724 7.06515L4.59996 10.2835L3.81881 14.8298C3.6782 15.6516 4.54372 16.2671 5.26863 15.8828L9.3525 13.7362L13.4364 15.8828C14.1613 16.264 15.0268 15.6516 14.8862 14.8298L14.105 10.2835L17.4078 7.06515C18.0014 6.48709 17.6733 5.47784 16.8547 5.35911L12.2896 4.69357L10.2493 0.556577C9.88368 -0.180831 8.82444 -0.190205 8.45573 0.556577Z" />
    </svg>
  );

  const half = (
    <svg width="18px" height="18px" viewBox="0 0 24 22.97">
      <path fill="#EEF0F6" d="M10.71,1.3,7.78,7.24l-6.55.95a1.43,1.43,0,0,0-.79,2.45l4.74,4.62L4.06,21.79A1.43,1.43,0,0,0,6.14,23.3L12,20.22l5.86,3.08a1.43,1.43,0,0,0,2.08-1.51l-1.12-6.53,4.74-4.62a1.43,1.43,0,0,0-.79-2.45l-6.55-.95L13.29,1.3A1.44,1.44,0,0,0,10.71,1.3Z" transform="translate(0 -0.5)"/>
      <path fill={fill} d="M10.71,1.3,7.78,7.24l-6.55.95a1.43,1.43,0,0,0-.79,2.45l4.74,4.62L4.06,21.79A1.43,1.43,0,0,0,6.14,23.3L12,20.22V.5A1.42,1.42,0,0,0,10.71,1.3Z" transform="translate(0 -0.5)"/>
    </svg>
  );

  const empty = (
    <svg width="18" height="16" viewBox="0 0 18 16">
      <path fill={fill} d="M8.45573 0.556577L6.41536 4.69357L1.8503 5.35911C1.03165 5.47784 0.703566 6.48709 1.29724 7.06515L4.59996 10.2835L3.81881 14.8298C3.6782 15.6516 4.54372 16.2671 5.26863 15.8828L9.3525 13.7362L13.4364 15.8828C14.1613 16.264 15.0268 15.6516 14.8862 14.8298L14.105 10.2835L17.4078 7.06515C18.0014 6.48709 17.6733 5.47784 16.8547 5.35911L12.2896 4.69357L10.2493 0.556577C9.88368 -0.180831 8.82444 -0.190205 8.45573 0.556577Z" />
    </svg>
  );

  return { full, half, empty }[type];
}

StarRatingIcon.propTypes = {
  type: PropTypes.string,
  fill: PropTypes.string
};

StarRatingIcon.defaultProps = {
  type: 'full',
  fill: '#FFA53B'
}

export default StarRatingIcon
