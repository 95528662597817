import React from "react";

class PresentableString extends React.Component {

  render () {
    let string = this.props.string || "";
    let placeholder = this.props.placeholder === false ? '' : this.props.placeholder || '[ link omitted ]';
    let qualifiedLinksRemoved = string.replace(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig, placeholder);
    let obviousLinksRemoved = qualifiedLinksRemoved.replace(/(www\..*\.(com|org|net|edu))|(.*\.(com|org|net|edu))|(\w* dot( *)(com|org|net|edu))/gi, placeholder);
    let output = obviousLinksRemoved.replace(/[^\x20-\x7E]+/g, ""); // removes non printable characters

    return (
      <React.Fragment>
        {output}
      </React.Fragment>
    );
  }
}

export default PresentableString;
