import React, {useState, useRef, useCallback} from "react";
import loadable from '@loadable/component'
import useLoadOnVisibility from '../../../hooks/useLoadOnVisibility'

const LazyProfileAchievements = (props) => {
  const targetEl = useRef(null);
  const [Element, setElement] = useState(null);

  const mountElement = useCallback(() => {
    const Element = loadable(() => import('../../profiles/ProfileAchievements'))
    setElement(Element);
  }, []);

  const { loaded } = useLoadOnVisibility(targetEl, mountElement);

  return (
    <div ref={targetEl}>
      {loaded && <Element {...props} />}
    </div>
  )
};

export default LazyProfileAchievements;
