import React from "react";
import PropTypes from "prop-types";
import VerifyTwilioSecurityCode from "./VerifyTwilioSecurityCode";

class ChooseVerificationMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      methodChosen: null
    };
    this.tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
    this.beginVerification = this.beginVerification.bind(this);
    this.onUnload = this.onUnload.bind(this);
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  onUnload () {
    fetch("/leads/record-duplicate-pii-sales-event", {
      method: 'POST',
      body: JSON.stringify({ event: 'duplicate_pii_lead_never_verified', lead_token: this.props.leadToken }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
  }

  beginVerification(method) {
    if (method === 'email') {
      this.tracking.trackEvent('QuizFlow', 'Match', 'Email_Selected');
    } else {
      this.tracking.trackEvent('QuizFlow', 'Match', 'SMS_Selected');
    }
    this.setState({ methodChosen: method })
  }

  render () {
    const { protectedEmail, protectedPhone, phone, email, onVerify, piiIndexStep, leadToken } = this.props;
    const { methodChosen } = this.state;
    if (methodChosen) {
      const heading = `We just sent you a code`
      const protectedAddress = methodChosen === 'email' ? protectedEmail : protectedPhone
      return (
        <VerifyTwilioSecurityCode
          leadToken={leadToken}
          heading={heading}
          phone={phone}
          email={email}
          channel={methodChosen}
          description={`For your security, we sent a 6-digit code to <${protectedAddress}>. Please enter the code below to continue.`}
          onVerify={onVerify}
          piiIndexStep={piiIndexStep}
        />
      )
    } else {
      return (
        <div id='verify-identity'>
          <h3>This email and phone number already exist in our system</h3>
          <p>
            {`For your security, we'll send you a 6-digit code to confirm your identity.
            Where should we send the code?`}
          </p>
          <div className='input-group security-code'>
            <div className='actions'>
              <a className='button next-button' onClick={() => this.beginVerification('email')} >
                { `Email ${protectedEmail}` }
              </a>
              <a className='button next-button' onClick={() => this.beginVerification('sms')} >
                { `Text message ${protectedPhone}` }
              </a>
            </div>
          </div>

        </div>
      )
    }
  }
}

ChooseVerificationMethod.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  protectedEmail: PropTypes.string,
  protectedPhone: PropTypes.string,
  onVerify: PropTypes.func.isRequired,
  piiIndexStep: PropTypes.number.isRequired
}

ChooseVerificationMethod.defaultProps = {
  phone: null,
  email: null,
  protectedEmail: null,
  protectedPhone: null
}

export default ChooseVerificationMethod;
