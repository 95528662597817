import React from 'react'
import PropTypes from 'prop-types'

const PhoneNumber = ({phone}) => (
  <div className="phone-number">
    <a href={`tel:${phone}`}>
      {phone}
    </a>
  </div>
)

PhoneNumber.propTypes = {
  phone: PropTypes.string.isRequired
}

export default PhoneNumber
