import React from "react";

import LaptopBricon from "../icons/LaptopBricon";
import CamcorderBricon from "../icons/CamcorderBricon";
import HandsWashingBricon from "../icons/HandsWashingBricon";
import SignedContractBricon from "../icons/SignedContractBricon";
import FileWithCheckmarkBricon from "../icons/FileWithCheckmarkBricon";

const MoveSafeDescription = (props) => (
  <div className='move-safe-description'>
    <div className="move-safe-desc-item">
      <div className='move-safe-icon'>
        <LaptopBricon />
      </div>
      <p className="move-safe-desc-text">
        Meets with clients virtually
      </p>
    </div>
    <div className="move-safe-desc-item">
      <div className='move-safe-icon'>
        <CamcorderBricon />
      </div>
      <p className="move-safe-desc-text">
        Strengthens the digital presence of listed homes through photography, dedicated websites, video tours, 3D tours, aerial photography, floorplans, and virtual staging
      </p>
    </div>
    <div className="move-safe-desc-item">
      <div className='move-safe-icon'>
        <HandsWashingBricon />
      </div>
      <p className="move-safe-desc-text">
        Has implemented safety measures for showings including ensuring the house is clean for showings, arriving before the client to open all doors and windows, adhering to social distancing protocol, and limiting showings to vetted buyers
      </p>
    </div>
    <div className="move-safe-desc-item">
      <div className='move-safe-icon'>
        <SignedContractBricon />
      </div>
      <p className="move-safe-desc-text">
        Uses an e-sign platform for disclosures, offers and counter offers, and closings
      </p>
    </div>
    <div className="move-safe-desc-item">
      <div className='move-safe-icon'>
        <FileWithCheckmarkBricon />
      </div>
      <p className="move-safe-desc-text">
        Includes a COVID-19 addendum in purchase agreements
      </p>
    </div>
  </div>
);

export default MoveSafeDescription;
