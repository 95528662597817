import React from "react";
import LoadingSpinner from "../global/LoadingSpinner";
import MapboxPlaceAutocomplete from "../global/MapboxPlaceAutocomplete";
import SearchIcon from "../icons/SearchIcon";
import AgentTransactionMap from "./AgentTransactionMap";
import '../../styles/components/agents/AgentAreaTransactions.scss';

class AgentAreaTransactions extends React.Component {

  constructor (props) {
    super(props);
    let transactions = props.transactions || [];
    let areas = [];
    let yearlyData = [];
    let activeFilters = {
      city: [],
      year: []
    };

    this.state = {
      transactions,
      areas,
      yearlyData,
      activeFilters,
      mapsLoading: true,
      currentlyShowing: transactions.length
    }
  }

  componentDidMount () {
    let transactions = this.normalizedTransactions();
    this.setState({
      normalizedTransactions: transactions,
      filteredTransactions:   transactions
    })
    this.setAreas(transactions);
    this.setYearlyData(transactions);
    PubSub.sub('AgentTransactionMap--maps-loaded', () => this.setState({mapsLoading: false}));
    PubSub.sub('AgentTransactionMap--maps-unavailable', () => this.setState({mapsUnavailable: true}));
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  normalizedTransactions () {
    return lodash.uniqBy(this.state.transactions, tx => `${tx.lng}${tx.lat}`).filter(tx => tx.lng && tx.lat);
  }

  setAreas (transactions) {
    let transactionsByCity = lodash.groupBy(transactions, 'city');
    let txAreas     = Object.keys(transactionsByCity);
    let areaCounts  = txAreas.map(key => ({city: key, count: transactionsByCity[key].length }));
    let areas       = areaCounts.sort( (a,b) => (a.count < b.count ? 1 : -1)).slice(0,6);
    this.setState({ areas });
  }

  calculateYearlyData(transactions) {
    const transactionsByYear    = lodash.groupBy(transactions, 'year');
    const txYears               = Object.keys(transactionsByYear);
    const yearlyCounts          = txYears.map(key => ({year: key, count: transactionsByYear[key].length }));
    const yearlyDataSortedDes   = yearlyCounts.sort((a, b) => (a.year < b.year ? 1 : -1));

    const yearlyData            = yearlyDataSortedDes.slice(0, 6);
    const priorYears            = yearlyDataSortedDes.slice(6, yearlyDataSortedDes.length);

    return [yearlyData, priorYears];
  }

  setYearlyData (transactions) {
    const [yearlyData, priorYears] = this.calculateYearlyData(transactions)

    this.setState({ yearlyData });
    if (priorYears.length > 0) {
      this.setState({
        priorYears: {
          label: `Prior to ${yearlyData.slice(-1)[0].year}`,
          years: priorYears.map(obj => obj.year),
          count: priorYears.reduce((tot, year) => (tot + year.count), 0)
        }
      })
    }
  }

  classNameForFilterButton (type, value) {
    const selected  = 'tx-category--selected';
    let isActive    = this.filterIsActive({type, value});
    return isActive ? selected : '';
  }

  handleNewLocation (data) {

    let img = document.createElement('img');
    let marker;
    let coords;
    let existingMarker;

    img.src           = '/pin.png';
    img.style.width   = '19.5px';
    img.style.height  = '31.5px';
    img.style.cursor  = 'pointer';

    try {

      marker = new mapboxgl.Marker(img);
      coords = [data.lng, data.lat];
      existingMarker = this.state.addressMarker;

      PubSub.emit('AgentTransactionMap--add-marker', {
        marker,
        coords,
        popup: {
          line1: data.formatted_address
        }
      });

      if (existingMarker) {
        existingMarker.remove();
      }

      this.setState({addressMarker: marker});
      this.panToClosestTransactions(coords);
      this.trackEvent('Interaction', 'address_provided', 'agent_profile_transactions_near_you', 1);

    } catch (err) { console.error(err); }
  }

  panToClosestTransactions (coords) {

    let point = { point: 0, coords };
    let points = this.state.filteredTransactions
      .map(tx => ({ point: GeoLib.haversineDistance([tx.lng, tx.lat], coords), coords: [tx.lng, tx.lat], desc: tx.address }))
      .concat(point)
      .sort((a,b) => a.point > b.point ? 1 : -1)

    let closestPoints = points.slice(0, 5);
    let bounds        = new mapboxgl.LngLatBounds();

    closestPoints.forEach(x => bounds.extend(x.coords));
    PubSub.emit('AgentTransactionMap--fit-bounds', { bounds });
  }

  filterMapBy (type, value) {
    let { activeFilters } = this.state;
    let filter        = {type, value};
    let active        = this.filterIsActive(filter);
    let bounds        = new mapboxgl.LngLatBounds();
    let transactions  = this.state.normalizedTransactions;
    let groupKeys;

    activeFilters[type] = activeFilters[type] || [];

    if (active) {
      const filterFunc = Array.isArray(value) ? (x => value.includes(x)) : (x => x === value);
      lodash.pullAll(activeFilters[type], activeFilters[type].filter(filterFunc));
    } else {
      if (Array.isArray(value)) {
        activeFilters[type].push(...value);
      } else {
        activeFilters[type].push(value);
      }
    }

    groupKeys = Object.keys(activeFilters);

    for (let i =0; i < groupKeys.length; i +=1) {
      let objectKey = groupKeys[i];
      let group     = activeFilters[groupKeys[i]];
      if (group.length) {
        transactions = transactions.filter(tx => group.indexOf(tx[objectKey]) > -1);
      }
    }

    if (active) {
      this.deactivateFilter(filter);
    } else {
      this.activateFilter(filter);
    }

    PubSub.emit('AgentTransactionMap--filter', { transactions });

    if (transactions.length) {
      transactions.forEach(tx => bounds.extend([tx.lng, tx.lat]));
      PubSub.emit('AgentTransactionMap--fit-bounds', { bounds });
    } else {
      PubSub.emit('AgentTransactionMap--zoom', 11);
    }

    this.setState({
      currentlyShowing: transactions.length,
      filteredTransactions: transactions
    });

    this.trackEvent('Interaction', `filter_map_by_${type}`, 'agent_profile_transactions', 1);
  }

  filterIsActive (filter) {
    let { activeFilters } = this.state;
    activeFilters[filter.type] = activeFilters[filter.type] || [];

    if (Array.isArray(filter.value)) {
      return !!activeFilters[filter.type].filter( x => filter.value.includes(x)).length;
    }

    return !!activeFilters[filter.type].filter( x => x === filter.value).length;
  }

  anyYearFilterActive () {
    return this.state.activeFilters.year.length > 0;
  }

  anyCityFilterActive () {
    return this.state.activeFilters.city.length > 0;
  }

  clearFilter (type) {
    let { activeFilters } = this.state;
    activeFilters[type] = [];
    this.setState({ activeFilters });
    this.filterMapBy();
  }

  activateFilter (filter) {
    let { activeFilters } = this.state;
    activeFilters[filter.type] = activeFilters[filter.type] || [];
    if (Array.isArray(filter.value)) {
      activeFilters[filter.type].push(...filter.value);
    } else {
      activeFilters[filter.type].push(filter.value);
    }
    this.setState({ activeFilters });
  }

  deactivateFilter (filter) {
    let { activeFilters } = this.state;
    let activeInstances;
    activeFilters[filter.type] = activeFilters[filter.type] || [];

    const filterFunc = Array.isArray(filter.value) ? (x => filter.value.includes(x)) : (x => x === filter.value);
    activeInstances = activeFilters[filter.type].filter(filterFunc);

    lodash.pullAll(activeFilters[filter.type], activeInstances);
    this.setState({activeFilters: this.state.activeFilters})
  }

  getCity(area) {
    return (
      <li className={`tx-category ${this.classNameForFilterButton('city', area.city)}`} key={area.city} onClick={() => this.filterMapBy('city', area.city)}>
        <span className="name">{area.city}</span>
        <span className="count">({area.count})</span>
      </li>
    );
  }

  getCities() {
     return this.state.areas.reduce((cities, area) => {
      if (area.city && area.city !== "null") {
        cities.push(this.getCity(area));
      }

      return cities;
    }, []);
  }

  renderPriorYears() {
    const { priorYears } = this.state;
    if (!priorYears)
      return null;

    return(
      <li className={`prior-years tx-category ${this.classNameForFilterButton('year', priorYears.years.map(x => Number(x)))}`} key={priorYears.years[0]} onClick={() => this.filterMapBy('year', priorYears.years.map(x => Number(x)))}>
        <span className="name">{priorYears.label}</span>
        <span className="count">({priorYears.count})</span>
      </li>
    );
  }

  render () {

    if (this.state.mapsUnavailable) {
      return <div className={`agent-area-transactions-component unavailable ${this.props.addClass || ""}`}></div>
    }
    const cities = this.getCities();

    return (
      <React.Fragment>
        <div className={`agent-area-transactions-component ${this.props.addClass || ""}`}>
          { this.state.mapsLoading && <div className="loader"><LoadingSpinner/></div> }
          <div className={this.state.mapsLoading ? 'loading' : ''}>
            <div className="tx-control">
              <div className="tx-nearby">
                <label>Transactions near you</label>
                <div className="row">
                  <MapboxPlaceAutocomplete id="agent-area-tx-home-search" addClass="col-xs-12 no-pad" inputClass="address-input" placeholder="Your Address" onSelect={ data => this.handleNewLocation(data) }/>
                  <a className="button button-clear"><SearchIcon/></a>
                </div>
              </div>

              <div className="tx-group">
                <label>
                  Transactions by City
                  {
                    this.anyCityFilterActive() && <a className='clear-filter' onClick={() => this.clearFilter('city')}>view all</a>
                  }
                </label>
                <ul className="tx-cities">
                  { cities }
                </ul>
              </div>

              <div className="tx-group">
                <label>
                  Transactions by Year
                  {
                    this.anyYearFilterActive() && <a className='clear-filter' onClick={() => this.clearFilter('year')}>view all</a>
                  }
                </label>
                <ul className="tx-years">
                  {
                    this.state.yearlyData.map(year => (
                      <li className={`tx-category ${this.classNameForFilterButton('year', Number(year.year))}`} key={year.year} onClick={() => this.filterMapBy('year', Number(year.year))}>
                        <div>
                          <span className="name">{year.year}</span>
                          <span className="count">({year.count})</span>
                        </div>
                      </li>
                    ))
                  }
                  { this.renderPriorYears() }
                </ul>
              </div>
            </div>

            <div className="tx-map">
              <AgentTransactionMap transactions={this.state.transactions}/>
              <div className="tx-count">
                showing {this.state.currentlyShowing} {this.state.currentlyShowing === 1 ? 'transaction' : 'transactions'}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default AgentAreaTransactions;
