import React from "react";
import PropTypes from "prop-types";
import QuizIcons from "./QuizIcons"

class ButtonQuestion extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      selectedAnswer: '',
    }
  }

  getIcon (icon) {
    return QuizIcons[icon] ? QuizIcons[icon] : '';
  }

  onSelectAnswer(option, newQuizStyles) {
    if (newQuizStyles) {
      this.setState({ selectedAnswer: option.value }, () => {
        // allow the checkmark to render for selected answer before moving to the next question
        setTimeout(() => {
          this.props.answerQuestion(option);
        }, 300);
      });
    } else {
      this.props.answerQuestion(option);
    }
  }

  render () {
    const { question, answers, completeDynamicText, newQuizStyles } = this.props;

    // Ensure selected answer is always in selected state (eg in case user goes back a few steps in the quiz)
    let selectedAnswer = this.state.selectedAnswer || answers[question.value];
    let outline;
    let buttonStyle;
    let buttonClasses;

    if (newQuizStyles) {
      buttonStyle = 'conversational';
      if (question.options.length > 2) {
       outline = 'button-no-outline';
       buttonClasses = outline;
      } else {
        // Yes/No questions
        outline = 'button-outline';
        buttonClasses = `button ${outline}`;
      }
    } else {
      buttonStyle = '';
      outline = 'button-outline';
      buttonClasses = `button ${outline}`;
    }

    if (!question.options) {
      return null
    } else {
      return question.options.map( (option) => {
        const text = completeDynamicText(option.text);
        let key = text.replace(/\s+/g, '_').toLowerCase();
        // snake-case button classnames
        let selectedAnswerClassName = option.value ? option.value.toString().replace(/[^A-Z0-9]+/ig, '-').toLowerCase() : '';

        return (
          <div
            className={`${buttonStyle} button-container-${outline}`}
            key={key}
            onClick={this.onSelectAnswer.bind(this, option, newQuizStyles)}
          >
            <a className={`${selectedAnswer === option.value ? 'selected' : ''} ${buttonStyle} ${buttonClasses} answer-${selectedAnswerClassName}`}>
              {newQuizStyles && buttonClasses === 'button-no-outline' && <CheckIcon />}
              {!newQuizStyles && option.icon && this.getIcon(option.icon)}
              {text}
            </a>
          </div>
        );
      })
    }
  }
}

ButtonQuestion.propTypes = {
  question: PropTypes.shape({}).isRequired,
  answerQuestion: PropTypes.func.isRequired,
  answers: PropTypes.shape({}).isRequired,
  completeDynamicText: PropTypes.func.isRequired,
  newQuizStyles: PropTypes.bool.isRequired
};

export default ButtonQuestion;
