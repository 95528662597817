import React from "react";

class MarkerIcon extends React.Component {

  render () {
    return (
      <svg viewBox="0 0 16 21" className="marker-icon">
        <path d="M7.21873398,20.10937 C6.54165246,19.1197892 5.66925831,17.8697922 4.60155272,16.3593789 C3.29947269,14.4843834 2.44009974,13.2213658 2.02343387,12.5703254 C1.42447716,11.6328277 1.02083189,10.8450689 0.812499255,10.2070498 C0.604166617,9.56903072 0.5,8.83335499 0.5,8.00002384 C0.5,6.64586021 0.838541058,5.39586319 1.51562258,4.25003278 C2.1927041,3.10420238 3.10416066,2.19274582 4.24999106,1.5156643 C5.39582146,0.838582781 6.64581848,0.500041723 7.99998212,0.500041723 C9.35414576,0.500041723 10.6041428,0.838582781 11.7499732,1.5156643 C12.8958036,2.19274582 13.8072601,3.10420238 14.4843417,4.25003278 C15.1614232,5.39586319 15.4999642,6.64586021 15.4999642,8.00002384 C15.4999642,8.83335499 15.3957976,9.56903072 15.187465,10.2070498 C14.9791323,10.8450689 14.5754871,11.6328277 13.9765304,12.5703254 C13.5598645,13.2213658 12.7004915,14.4843834 11.3984115,16.3593789 L8.78123026,20.10937 C8.59893883,20.3697862 8.33852318,20.499994 7.99998212,20.499994 C7.66144106,20.499994 7.40102541,20.3697862 7.21873398,20.10937 Z M7.99998212,11.1250164 C8.85935507,11.1250164 9.5950302,10.8190277 10.2070081,10.2070498 C10.818986,9.59507192 11.1249747,8.85939679 11.1249747,8.00002384 C11.1249747,7.14065089 10.818986,6.40497576 10.2070081,5.79299785 C9.5950302,5.18101995 8.85935507,4.87503129 7.99998212,4.87503129 C7.14060917,4.87503129 6.40493404,5.18101995 5.79295613,5.79299785 C5.18097822,6.40497576 4.87498957,7.14065089 4.87498957,8.00002384 C4.87498957,8.85939679 5.18097822,9.59507192 5.79295613,10.2070498 C6.40493404,10.8190277 7.14060917,11.1250164 7.99998212,11.1250164 Z"></path>
      </svg>
    );
  }
}

export default MarkerIcon;
