import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../global/Tooltip";
import StarRatingLargeIcon from "../icons/StarRatingLargeIcon";
import '../../styles/components/global/StarRater.scss';

class StarRater extends React.Component {

  constructor (props) {
    super();
    this.state = { value: props.value, activeTooltip: null };

    this.onChange = this.onChange.bind(this);
    this.onStarClick = this.onStarClick.bind(this);
    this.onStarHover = this.onStarHover.bind(this);
    this.onStarHoverOut = this.onStarHoverOut.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    const { value } = nextProps;
    if (value != null && (value !== this.state.value)) {
      this.setState({ value });
    }
  }

  onChange (inputValue) {
    const { editing, value } = this.props;

    if (!editing) {
      return;
    }

    this.setState({value: inputValue});
  }

  onStarClick (index, value, name, e) {
    e.stopPropagation();

    const { onStarClick, editing } = this.props;

    if (!editing) {
      return;
    }

    if (index !== this.props.value) {
      onStarClick && onStarClick(index, value, name, e);
    }
  }

  onStarHover (index, value, name, e) {
    e.stopPropagation();

    const { onStarHover, editing } = this.props;

    if (!editing) {
      return;
    }

    onStarHover && onStarHover(index, value, name, e);
    this.setState({ activeTooltip: index });
  }

  onStarHoverOut (index, value, name, e) {
    e.stopPropagation();

    const { onStarHoverOut, editing } = this.props;

    if (!editing) {
      return;
    }

    onStarHoverOut && onStarHoverOut(index, value, name, e);
    this.setState({ activeTooltip: null });
  }

  renderStars () {
    const {
      name,
      starCount,
      starColor,
      emptyStarColor,
      editing,
      tooltips
    } = this.props;
    const { value } = this.state;

    const starStyles = (i, value) => ({
      float: 'right',
      cursor: editing ? 'pointer' : 'default',
      color: value >= i ? starColor : emptyStarColor
    });
    const sizeStyle = this.props.size ? { height: `${this.props.size}rem` } : {};

    const radioStyles = {
      display: 'none',
      position: 'absolute',
      marginLeft: -9999
    };

    // populate stars
    let starNodes = [];

    for (let i = starCount; i > 0; i--) {
      const id = `${name}_${i}`;
      let labelClasses = ['star-rater-star'];
      labelClasses.push(value >= i ? 'star-rater-full-star' : 'star-rater-empty-star');
      if (value === i) {
        labelClasses.push('star-selected');
      }

      const tooltip = tooltips && tooltips[i - 1] ? (
        <Tooltip text={ tooltips[i - 1] } isVisible={ this.state.activeTooltip === i && this.state.activeTooltip !== null } />
      ) : null;

      const starNodeInput = (
        <input
          key={`input_${id}`}
          style={radioStyles}
          className="star-rater-input"
          type="radio"
          name={name}
          id={id}
          value={i}
          checked={value === i}
          onChange={this.onChange.bind(this, i, name)}
        />
      );

      const starNodeLabel = (
        <label
          key={`label_${id}`}
          style={{...starStyles(i, value), ...sizeStyle}}
          className={labelClasses.join(' ')}
          htmlFor={id}
          onClick={e => this.onStarClick(i, value, name, e)}
          onTouchStart={e => this.onStarClick(i, value, name, e)}
          onMouseOver={e => this.onStarHover(i, value, name, e)}
          onMouseLeave={e => this.onStarHoverOut(i, value, name, e)}
        >
          { tooltip }
          { this.renderIcon(i, value, name, id) }
        </label>
      );

      starNodes.push(starNodeInput);
      starNodes.push(starNodeLabel);
    }

    return starNodes.length ? starNodes : null;
  }

  renderIcon (index, value, name, id) {
    const { renderStarIcon, renderStarIconHalf, size, starColor } = this.props;

    if (
      typeof renderStarIconHalf === 'function' &&
      Math.ceil(value) === index &&
      value % 1 !== 0
    ) {
      return renderStarIconHalf(index, value, name, id);
    }

    if (typeof renderStarIcon === 'function') {
      return renderStarIcon(index, value, name, id);
    }

    return <StarRatingLargeIcon fill={ starColor }/>
  }

  render () {
    const { editing, className } = this.props;
    const classes = `star-rater ${(!editing && 'star-rater-non-editable' ) || ''}`
    return (
      <div style={{position: 'relative'}} className={classes}>
        { this.renderStars() }
      </div>
    );
  }
}

StarRater.propTypes = {
  name              : PropTypes.string.isRequired,
  value             : PropTypes.number,
  editing           : PropTypes.bool,
  starCount         : PropTypes.number,
  starColor         : PropTypes.string,
  onStarClick       : PropTypes.func,
  onStarHover       : PropTypes.func,
  onStarHoverOut    : PropTypes.func,
  renderStarIcon    : PropTypes.func,
  renderStarIconHalf: PropTypes.func,
  size              : PropTypes.number,
  tooltips          : PropTypes.arrayOf(PropTypes.string)
};

StarRater.defaultProps = {
  starCount     : 5,
  editing       : true,
  starColor     : '#FFA53B',
  emptyStarColor: '#CCC',
  size          : 1.6
};

export default StarRater;
