import React from "react";
import ChevronIcon from "../icons/ChevronIcon";
import FlagIcon from "../icons/FlagIcon";
import ArrayPager from "../global/ArrayPager";
import '../../styles/components/agents/AgentAllTransactions.scss';

class AgentAllTransactions extends React.Component {

  constructor (props) {
    super(props);

    let sorted = props.all || [];
    let pageSize = 7;
    let i = 0;
    let transactionInfoHidden  = false;
    let transactionPriceHidden = false;

    for (i; i < sorted.length; i += 1) {
      transactionInfoHidden  = transactionInfoHidden  || sorted[i].transaction_info_hidden;
      transactionPriceHidden = transactionPriceHidden || sorted[i].transaction_price_hidden;
      if (transactionInfoHidden && transactionPriceHidden) {
        break;
      }
    }

    this.state = {
      all: sorted,
      sorted,
      currentSortKey: 'date_sort_value',
      sortDirection: 'desc',
      currentPage: 0,
      pageSize,
      paginated: sorted.slice(0, pageSize),
      totalPages: this.calculatePages(sorted, pageSize),
      transactionInfoHidden,
      transactionPriceHidden
    }
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value);
    } catch (err) { console.error(err) }
  }

  handlePageChanged (page) {
    this.setState({
      currentPage: page,
      paginated: this.paginate(this.state.pageSize, page)
    })
    this.trackEvent('Interaction', 'paginate', 'agent_profile_transactions', page);
  }

  calculatePages (transactions, pageSize) {
    return Math.ceil(transactions.length / pageSize);
  }

  sortBy (currentSortKey) {
    let oppositeDir = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
    let sortDirection = this.state.currentSortKey === currentSortKey ? oppositeDir : 'desc';
    let sorted = lodash.orderBy(this.state.all, currentSortKey, sortDirection);
    let newState = {
      currentSortKey,
      sortDirection,
      currentPage: 0,
      sorted,
      paginated: this.paginate(this.state.pageSize, 0, sorted)
    };

    this.setState(newState);
    this.trackEvent('Interaction', 'sort', 'agent_profile_transactions', 1);
  }

  paginate (pageSize, pageNumber, data) {
    return (data || this.state.sorted).slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  conditionalLink(child, url) {
    if (url) {
      return <a href={url}>{child}</a>;
    }
    return child;
  }

  render () {
    return (
      <React.Fragment>

        <div className={`agent-all-transactions-component ${this.props.addClass || ""}`}>
          <ul className="table-headers">
            <div className="inline table-left">
              <li className={`headline inline location ${this.state.currentSortKey == 'city' ? "active " + this.state.sortDirection : ""}`} onClick={() => { this.sortBy('city') }}>
                <label>
                  <span>Location</span>
                  <ChevronIcon direction={this.state.sortDirection == 'asc' ? 'up' : ''}/>
                </label>
              </li>
            </div>
            <div className="inline table-right">
              <li className={`headline inline property ${this.state.currentSortKey == 'propertyType' ? "active " + this.state.sortDirection : ""}`} onClick={() => { this.sortBy('propertyType') }}>
                <label>
                  <span>Property Details</span>
                  <ChevronIcon direction={this.state.sortDirection == 'asc' ? 'up' : ''}/>
                </label>
              </li>
              <li className={`headline inline represented ${this.state.currentSortKey == 'represented' ? "active " + this.state.sortDirection : ""}`} onClick={() => this.sortBy('represented')}>
                <label>
                  <span>Side</span>
                  <ChevronIcon direction={this.state.sortDirection == 'asc' ? 'up' : ''}/>
                </label>
              </li>
              { !this.state.transactionPriceHidden && (
                <li className={`headline inline price ${this.state.currentSortKey == 'price_sort_value' ? "active " + this.state.sortDirection : ""}`} onClick={() => this.sortBy('price_sort_value')}>
                  <label>
                    <span>Price</span>
                    <ChevronIcon direction={this.state.sortDirection == 'asc' ? 'up' : ''}/>
                  </label>
                </li>
              )}
              <li className={`headline inline date${this.state.transactionPriceHidden && ' wide' || ''} ${this.state.currentSortKey == 'date_sort_value' ? "active " + this.state.sortDirection : ""}`} onClick={() => this.sortBy('date_sort_value')}>
                <label>
                  <span>Sold Date</span>
                  <ChevronIcon direction={this.state.sortDirection == 'asc' ? 'up' : ''}/>
                </label>
              </li>
            </div>
          </ul>

          <ul className="table-data">
            {
              this.state.paginated.map( (tx, i) => {
                return (
                  <li className="tx" key={`all-tx-${i}`}>
                    <div className="inline table-left">
                      {
                        this.state.transactionInfoHidden ? (
                          <div className="primary address">{tx.city}, {tx.state}</div>
                        ) : (
                          <React.Fragment>
                            {this.conditionalLink(
                              <div className="primary address">{tx.address}</div>,
                              tx.property_url
                            )}
                            {this.conditionalLink(
                              <div className="secondary city">{tx.city}</div>,
                              tx.city_properties_url
                            )}
                          </React.Fragment>
                        )
                      }
                    </div>
                    <div className="inline table-right">
                      <div className="inline property">
                        <div className="type">{tx.propertyType}</div>
                        <div className="secondary details">{tx.propertyDetails}</div>
                      </div>
                      <div className="inline represented">{tx.represented}</div>
                      { !this.state.transactionPriceHidden && ( <div className="inline price">{tx.price}</div> )}
                      <div className={`inline date${this.state.transactionPriceHidden && ' wide' || ''}`}>
                        { tx.formatted_date }
                      </div>
                    </div>
                    {
                      (() => {
                        if (tx.flag) {
                          return (
                            <div className="tx-flag">
                              <FlagIcon/>
                              <span>{tx.flag_message}</span>
                            </div>
                          )
                        }
                      })()
                    }
                  </li>
                )
              })
            }
          </ul>
          {
            this.state.paginated.length &&
            this.state.all.length > this.state.pageSize &&
            <ArrayPager
              showFirstAndLastLinks={false}
              current={this.state.currentPage}
              total={this.state.totalPages}
              onPageChanged={this.handlePageChanged.bind(this)}
              visiblePages={4}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default AgentAllTransactions;
