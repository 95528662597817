import React from "react";
import httpService from "../services/http_service"
import tracking from "../services/tracking_service"
import { cookies } from "../services/storage_service.js"

import PhoneInput from "../global/PhoneInput";
import MapboxPlaceAutocomplete from "../global/MapboxPlaceAutocomplete";
import CurrencyInput from "../global/CurrencyInput";
import ButtonQuestion from "../qaas/ButtonQuestion";
import MultiselectQuestion from "../qaas/MultiselectQuestion";
import AgentAutocomplete from "../qaas/AgentAutocomplete";
import StarRater from "../global/StarRater";
import LoadingSpinner from "../global/LoadingSpinner";
import DropdownQuestion from '../qaas/DropdownQuestion';
import MultistepConfirmLocation from "../qaas/MultistepConfirmLocation";
import ConfirmLocation from "../qaas/ConfirmLocation";
import VerifyPhone from "../qaas/VerifyPhone";
import SignupSignin from "../client/SignupSignin";
import Interstitial from "../qaas/Interstitial";
import CalendlyQuizStep from "../qaas/CalendlyQuizStep";
import PropertyTypeInterstitial from "../qaas/PropertyTypeInterstitial";
import AdditionalQuestionInterstitial from "../qaas/AdditionalQuestionInterstitial";
import VerifyIdentity from "../qaas/VerifyIdentity";
import '../../styles/components/global/LoadingSpinner.scss';
import '../../styles/components/global/QuizController.scss';

const PARAMETER = 'qaas';

const ACTIVE_STEP_TO_GA_EVENT_MAP = {
  additional_services: 'IndicateWantsOtherTransactionSide',
  agent_autocomplete_select: 'AgentAutocompleteSelect',
  customer_price_estimate: 'SellPriceEstimate',
  phone_email: 'Email_Phone',
  property_type: 'PropertyType',
  phone: 'PhoneNumber',
  price: 'SellPriceBucket',
  confirm_location: 'AcceptAddressVerify',
  heard_about: 'UserReportedMarketing',
  timeline: "BuySellTimeline",
  year_built: "PropertyYearBuilt",
  condition: "PropertyCondition",
  previous_agent: "HasAgent",
  confirm_phone: "ConfirmedPhone",
  number_of_bedrooms: "Bedrooms",
  number_of_bathrooms: "Bathrooms",
  garage_size: "GarageSize",
  lot_size: "LotSize",
  square_feet: "SqFt",
  property_prefabricated: "PreFabricatedHome",
  property_age_restricted_community: "AgeRestrictedCommunity",
  property_gated_community: "GatedCommunity",
  property_unpermitted_addition: "UnpermittedAddition",
  property_significant_foundation_issues: "FoundationIssues",
  property_flood_zone: "FloodZone",
  property_occupancy: "OccupancyClosing",
  agent_property_value_guess: "SellPriceEstimate",
  name_phone_email: "NameEmailPhone",
  confirm_property_details: "investor_confirm_property_specs",
  confirm_occupant_at_closing: "investor_confirm_prop_occ",
  additional_property_details: "investor_confirm_prop_attrib",
  ineligible_location: "IneligibleLocation",
  calendly: "Calendly"
};

const TRACK_PAGE_VIEW_IDS = [
  "ineligible_location"
]

const HOMELIGHT_URL = process.env.HOMELIGHT_URL || ''

let validate;

class Disclaimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      span: null,
      tooltipContainer: null
    }

    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.tooltipContainer = this.tooltipContainer.bind(this);
    this.tooltipContent = this.tooltipContent.bind(this);
    this.initTooltip = this.initTooltip.bind(this);
    this.dismountTooltip = this.dismountTooltip.bind(this);
    this.setFinishButtonText = this.setFinishButtonText.bind(this);
    this.initEvents = this.initEvents.bind(this);
  }

  componentDidMount() {
    this.initTooltip();
  }

  componentWillUnmount() {
    this.dismountTooltip();
  }

  dismountTooltip() {
    const { span } = this.state;
    if (span === null)
      return;

    span.removeEventListener('mouseenter', this.showTooltip);
    span.removeEventListener('mouseleave', this.hideTooltip);
  }

  showTooltip() {
    const { tooltipContainer } = this.state;
    if (tooltipContainer === null)
      return;

    tooltipContainer.style["display"] = "inherit";
  }

  hideTooltip() {
    const { tooltipContainer } = this.state;
    if (tooltipContainer === null)
      return;

    tooltipContainer.style["display"] = "none";
  }

  tooltipContainer() {
    const tooltipContainer = document.createElement("div");
    tooltipContainer.setAttribute("id", "tooltip-container");

    return tooltipContainer;
  }

  tooltipContent() {
    const content = document.createElement("div");
    content.className += "tooltip-content";

    return content;
  }

  initEvents(span) {
    span.addEventListener('mouseenter', this.showTooltip);
    span.addEventListener('mouseleave', this.hideTooltip);
  }

  initTooltip() {
    // Tooltip has already been mounted
    if (!!document.getElementById("tooltip-container")) {
      return;
    }

    let span = document.getElementById("disclaimer-tooltip");
    if (span === null)
      return;

    let tooltipContainer = this.tooltipContainer();
    let tooltipContent = this.tooltipContent();
    tooltipContent.innerHTML = "Partners including SoFi. <br><br> Note, partner participation does not constitute an endorsement."

    tooltipContainer.appendChild(tooltipContent);
    span.prepend(tooltipContainer);

    this.setState({span: span, tooltipContainer: tooltipContainer});
    this.initEvents(span);
  }

  setFinishButtonText (text) {
    if (this.props.finish_btn_text) {
      return text.replace(/{{finish_btn_text(.*)}}/g, this.props.finish_btn_text);
    }
    return text.replace(/{{finish_btn_text \| (.*)}}/g, '$1');
  }

  render() {
    const { question } = this.props;
    let disclaimerRef = question.disclaimer_required && (r => { question.disclaimerElement = r });

    if (question.disclaimer_required) {
      return (
        <div className="disclaimer">
          <label>
            <input ref={ disclaimerRef } type="checkbox"/>&nbsp;
            <span dangerouslySetInnerHTML={{__html:this.setFinishButtonText(question.disclaimer)}} />
          </label>
        </div>
      )
    } else {
      return (
        <div className="disclaimer">
          <span dangerouslySetInnerHTML={{__html:this.setFinishButtonText(question.disclaimer)}} />
        </div>
      )
    }
  }
}

class QuizController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      config: props.config || null,
      errors: {},
      answers: {},
      loading: true,
      started: false,
      transition: false,
      previousAgentInfo: null,
      quizViewTracked: false,
    };
    this.XHRPromises = [];
    this.answerQuestion = this.answerQuestion.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.start = this.start.bind(this);
    this.completeDynamicText = this.completeDynamicText.bind(this);
    this.setPreviousAgent = this.setPreviousAgent.bind(this);
    this.handleErrorResponse = this.handleErrorResponse.bind(this);
  }

  componentDidMount() {
    this.authenticityToken = this.fetchAuthenticityToken();
    let {config} = this.state;
    validate = modules.import('v4/modules/input-validation');

    this.initializeJsSentry();

    if (config && !config.start_screen) {
      this.start();
    }

    if (this.props.urlPersistence) {
      this.setUpURLPersistence();
    }

    if (this.props.config_url) {
      this.requestInitialConfig();
    } else {
      this.setState({loading: false});
      this.extendStateFromObject(config);
    }

    if (this.props.extendState) {
      this.extendStateFromObject({extend_state: this.props.extendState});
    }
  }

  fetchAuthenticityToken() {
    const csrfTokenTag = document.querySelector('[name=csrf-token]');
    if (!csrfTokenTag) return;

    return csrfTokenTag.getAttribute('content');
  }

  requestInitialConfig() {
    return httpService.get({url: `${HOMELIGHT_URL}${this.props.config_url}`}).then(config => {
      this.extendStateFromObject(config);

      this.setState({config, loading: false});

      if (!config.start_screen) {
        this.start();
      }
    });
  }

  initializeJsSentry() {
    const Sentry = modules.import('modules/sentry');
    const tag = 'qaas';

    if (!!Sentry) {
      const sentryLoader = new Sentry();
      sentryLoader.init(tag);
    }
  }

  setUpURLPersistence() {
    let components = this.getHashComponents();
    window.addEventListener('hashchange', this.onHashChange.bind(this));
    if (!components.hash) {
      location.hash += `/${PARAMETER}=0/`;
    } else {
      this.onHashChange();
    }
  }

  onHashChange() {
    let {step} = this.getHashComponents();
    if ((!step && step !== 0) || step === this.state.step) {
      return;
    }
    this.setState({step});
  }

  getHashComponents() {
    let regex = new RegExp(`\\/${PARAMETER}=(\\d+)\\/`);
    let component = (location.hash || '').match(regex) || [];
    return {
      hash: component[0],
      step: Number(component[1]) || 0,
    };
  }

  persistHashLocation(step) {
    step = step || this.state.step;
    let hashComponents = this.getHashComponents();
    let newHash = `/${PARAMETER}=${step}/`;
    location.hash = hashComponents.hash
      ? location.hash.replace(hashComponents.hash, newHash)
      : newHash;
  }

  extendStateFromObject(object) {
    let {answers, step} = this.state;
    if (object && object.hasOwnProperty('extend_state')) {
      answers = Object.assign(answers, object.extend_state);
      this.setState({answers}, () => {
        // Update the displayAnswers in the quiz wrapper component when
        // there are synchronous updates to the quiz instructions
        if (object.quiz && this.props.onAnswerStep && step < object.quiz.length) {
          let questions = object.quiz[step].questions;
          this.props.onAnswerStep(questions, answers, step);
        }
      });
    }
    return object;
  }

  start() {
    let {config, started, answers} = this.state;
    let quizName = this.props.source_form || config.source_form || '';
    let source_page_type = this.props.source_page_type || config.source_page_type || '';

    if (!started) {
      this.setState({started: true});
      this.trackEvent('Quiz', 'Entered Quiz', this.normalizeQuizName(quizName), 1);
      this.trackDataLayerVariables('user_type', answers);
      this.trackDataLayer('source_page_type', source_page_type);
      this.trackDataLayer('source_form', quizName);
    }
  }

  trackLeadValuesInDataLayer() {
    const {config, answers, step} = this.state;
    if (!config.quiz[step]) return;

    const create_lead_on_completion = config.quiz[step].questions.some(question => {
      return question.create_lead_on_completion === true;
    });

    if (config.conversion && config.tracking_category) {
      if (answers.property_type) {
        this.trackDataLayer('property_type', answers.property_type);
      }

      if (answers.lead_user_type) {
        this.trackDataLayer('lead_user_type', answers.lead_user_type);
      }

      if (answers.lead_id) {
        this.trackDataLayer('dl_lead_id', answers.lead_id);
      }

      if (!answers.price) {
        answers.price = this.defaultPriceByState();
      }

      if (answers.price) {
        this.trackDataLayer('price', answers.price);
      }

      if (answers.lead_probability) {
        const answersPrice = Number.parseInt(answers.price || answers.user_manual_estimated_price);
        const defaultPrice = this.defaultPriceByState();
        const price = Boolean(answersPrice)
          ? Math.min(Number.parseInt(answersPrice), 1000000)
          : defaultPrice;
        const agentCommission = this.isServiceState() ? 0.30 : 0.33;
        const score = price * 0.03 * agentCommission * answers.lead_probability;
        this.trackDataLayer('lead_score_v6', score);

        if (answers.original_lead_probability) {
          const originalScore =
            price * 0.03 * agentCommission * answers.original_lead_probability;
          this.trackDataLayer('lead_score_v6_orginal', originalScore);
        }
      }

      if (create_lead_on_completion) {
        this.trackConversion(answers && answers.session_uuid);
      }
    }
  }

  isServiceState() {
    const {answers} = this.state;
    const serviceStates = ['IL', 'WA', 'CA', 'TX', 'CO', 'AZ', 'FL'];
    const state = answers && answers.geocode && answers.geocode.state;

    if (!state) {
      return false;
    }

    return serviceStates.includes(state);
  }

  defaultPriceByState() {
    const { answers } = this.state;
    const { priceByState } = this.props;
    const state = answers && answers.geocode && answers.geocode.state;
    const defaulPrice = 415000;

    if (!state || !priceByState) {
      return defaulPrice;
    }

    return priceByState[state] || defaulPrice;
  }

  quizCompleted() {
    this.setState({loading: false});
    let {config, answers} = this.state;
    let quizName = this.props.source_form || config.source_form || '';
    this.trackEvent('Quiz', 'Completed Quiz', this.normalizeQuizName(quizName), 1);
    this.trackTatariConversion(answers.lead_id);

    if (answers.lead_id) {
      this.trackDataLayer('dl_lead_id', answers.lead_id);
    }

    let eventData = {
      lead_id: answers.lead_id,
      dl_price: this.valueFromDataLayer('price'),
      dl_source_page_type: this.valueFromDataLayer('source_page_type'),
      dl_lead_score_v6: this.valueFromDataLayer('lead_score_v6'),
      dl_property_type: this.valueFromDataLayer('property_type'),
      dl_lead_user_type: this.valueFromDataLayer('lead_user_type'),
      dl_source_form: this.valueFromDataLayer('source_form'),
      dl_timeline: this.valueFromDataLayer('timeline'),
      quiz_session_uuid: answers.quiz_session_uuid,
      dl_lead_score_v6_orginal: this.valueFromDataLayer('lead_score_v6_orginal'),
      dl_ppl_zip_code: (answers.geocode && answers.geocode.postal_code) || answers.postal_code,
      dl_ppl_value_boost: answers.ppl_value_boost,
    };

    if (this.props.trackSemEvent) {
      this.trackSemEvent('conversion', eventData, 'google');

      if (answers.msclkid) {
        eventData.type = 'online';
        eventData.microsoft_click_id = answers.msclkid;
        this.trackSemEvent('conversion', eventData, 'microsoft');
      }

      if (answers.fbclid) {
          eventData.name = 'Purchase';
          eventData.type = 'online';
          eventData.fbc = answers.fbclid;
          eventData.fbp = answers.fbp;
        this.trackSemEvent('conversion', eventData, 'facebook');
      }
    }

    if (this.props.onQuizComplete) {
      this.props.onQuizComplete(answers);
    }

    if (window.PubSub) {
      // set current time for comparison later
      answers.time_emitted = new Date().getTime();
      PubSub.emit('quiz-controller-complete', answers, config.quiz_id || this.props.quizId);
    }
  }

  trackEvent(category, action, label, value) {
    try {
      tracking.trackEvent(category, action, label, value);
    } catch (err) {
      console.error(err);
    }
  }

  trackConversion(token) {
    let {config} = this.state;
    try {
      tracking.trackConversion(true, token);
      this.trackEvent(config.tracking_category, 'conversion', config.conversion, 1);
    } catch (err) {
      console.error(err);
    }
  }

  trackSemEvent(eventName, eventData, platform) {
    try {
      tracking.trackSemEvent(eventName, eventData, platform)
    } catch (err) {
      console.error(err);
    }
  }

  trackTatariConversion(leadId) {
    const tatari = window.tatari;
    if (!!tatari) {
      try {
        if (leadId) tatari.identify(leadId);
        tatari.track('lead_conversion');
      } catch (err) {
        console.error(err);
      }
    }
  }

  trackDataLayer(key, value) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      [key]: value,
    });
  }

  trackDataLayerVariables(question_id, answers) {
    let allowedIds = ['user_type', 'property_type', 'price', 'timeline', 'customer_price_estimate'];
    // Mapping when question id doesn't match with data layer variable name
    let questionToDataLayer = {
      customer_price_estimate: 'price',
    };
    // Mapping when question id doesn't match with answer name
    let questionToAnswerName = {
      customer_price_estimate: 'user_manual_estimated_price',
    };
    let dataLayerName = questionToDataLayer[question_id]
      ? questionToDataLayer[question_id]
      : question_id;
    let answerName = questionToAnswerName[question_id]
      ? questionToAnswerName[question_id]
      : question_id;

    if (allowedIds.includes(question_id)) {
      this.trackDataLayer(dataLayerName, answers[answerName]);
    }
  }

  trackPageView() {
    let {config, step} = this.state;
    let question = config && config.quiz[step];

    if (question && TRACK_PAGE_VIEW_IDS.includes(question.id)) {
      this.trackEvent('Quiz', 'Submit', ACTIVE_STEP_TO_GA_EVENT_MAP[question.id], 1);
    }
  }

  trackQuizSubmitTime() {
    if (this.submitTime) {
      const interstitialStartTime = new Date().getTime();
      const submitToInterstitialTime = interstitialStartTime - this.submitTime;

      if (submitToInterstitialTime && !Number.isNaN(submitToInterstitialTime)) {
        const formattedSubmitTime = (submitToInterstitialTime / 1000).toFixed(1); // in seconds, one decimal
        this.trackEvent('Quiz', 'SubmitPII-to-Interstitial-time', formattedSubmitTime, 0);
        this.submitTime = null;
      }
    }
  }

  trackGoogleConversion(answers) {
    const {email} = answers;
    if (!email) return;

    window.HOMELIGHT_GOOGLE_CONVERSIONS_TRACKING_USER_EMAIL = email;
  }

  normalizeQuizName(quizName) {
    return quizName.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }

  valueFromDataLayer(key) {
    let value;

    window.dataLayer.find(dlKey => {
      if (dlKey[key]) {
        value = dlKey[key];
        return;
      }
    });

    return value;
  }

  post(data) {
    let {
      state: {config},
      props,
    } = this;

    let source = props.source || config.source || 'web';
    let source_page_type = props.source_page_type || config.source_page_type || '';
    let source_form = props.source_form || config.source_form || '';
    let referrer =
      props.original_referrer ||
      config.original_referrer ||
      cookies.get('original_referrer') ||
      location.href;
    let entry_controller =
      props.entry_controller || config.entry_controller || cookies.get('entry_controller');
    let entry_action = props.entry_action || config.entry_action || cookies.get('entry_action');
    let entry_path = props.entry_path || config.entry_path || cookies.get('entry_path');
    let activeQuestion = config.quiz[this.state.step];
    let question_id = activeQuestion ? activeQuestion.id : null;
    const current_instructions = config.quiz;
    let area_slug =
      props.areaSlug || (config.extend_state && config.extend_state.area_slug) || null;
    const quiz_controller = "QuizController";

    data = Object.assign(data, {
      authenticity_token: this.authenticityToken,
      source,
      source_page_type,
      source_form,
      referrer,
      entry_controller,
      entry_action,
      entry_path,
      question_id,
      area_slug,
      current_instructions,
      quiz_controller,
    });

    const url = `${HOMELIGHT_URL}${config.persistence_url}`;
    let promise = httpService.post({url, data}).then(res => this.extendStateFromObject(res));
    this.XHRPromises.push(promise);
    return promise;
  }

  getValidationForQuestion(question) {
    return (
      question.validator ||
      (question.phone && 'phone') ||
      (question.email && 'email') ||
      (question.currency && 'currency') ||
      false
    );
  }

  validateAnswers(answers, extend) {
    return answers
      .map(element => {
        let {errors} = this.state;
        let validation = this.getValidationForQuestion(element.question);

        if (this.failsValidation(element, validation, extend)) {
          errors[element.question.value] = true;
          this.setState({errors});
          return element.question.text;
        }

        if (
          validation &&
          validate &&
          validate[validation] &&
          !validate[validation](element.answer)
        ) {
          errors[element.question.value] = true;
          this.setState({errors});
          return element.question.text;
        } else if (validation && !validate[validation]) {
          console.error('NO VALIDATION WAS FOUND FOR', validation);
        }
      })
      .filter(presence => presence);
  }

  failsValidation(element, validation, extend) {
    const {answer} = element;
    const {required, value} = element.question;

    // there may be an "extend" value for this question with a key formatted like `${question.value}_${answer.id}`
    let additionalValueExists = false;
    if (extend) {
      const extendKeys = Object.keys(extend);
      additionalValueExists = extendKeys.some(key => {
        return key.indexOf(value) !== -1 || key.toString() === 'explicitEmpty';
      });

      // this exists to green-light empty payloads, but don't send with data
      delete extend['explicitEmpty'];
    }

    // false is allowed, but marking a multiselect question as required requires non-empty array
    const answerIsEmpty =
      (typeof answer === 'string' || Array.isArray(answer)) && answer.length === 0;
    const answerNotProvided =
      (answer === undefined || answer === null || answerIsEmpty) && !additionalValueExists;
    return answerNotProvided && (required || validation);
  }

  buildAnswers(answers) {
    let currentAnswers = this.state.answers;
    answers.forEach(element => {
      if (element.question.currency) {
        let str = element.answer;
        currentAnswers[element.question.value] =
          typeof str === 'string' ? parseFloat(str.replace(/[^0-9\.]/g, '')) || str : str;
      } else {
        currentAnswers[element.question.value] = element.answer;
      }

      if (element.question.create_lead_on_completion) {
        currentAnswers['create_lead'] = true;
      }
    });
    return currentAnswers;
  }

  skipQuestion() {
    let {config, step} = this.state;
    let activeStep = config.quiz[step];
    const gaTrackingName = ACTIVE_STEP_TO_GA_EVENT_MAP[activeStep.id] || activeStep.id;
    this.trackEvent('Quiz', 'Skip step', gaTrackingName, 1);

    this.setState({step: step + 1, errors: {}}, () => {
      // reset any error messages as well
      if (this.props.urlPersistence) {
        this.persistHashLocation(this.state.step);
      }
    });
  }

  answerIsFromClickedOption(question) {
    const questionTypes = ['buttons', 'multiselect', 'single_select'];
    return questionTypes.some(type => !!question[type]);
  }

  setPreviousAgent(value) {
    this.setState({previousAgentInfo: value});
  }

  // Get the response from the value of designated input field - parse stringified value if not a primitive
  answerFromInputField(question) {
    const answerField = this.quizElement.querySelector(`[name="${question.value}"]`);
    if (!answerField) {
      return;
    }

    try {
      return !!answerField.dataset.parseResponse
        ? JSON.parse(answerField.value)
        : answerField.value;
    } catch (err) {
      return;
    }
  }

  answerQuestion(answerFromButton) {
    let {config} = this.state;
    let activeStep = config.quiz[this.state.step];
    let step = config.quiz.indexOf(activeStep);
    let question_id = activeStep.id;
    let answers = {};
    let errors = {};
    let synchronous =
      activeStep.async_persistence !== undefined &&
      activeStep.async_persistence.toString() === 'false';
    let nextStepId = config.quiz[this.state.step + 1] && config.quiz[this.state.step + 1].id;
    let authenticity_token = this.fetchAuthenticityToken();

    let questions = activeStep.questions.map(element => {
      let answer = null;
      if (this.answerIsFromClickedOption(element)) {
        answer = answerFromButton.value;
      } else if (element.agent_search && this.state.previousAgentInfo) {
        answer = this.state.previousAgentInfo;
      } else if (element.verify_identity) {
        answer = answerFromButton;
      } else {
        answer = this.answerFromInputField(element);
      }
      return {
        answer: answer,
        question: element,
      };
    });

    let errorMessages = activeStep.confirm_phone
      ? []
      : this.validateAnswers(questions, answerFromButton.extend);

    if (answerFromButton.extend) {
      Object.assign(answers, answerFromButton.extend);
    }

    if (errorMessages.length) {
      const msg = `Please enter the following:\n${errorMessages.join(', ')}`;
      this.addErrorMessage(msg);
      return msg;
    } else {
      this.addErrorMessage('');
    }

    if (activeStep && activeStep.disclaimer && activeStep.disclaimerElement) {
      let checked = activeStep.disclaimerElement.matches(':checked');
      if (!checked && activeStep.disclaimer_required) {
        return alert(
          activeStep.disclaimer_missing_message || 'Please agree that you have read our disclaimer'
        );
      }
      Object.assign(answers, {disclaimer: checked});
    }

    Object.assign(answers, this.buildAnswers(questions));
    answers.step = step;
    answers.question_id = question_id;

    if (this.props.onStepSubmit) {
      this.props.onStepSubmit(answers);
    }

    // update displayAnswers in the quiz wrapper component after every update to answers
    if (this.props.onAnswerStep) {
      this.props.onAnswerStep(activeStep.questions, answers, step, answerFromButton.text);
    }

    if (activeStep.id === 'listed_property') {
      this.trackHomeListedAnswer(answers);
    }

    if (ACTIVE_STEP_TO_GA_EVENT_MAP[activeStep.id]) {
      this.trackEvent('Quiz', 'Submit', ACTIVE_STEP_TO_GA_EVENT_MAP[activeStep.id], 0);
    } else if (this.containsFullName(activeStep)) {
      this.trackEvent('Quiz', 'Submit', 'FirstName', 0);
    }

    this.trackDataLayerVariables(activeStep.id, answers);
    this.trackGoogleConversion(answers);
    this.trackLeadValuesInDataLayer();

    if (synchronous) {
      this.advanceStepSync(activeStep, answers, errors);
    } else {
      this.advanceStepAsync(activeStep, answers, errors);
    }

    // when in sync mode, should we fire any pubsubs after the server response?
    if (window.PubSub) {
      PubSub.emit('quiz-controller-completed-step', {
        step_id: question_id,
        next_step_id: nextStepId,
        quiz_id: config.quiz_id || this.props.quizId,
        step: step,
        authenticity_token: authenticity_token,
      });
    }
  }

  addErrorMessage(msg) {
    const element = document.querySelector('#error-message');

    if (element) {
      element.innerHTML = msg;
    }
  }

  containsFullName(step) {
    for (let i = 0; i < step.questions.length; i++) {
      let question = step.questions[i];
      if (question.value == 'full_name') {
        return true;
      }
    }
    return false;
  }

  advanceStepAsync(activeStep, answers, errors) {
    this.setState(
      prev => {
        return {answers, errors, step: prev.step + 1};
      },
      () => {
        const newStep = this.state.step;

        this.runStepChangeCallback(this.state.config.quiz[newStep], newStep);

        this.onAfterAsyncStepAdvance(activeStep);
        if (this.props.urlPersistence) {
          this.persistHashLocation(newStep);
        }
      }
    );
  }

  advanceStepSync(activeStep, answers, errors) {
    let {config, step} = this.state;
    if (!config.quiz[step + 1]) {
      // No steps after this submit, track time submit starts
      this.submitTime = new Date().getTime();
    }

    this.setState({loading: true, answers, errors});
    Promise.all(this.XHRPromises).then(() => {
      this.post(answers)
        .then(res => this.processSynchronousStep(res, activeStep))
        .catch(err => {
          this.XHRPromises.pop();
          const errorMessage =
            err.responseJSON && err.responseJSON.error_messages
              ? err.responseJSON.error_messages
              : "We're sorry, something went wrong. Please try again later.";

          if (window.Sentry) {
            window.Sentry.captureException(err);
          }

          return this.handleErrorResponse(errorMessage, activeStep);
        });
    });
  }

  processSynchronousStep(serverResponse, activeStep) {
    let {error_messages, current_step_index, new_instructions, extend_state} = serverResponse;
    let {errors} = this.state;

    if (extend_state) {
      this.lead_token = extend_state.lead_token || this.lead_token;
    }

    if (new_instructions) {
      this.handleNewInstructions(new_instructions, current_step_index, activeStep);
    }

    if (error_messages) {
      return this.handleErrorResponse(error_messages, activeStep);
    }

    this.setState({step: current_step_index}, () => {
      const newStep = this.state.step;

      this.runStepChangeCallback(this.state.config.quiz[newStep], newStep);

      this.onAfterSynchronousStepAdvance(activeStep);
      if (this.props.urlPersistence) {
        this.persistHashLocation(this.state.step);
      }
    });
  }

  handleErrorResponse(errorMessage, activeStep) {
    const {errors} = this.state;
    if (errorMessage) {
      errors[activeStep.questions[0].value] = true;
      this.setState({errors});
      this.setState({loading: false});

      const elem = document.querySelector('#error-message');
      elem.innerHTML = errorMessage;
      return errorMessage;
    }
  }

  runStepChangeCallback(activeStep, currentStepIndex) {
    if (!this.props.onStepChange) return;

    this.props.onStepChange({
      activeStep: activeStep,
      currentStepIndex: currentStepIndex,
    });
  }

  handleNewInstructions(config, stepIndex, step) {
    this.rebuildQuizFromInstructions(config, stepIndex || 0);
    if (this.props.urlPersistence) {
      this.persistHashLocation(stepIndex);
    }

    if (step.track && config.tracking_category && config.tracking_action) {
      this.trackEvent(config.tracking_category, config.tracking_action, step.track, 1);
      step.trackSent = true;
    }
  }

  rebuildQuizFromInstructions(config, step) {
    this.extendStateFromObject(config);

    this.setState({config, step, loading: false});
  }

  onAfterAsyncStepAdvance(activeStep) {
    let {config, answers, step} = this.state;

    if (!config.quiz[step]) {
      this.post(answers).then(() => {
        this.quizCompleted();
      }).catch(err => {
        if (window.Sentry) {
          window.Sentry.captureException(err);
        }
      });
    } else if (config.persist_each) {
      const quizUpdate = this.post(answers).catch(err => {
        if (window.Sentry) {
          window.Sentry.captureException(err);
        }
      });
      quizUpdate.catch(err => {
        let errorMessage = err;
        if (err.status && err.responseText) {
          errorMessage = `Quiz step did not update - Status: ${
            err.status
          }, Error: ${err.responseText.substring(0, 200)}`;
        }
        if (window.Sentry) {
          window.Sentry.captureException(errorMessage);
        }
      });
    }

    if (activeStep.track && config.tracking_category && config.tracking_action) {
      this.trackEvent(config.tracking_category, config.tracking_action, activeStep.track, 1);
    }

    this.setState({transition: !this.state.transition});
  }

  onAfterSynchronousStepAdvance(activeStep) {
    let {config, step} = this.state;

    if (!config.quiz[step]) {
      this.quizCompleted();
    }

    if (
      activeStep.track &&
      config.tracking_category &&
      config.tracking_action &&
      !activeStep.trackSent
    ) {
      this.trackEvent(config.tracking_category, config.tracking_action, activeStep.track, 1);
    }

    this.setState({transition: !this.state.transition, loading: false});
  }

  createGeocodeHandler(question) {
    if (question.geocode_value) {
      return result => {
        let {answers} = this.state;
        answers[question.geocode_value] = result;
        this.setState({answers});
        this.answerQuestion(question);
      };
    }
    if (question.emit && window.PubSub) {
      return result => {
        this.answerQuestion(question);
        PubSub.emit('quiz-controller--geocode-result', result);
      };
    }
  }

  renderAnswerField(question) {
    let {newQuizStyles} = this.props;
    let {errors, config, answers, step} = this.state;
    let currentValue = answers[question.value] || question.default_value;
    if (question.email) {
      return (
        <input
          className={errors[question.value] ? 'error' : ''}
          placeholder={question.placeholder || ''}
          name={question.value}
          type="email"
          defaultValue={currentValue}
        />
      );
    } else if (question.phone) {
      return (
        <PhoneInput
          className={errors[question.value] ? 'error' : ''}
          placeholder={question.placeholder || ''}
          name={question.value}
          defaultValue={currentValue}
        />
      );
    } else if (question.geocode) {
      return (
        <MapboxPlaceAutocomplete
          onSelect={this.createGeocodeHandler(question)}
          addClass={errors[question.value] ? 'error' : ''}
          types={question.geocode_types}
          bias={this.props.areaPointBias}
          name={question.value}
          defaultValue={currentValue}
          buttonText={step.button_text || 'Next'}
          showButton={true}
        />
      );
    } else if (question.currency) {
      return (
        <CurrencyInput
          className={errors[question.value] ? 'error' : ''}
          name={question.value}
          defaultValue={currentValue}
          key={question.value}
        />
      );
    } else if (question.buttons) {
      return (
        <ButtonQuestion
          question={question}
          answerQuestion={this.answerQuestion}
          answers={answers}
          completeDynamicText={this.completeDynamicText}
          newQuizStyles={newQuizStyles}
        />
      );
    } else if (question.multiselect || question.single_select) {
      return (
        <MultiselectQuestion
          config={config}
          question={question}
          answerQuestion={this.answerQuestion}
          answers={answers}
          completeDynamicText={this.completeDynamicText}
          newQuizStyles={newQuizStyles}
        />
      );
    } else if (question.agent_search) {
      return <AgentAutocomplete question={question} setPreviousAgent={this.setPreviousAgent} />;
    } else if (question.star_rating) {
      return (
        <StarRater
          name={question.value}
          value={currentValue}
          size={question.star_rem_size}
          starCount={question.max_stars}
        />
      );
    } else if (question.textarea) {
      return (
        <textarea
          className={errors[question.value] ? 'error' : ''}
          name={question.value}
          defaultValue={currentValue}
        />
      );
    } else if (question.htmlSelect) {
      return (
        <select
          defaultValue={currentValue}
          name={question.value}
          className={errors[question.value] ? 'error' : ''}
        >
          {question.options.map(e => {
            return (
              <option key={e.value + step} value={e.value}>
                {e.text}
              </option>
            );
          })}
        </select>
      );
    } else if (question.dropdown) {
      return (
        <DropdownQuestion
          question={question}
          answers={answers}
          completeDynamicText={this.completeDynamicText}
        />
      );
    } else {
      return (
        <input
          key={question.value}
          placeholder={question.placeholder || ''}
          className={errors[question.value] ? 'error' : ''}
          name={question.value}
          type={question.type || 'text'}
          defaultValue={currentValue}
        />
      );
    }
  }

  hideActionButtons(question) {
    if (question.questions && question.questions.length === 1) {
      const firstQuestion = question.questions[0];
      return firstQuestion.geocode || this.answerIsFromClickedOption(firstQuestion);
    }
    return false;
  }

  renderActionButtons(step) {
    if (this.hideActionButtons(step)) {
      return;
    }

    let {config, loading} = this.state;
    let buttonText = step.button_text || 'Next';

    let create_lead_on_completion = step.questions.some(question => {
      return question.create_lead_on_completion === true;
    });

    const submittingFinalStep =
      create_lead_on_completion || config.quiz.indexOf(step) === config.quiz.length - 1;
    if (submittingFinalStep) {
      buttonText = config.finish_btn_text || this.props.finish_btn_text || 'Finish';
    }

    return (
      <div className={`actions ${(step.skippable && 'button-group') || ''}`}>
        {step.skippable ? (
          <a className="button secondary-button" onClick={this.skipQuestion}>
            Skip
          </a>
        ) : null}

        {loading ? (
          <div className="button next-button">
            <LoadingSpinner style="chasing" text="" height="16px" />
          </div>
        ) : (
          <a
            className="button next-button"
            ref={el => (this.submitButton = el)}
            onClick={this.answerQuestion}
          >
            {buttonText}
          </a>
        )}
      </div>
    );
  }

  renderDisclaimer(question) {
    return <Disclaimer question={question} finish_btn_text={this.props.finish_btn_text} />;
  }

  setFinishButtonText(text) {
    if (this.props.finish_btn_text) {
      return text.replace(/{{finish_btn_text(.*)}}/g, this.props.finish_btn_text);
    }
    return text.replace(/{{finish_btn_text \| (.*)}}/g, '$1');
  }

  // Any quiz copy may swap out with a value stored in props.copyContext or state.answers by wrapping in "{{}}".
  completeDynamicText(text) {
    if (!text || text.indexOf('{{') === -1) {
      return text; // return early if no string templating
    }

    const {copyContext} = this.props;
    const {answers} = this.state;
    const combinedCopyValues = {...answers, ...copyContext};

    let updatedText = text;
    const templateRegex = /({{).+(}})/g;
    const keyRegex = /({{)\w+/;
    const templateMatches = text.match(templateRegex);

    templateMatches.forEach(template => {
      const keyMatch = template.match(keyRegex);
      const key = keyMatch && keyMatch[0] ? keyMatch[0].replace('{{', '') : null;
      if (key && combinedCopyValues[key]) {
        updatedText = text.replace(template, combinedCopyValues[key]);
      } else {
        // Fall back to default. Example: {{display_city | your city}} is replaced with "your city"
        const defaultRegex = /(\|\s)[^}]+/;
        const defaultMatch = template.match(defaultRegex);
        const defaultText =
          defaultMatch && defaultMatch[0] ? defaultMatch[0].replace('| ', '') : null;
        if (defaultText) {
          updatedText = text.replace(template, defaultText);
        }
      }
    });

    return updatedText;
  }

  renderStartScreen(config) {
    let heading = config.start_screen.heading && (
      <div className="heading">{config.start_screen.heading}</div>
    );
    let info = config.start_screen.info && <div className="info">{config.start_screen.info}</div>;
    return (
      <div className="start-screen">
        {heading}
        {info}
        <a className="button" onClick={this.start}>
          {config.start_screen.button_text || 'Get Started'}
        </a>
      </div>
    );
  }

  renderConfirmStep(step) {
    const {answers, transition} = this.state;
    const confirmProps = {
      answers: answers,
      step: step,
      transition: transition,
      completeDynamicText: this.completeDynamicText,
    };

    return step['multistep'] ? (
      <MultistepConfirmLocation onSubmit={this.answerQuestion} {...confirmProps} />
    ) : (
      <React.Fragment>
        <ConfirmLocation {...confirmProps} />
        {this.renderActionButtons(step)}
      </React.Fragment>
    );
  }

  renderPhoneConfirmStep(step) {
    this.trackEvent('QuizFlow', 'beforeShow', 'VerifyCode');
    return (
      <VerifyPhone
        phoneNumber={this.state.answers.phone}
        lead_token={this.lead_token}
        step={step}
        onVerify={this.answerQuestion}
      />
    );
  }

  renderPhoneEmailStep(question) {
    const defaultAnswers = {};
    ['full_name', 'email', 'phone'].forEach(fieldName => {
      defaultAnswers[fieldName] = this.getDefaultValueFor(fieldName, question.questions);
    });

    return (
      <React.Fragment>
        <SignupSignin
          defaultAnswers={defaultAnswers}
          onSignupComplete={this.answerQuestion}
          disclaimer={question.disclaimer}
        />
        <div className="ss-disclaimer">{this.renderDisclaimer(question)}</div>
      </React.Fragment>
    );
  }

  getDefaultValueFor(fieldName, questions) {
    let result = questions.find(question => {
      return question.value === fieldName;
    });

    return result && result.default_value;
  }

  renderCompleteStep(config) {
    if (config.complete.interstitial) {
      this.trackQuizSubmitTime();
      return <Interstitial valueProps={this.props.interstitialValueProps} />;
    } else {
      return (
        <div className="question-contents complete">
          {config.complete.show.heading && (
            <h2 className="heading">{config.complete.show.heading} </h2>
          )}
          {config.complete.show.info && <div className="info">{config.complete.show.info} </div>}
        </div>
      );
    }
  }

  renderPropertyInterstitialStep(config) {
    let leadType = this.state.answers.user_type;
    return (
      <PropertyTypeInterstitial
        propertyType={this.state.answers.property_type}
        leadType={leadType}
        answerQuestion={this.answerQuestion}
      />
    );
  }

  renderAdditionalQuestionInterstitialStep() {
    return (
      <AdditionalQuestionInterstitial
        valueProps={this.props.additionalQuestionValueProps}
        secondaryUserType={this.props.secondaryUserType}
        answerQuestion={this.answerQuestion}
      />
    );
  }

  renderCalendlyStep() {
    const calendlyUrl = this.state.config.quiz[this.state.step].calendly_url;
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <CalendlyQuizStep
        utm={{
          utmContent: this.state.answers.lead_token,
          utmSource: 'simple-sale-quiz-flow',
          utmMedium: 'direct',
          utmCampaign: urlParams.get('utm_campaign'),
        }}
        url={calendlyUrl}
        email={this.state.answers.email}
        name={this.state.answers.full_name}
        onSubmit={this.answerQuestion}
      />
    );
  }

  renderVerifyIdentityStep(pii_step_index) {
    const {answers} = this.state;
    const {newQuizStyles} = this.props;
    return (
      <VerifyIdentity
        leadToken={this.lead_token}
        phone={answers.phone}
        email={answers.email}
        duplicatePiiStatus={answers.detect_duplicate_contact_info}
        onVerify={this.answerQuestion}
        newQuizStyles={newQuizStyles}
        piiIndexStep={pii_step_index}
      />
    );
  }

  trackHomeListedViewed() {
    this.trackEvent('HLSS_Quiz', 'View', 'ListedPropertyView', 0);
  }

  trackHomeListedAnswer(answers) {
    const value = answers['home_listed'];
    this.trackEvent('HLSS_Quiz', 'Submit', 'ListedPropertySubmit', value === 'Yes' ? 1 : 0);
  }

  renderStep() {
    const piiStepIds = ['phone_email', 'name_phone_email', 'contact_info'];

    let {config, step, loading, started, quizViewTracked} = this.state;
    let {newQuizStyles} = this.props;
    let isInterstitialVariant = config && config.quiz_interstitials;
    let question = config && config.quiz[step];
    const isPiiQuestion =
      question &&
      (question.id === 'phone_email' ||
        question.id === 'name_phone_email' ||
        question.id === 'confirm_phone');
    let loadingText =
      (question && question.loading_text) ||
      (config && config.loading_text) ||
      'Performing market analysis...';
    this.trackPageView();
    if (document.body.classList.contains('new-quiz-styling')) {
      const newStyleLight = !loading && question && question.light_background;
      this.determineNewStylingMode(newStyleLight);
    }
    // skip loading interstitial for last question (pii) as we have a final interstitial here already
    if (isPiiQuestion) {
      loading = false;
    }

    if (question && question.id === 'property_type_interstitial') {
      return this.renderPropertyInterstitialStep(config);
    }

    if (question && question.id === 'additional_question_interstitial') {
      return this.renderAdditionalQuestionInterstitialStep();
    }

    if (question && question.id === 'calendly') {
      return this.renderCalendlyStep();
    }

    if (question && question.id === 'listed_property' && !quizViewTracked) {
      this.trackHomeListedViewed();
      this.setState({quizViewTracked: true});
    }

    if (loading) {
      // the first two conditions here ensure the correct loading experience for this quiz variant
      if (isInterstitialVariant && question && question.id == 'property_type') {
        return this.renderPropertyInterstitialStep(config);
      } else if (isInterstitialVariant && question && question.id == 'additional_services') {
        return this.renderAdditionalQuestionInterstitialStep();
      } else {
        return (
          <div className="question-contents">
            <LoadingSpinner style="chasing" text={loadingText} />
          </div>
        );
      }
    } else if (!started && config.start_screen) {
      return this.renderStartScreen(config);
    } else if (!question && config.complete) {
      return this.renderCompleteStep(config);
    } else if (question.verify_identity) {
      // necessary for back navigation from verify screen
      // for now this is only special seller quiz with this question
      let piiQuestion = config.quiz.filter(i => piiStepIds.includes(i.id))[0];
      let indexOfPiiQuestion = config.quiz.indexOf(piiQuestion);
      return (
        <div className="question-contents">{this.renderVerifyIdentityStep(indexOfPiiQuestion)}</div>
      );
    } else if (question.confirm_phone) {
      return <div className="question-contents">{this.renderPhoneConfirmStep(question)}</div>;
    } else if (question.signup_signin) {
      return <div className="question-contents">{this.renderPhoneEmailStep(question)}</div>;
    } else if (question.confirm) {
      return <div className="question-contents">{this.renderConfirmStep(question)}</div>;
    } else {
      if (config.layout && config.layout === 'agent_move_safe') {
        return (
          <div className={`question-contents ${question.id}`}>
            <div
              className={`${this.state.transition ? '' : 'animate-in'} ${
                newQuizStyles ? 'conversational-button-question-container' : ''
              }`}
            >
              <div
                className={`${this.state.transition ? 'animate-in' : ''} ${
                  newQuizStyles ? 'conversational-button-question-container' : ''
                }`}
              >
                {question.desc && <p>{this.completeDynamicText(question.desc)}</p>}
                {question.heading && <h2>{this.completeDynamicText(question.heading)}</h2>}
                {question.subheading && <p>{this.completeDynamicText(question.subheading)}</p>}
                {this.renderQuestions(question.questions)}
                {question.post_text && <p>{this.completeDynamicText(question.post_text)}</p>}
                {this.renderActionButtons(question)}
                {question.disclaimer && this.renderDisclaimer(question)}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={`question-contents ${question.id}`}>
            <div
              className={`${this.state.transition ? '' : 'animate-in'} ${
                newQuizStyles ? 'conversational-button-question-container' : ''
              }`}
            >
              <div
                className={`${this.state.transition ? 'animate-in' : ''} ${
                  newQuizStyles ? 'conversational-button-question-container' : ''
                }`}
              >
                {question.heading && <h2>{this.completeDynamicText(question.heading)}</h2>}
                {question.desc && <p>{this.completeDynamicText(question.desc)}</p>}
                {this.renderQuestions(question.questions)}
                {this.renderActionButtons(question)}
                {question.disclaimer && this.renderDisclaimer(question)}
              </div>
            </div>
          </div>
        );
      }
    }
  }

  renderQuestions(questions) {
    return questions.map((q, key) => {
      return (
        <div
          key={key}
          id={`qaas_q_${q.value}`}
          className={`input-group ${this.state.errors[q.value] ? 'error' : ''}`}
        >
          <label className="question-text">{this.completeDynamicText(q.text)}</label>
          {this.renderAnswerField(q)}
        </div>
      );
    });
  }

  calculateProgression() {
    let progressionPercent = 0;
    let {config, step} = this.state;
    if (!config || !config.quiz || step === 0) {
      return 0;
    }
    return (((step + 1) / (config.quiz.length + 1)) * 100) | 0;
  }

  determineNewStylingMode(newStyleLight) {
    if (newStyleLight) {
      document.body.classList.add('new-quiz-light');
      document.body.classList.remove('new-quiz-dark');
    } else {
      document.body.classList.add('new-quiz-dark');
      document.body.classList.remove('new-quiz-light');
    }
  }

  renderErrorPopup() {
    if (this.props.disableErrorPopup) return;

    const errorClassName = Object.keys(this.state.errors).length === 0 ? '' : 'error';

    return <div id="error-message" className={errorClassName} />;
  }

  render() {
    let ref = r => {
      this.quizElement = r;
    };
    return (
      <div ref={ref} className="quiz-controller">
        <div className="quiz-progression">
          <span
            className="current-progress"
            style={{width: this.calculateProgression() + '%'}}
          ></span>
        </div>
        {this.renderStep()}
        {this.renderErrorPopup()}
      </div>
    );
  }
}

export default QuizController;
