import React from "react";
import GenericModal from "./GenericModal";
import '../../styles/components/global/AgentActivityEmailModal.scss';

const AgentActivityEmailModal = ({show, agentName, buttonLink}) => (
  <div className="agent-activity-email-modal-component">
    <GenericModal show={show}>
      <h1>Are you {agentName}?</h1>
      <div className='sub-heading'>HomeLight has identified you as a top performing agent based on your history of closed transactions.</div>
      <h4 className='sub-heading2'>Claim your profile now and begin matching with buyers and sellers in your local market.</h4>
      <a href={buttonLink} className="action-button button button-outline">Claim my HomeLight Profile</a>
    </GenericModal>
  </div>
);

export default AgentActivityEmailModal;
